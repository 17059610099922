import React, { useEffect } from 'react';
import Pusher from 'pusher-js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/img/edusnap/notify.png';
import notificationSound from '../../../assets/audio/livechat.mp3'; // Asegúrate de que la ruta sea correcta

const NotificationComponent = ({ subdominio, user }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
            cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
            wsPort: 0,
            wssPort: 0,
        });
        const userId = user?.id; // Asegúrate de que user tenga un id
        const channelName = `notifications${subdominio}${userId}`;
        const channel = pusher.subscribe(channelName);

        channel.bind('new-notification', function(data) {
            if (data && data.message) {
                // Reproducir sonido de notificación
                playNotificationSound();
                console.log("Notification data is valid:", data);
                // Mostrar la notificación push
                if (Notification.permission === "granted") {
                    showNotification(data);
                } else if (Notification.permission !== "denied") {
                    Notification.requestPermission().then(function(permission) {
                        if (permission === "granted") {
                            showNotification(data);
                        }
                    });
                }
                // Mostrar el toast
                showToast(data);
            } else {
                console.error('Invalid data structure received:', data);
            }
        });

        function showNotification(data) {
            new Notification(data.titulo, {
                body: data.message,
                icon: logo, // Usa la imagen importada
                data: { url: data.url },
            });
        }

        function showToast(data) {
            toast.info(
                <div>
                    <strong>{data.titulo}</strong>
                    <p>{data.message}</p>
                </div>,
                {
                    position: 'bottom-left', // Cambiado a bottom-left
                    autoClose: 5000,
                    onClick: () => {
                        // Redirige al usuario al hacer clic en el toast usando data.url
                        navigate(data.url); // Usa data.url para la redirección
                    },
                }
            );
        }

        function playNotificationSound() {
            const audio = new Audio(notificationSound);
            document.addEventListener('click', () => {
                audio.play().then(() => {
                    console.log('Notification sound played successfully.');
                }).catch(error => {
                    console.error('Error playing notification sound:', error);
                });
            }, { once: true }); // Escuchar solo una vez
        }

        return () => {
            channel.unbind_all();
            channel.unsubscribe();
        };
    }, [subdominio, user, navigate]);

    // No es necesario retornar un div vacío si el componente no necesita renderizar nada
    return null;
};

export default NotificationComponent;
