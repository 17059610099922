import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useApiContext } from '../layout/Template';
import { useNavigate } from 'react-router-dom';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import Parciales from './Parciales';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import es from 'date-fns/locale/es'; // Importa la localización en español
import moment from 'moment';
import 'moment/locale/es'; // Asegúrate de importar el locale en español
registerLocale('es', es);

const ParcialMateria = () => {
    const { materiaId, subcicloId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user } = useApiContext();
    const [isLoading, setIsLoading] = useState(false);
    
    const [parcial, setParcial] = useState(null);
    const [materia, setMateria] = useState(null);
    const [titulo, setTitulo] = useState([]);
    const [estudiantes, setEstudiantes] = useState([]);
    const [clases, setClases] = useState([]);
    const [ciclos, setCiclos] = useState([]);
    const [subciclos, setSubciclos] = useState([]);
    const [editableColumn, setEditableColumn] = useState(null);
    const [show, setShow] = useState(false);
    const [selectedDates, setSelectedDates] = useState([]);
    const [isRange, setIsRange] = useState(true); // Estado para seleccionar entre rango y múltiples fechas
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleDateChange = (dates) => {
        if (isRange) {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
            console.log("Rango de fechas:", start ? start.toLocaleDateString() : 'N/A', end ? end.toLocaleDateString() : 'N/A');
        } else {
            if (dates && !selectedDates.some(d => d.getTime() === dates.getTime())) {
                const updatedDates = [...selectedDates, dates];
                setSelectedDates(updatedDates);
                console.log("Fechas seleccionadas:", updatedDates.map(d => d.toLocaleDateString()));
            } else {
                const updatedDates = selectedDates.filter(d => d.getTime() !== dates.getTime());
                setSelectedDates(updatedDates);
                console.log("Fechas seleccionadas:", updatedDates.map(d => d.toLocaleDateString()));
            }
        }
    };


    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/asistencias/materia/${materiaId}/${subcicloId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            const data = response.data;
            setParcial(data.parcial);
            setMateria(data.materia);
            setTitulo(data.titulo);
            setEstudiantes(Array.isArray(data.estudiantes) ? data.estudiantes : Object.values(data.estudiantes));
            setClases(data.clases);
            setCiclos(data.ciclos);
            setSubciclos(data.subciclos);

            console.log(data)
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [materiaId, subcicloId]);

    const obtenerDiaDeLaSemana = (fecha) => {
        // Usa moment para parsear y formatear la fecha
        return moment(fecha).locale('es').format('dddd');
    };

    const handleEditClick = (claseId) => {
        if (editableColumn === claseId) {
            // Cancel edit mode
            setEditableColumn(null);
        } else {
            // Start edit mode
            setEditableColumn(claseId);
        }
    };

    const [asistencias, setAsistencias] = useState({});

    const handleValueChange = (estudianteId, claseId, newValue) => {
        setAsistencias(prevAsistencias => ({
            ...prevAsistencias,
            [`${estudianteId}-${claseId}`]: newValue
        }));
    };

    const handleSaveClick = async () => {
        // Mostrar el popup de carga
        window.showLoaderPopup();
        // Recopilar datos
        const asistenciaData = estudiantes.map(estudiante => ({
            id_clase: editableColumn,
            asistencia: asistencias[`${estudiante.id}-${editableColumn}`] || estudiante.asistencias[editableColumn] || '1', // Usa el valor del estado si existe, sino el valor original
            id_estudiante: estudiante.id
        }));

        // Filtrar los datos para excluir aquellos con asistencia vacía
        const filteredData = asistenciaData.filter(data => data.asistencia !== '');

        // Mostrar datos en consola
        console.log('Datos para guardar:', filteredData);

        try {
            // Enviar datos a la API
            const response = await axios.post(`${apiUrl}/asistencias/materia/store`, {
                asistencias: filteredData,
                materiaId, // Añadir materiaId al payload
                editableColumn,
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });

            // Actualizar los valores en el estado local
            filteredData.forEach(data => {
                updateValues(data.id_estudiante, data.id_clase, data.asistencia);
            });
            
            // Manejar la respuesta de la API
            console.log('Respuesta de la API:', response.data);
            // Opcionalmente puedes mostrar un mensaje de éxito o hacer algo más con la respuesta
            // Ocultar el popup de carga
            window.hideLoaderPopup();
            window.showSuccessPopup("Registros actualizados.");
    
        } catch (err) {
            window.hideLoaderPopup();
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally{
            // Opción para restablecer la columna editable después de guardar
            setEditableColumn(null);
        }
    };
    
    const updateValues = (estudianteId, claseId, newValue) => {
        setEstudiantes(prevEstudiantes => 
            prevEstudiantes.map(estudiante => {
                if (estudiante.id === estudianteId) {
                    return {
                        ...estudiante,
                        asistencias: {
                            ...estudiante.asistencias,
                            [claseId]: newValue
                        }
                    };
                }
                return estudiante;
            })
        );
    };

    const handleSubmit = async () => {
        // Verifica si se ha seleccionado el rango o múltiples fechas
        const isRange = startDate && endDate; // Si startDate y endDate están definidos, es un rango
    
        if (isRange) {
            if (!startDate || !endDate) {
                alert('Por favor, selecciona un rango de fechas válido.');
                return;
            }
        } else {
            if (selectedDates.length === 0) {
                alert('Por favor, selecciona al menos una fecha.');
                return;
            }
        }
        window.showLoaderPopup();
        try {
            // Prepara los datos a enviar en función del tipo de selección
            const dataToSend = isRange
                ? { startDate, endDate, isRange: true }
                : { dates: selectedDates, isRange: false };
            const response = await axios.post(
                `${apiUrl}/asistencias/materia/storeFechas/${materiaId}/${subcicloId}`,
                dataToSend,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json'
                    }
                }
            );
            console.log('Respuesta del servidor:', response.data);
            //alert('Fechas guardadas con éxito.');
            handleClose();
            fetchData();
            window.hideLoaderPopup();
            window.showSuccessPopup("Registros Creados.");
        } catch (error) {
            console.error('Error al guardar las fechas:', error);
            alert('Hubo un error al guardar las fechas.');
        } 
    };
    
    const deleteClase = async (id) => {
        window.showLoaderPopup();
        try {
            await axios.delete(`${apiUrl}/clase/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            fetchData();
            window.hideLoaderPopup();
            window.showSuccessPopup("Registro eliminado.");
        } catch (error) {
            console.error('Error al eliminar la clase:', error);
            // Manejo de errores según sea necesario
        }
    }

    const generarPDF = async () => {
        console.log("procesando");
        try {
            const response = await axios.get(`${apiUrl}/asistencias/materia/pdf/${materiaId}/${subcicloId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/pdf', // Asegúrate de que la respuesta sea un PDF
                },
                responseType: 'blob' // Importante para manejar la respuesta como archivo binario
            });
    
            // Crear un enlace de descarga
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
    
            // Forma el nombre del archivo con un formato seguro para nombres de archivo
            const nombreArchivo = 'Asistencias.pdf';
            link.setAttribute('download', nombreArchivo);
            document.body.appendChild(link);
            link.click();
    
            // Liberar el objeto URL y eliminar el enlace del DOM
            window.URL.revokeObjectURL(url);
            link.remove();
        } catch (err) {
            console.error('Error al descargar el PDF:', err);
            window.showErrorPopup('Error al descargar el archivo PDF.');
        }
        console.log("fin");
    };

    if (loading) {
        return <Spinner />;
    }
    
    return (
        <div className='py-3 px-3 content-wrapper'>
            <style>
                {`

                    .fecha {
                        display: inline-block;
                        -webkit-writing-mode: vertical-rl;
                        -ms-writing-mode: tb-rl;
                        writing-mode: vertical-rl;
                        transform: rotate(180deg); /* Ajusta la orientación si es necesario */
                        white-space: nowrap; /* Evita el salto de línea */
                        margin-bottom: 0; /* Asegura que no haya espacio adicional debajo del texto */
                        font-size: 15px;
                    }

                    .icono {
                        margin-top: 4px; /* Ajusta el espacio entre el icono y el texto según sea necesario */
                    }

                    .fixed-column {
                        position: sticky;
                        left: 0; /* Asegúrate de que la columna se mantenga fija en la posición horizontal */
                        top: 0; /* Asegúrate de que la columna se mantenga fija en la parte superior */
                        background: white; /* O el color de fondo que prefieras */
                        z-index: 1; /* Asegura que la columna esté encima del resto del contenido */
                    }
                        
                    .table td {
                        vertical-align: middle;
                        line-height: 1;
                        white-space: nowrap;
                        padding: 0rem 0.5rem;
                        max-width: 270px;
                    }

                    .card .card-body {
                        padding: 1.5rem 0.2rem;
                    }
                `}
            </style>
            <div className="d-sm-flex justify-content-between align-items-start mb-2">
                <div>
                    <h2><strong>{titulo.descripcion}</strong></h2>
                </div>
                <div>
                    <div className="btn-group mb-2" role="group" aria-label="Basic outlined example">
                        <Link to={`/Materias/Contenido/${materiaId}/${subcicloId}`} className="btn btn-warning btn-sm"><i className="fas fa-chevron-circle-left"></i> <span className="d-none d-lg-inline"> Volver</span></Link>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="d-sm-flex justify-content-between align-items-start mb-2">
                        <div>
                            <h2><strong>{titulo.subtitulo}</strong></h2>
                            <Parciales ciclos={ciclos} subciclos={subciclos} materiaId={materiaId} />
                        </div>
                        <div>
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-success" onClick={handleShow}>
                                    <i className="fas fa-calendar-alt"></i> <span className="d-none d-lg-inline"> Añadir Fechas</span>
                                </button>
                                <button className="btn btn-primary" onClick={generarPDF}>
                                    <i className="fas fa-file-pdf"></i> <span className="d-none d-lg-inline"> Generar PDF</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className={`modal fade ${show ? 'show d-block' : ''}`} tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Añadir fechas</h5>
                                    <button type="button" className="btn-close" onClick={handleClose}></button>
                                </div>
                                <div className="modal-body">
                                    <div className="mb-3 text-center">
                                        <button onClick={() => setIsRange(true)} className={isRange ? 'btn btn-primary' : 'btn btn-secondary'}>
                                            Selección de Rango
                                        </button>
                                        <button onClick={() => setIsRange(false)} className={!isRange ? 'btn btn-primary' : 'btn btn-secondary'}>
                                            Selección Múltiple
                                        </button>
                                    </div>
                                    <form>
                                        <div className="mb-3 text-center">
                                            <DatePicker
                                                selected={isRange ? startDate : null}
                                                onChange={handleDateChange}
                                                startDate={isRange ? startDate : null}
                                                endDate={isRange ? endDate : null}
                                                selectsRange={isRange}
                                                selectsStart={!isRange}
                                                selectsEnd={!isRange}
                                                inline
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText={isRange ? "Seleccione un rango de fechas" : "Seleccione múltiples fechas"}
                                                locale={es} // Establece el idioma a español
                                                className="form-control"
                                                highlightDates={isRange ? [] : selectedDates} // Resalta las fechas seleccionadas solo en modo de selección múltiple
                                                shouldCloseOnSelect={!isRange} // Cierra el calendario solo en modo de selección de rango
                                                disabledKeyboardNavigation
                                            />
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={handleClose}>
                                        Cerrar
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleSubmit}
                                        disabled={loading}
                                    >
                                        {loading ? 'Guardando...' : 'Guardar cambios'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th className='fixed-column' style={{ backgroundColor: 'white' }}>
                                        Estudiantes
                                    </th>
                                    {clases.map((clase, index) => {
                                        const diaDeLaSemana = obtenerDiaDeLaSemana(clase.fecha);
                                        return (
                                            <th key={index} style={{ width: "5px" }}>
                                                <div>
                                                    {clase.fecha}
                                                    <br />
                                                    <span className="dia">{diaDeLaSemana}</span>
                                                </div>
                                                <div className="d-sm-flex justify-content-between align-items-start mb-2 fs-6">
                                                    <div className="icono text-center mb-2">
                                                        <Link to="#" onClick={() => handleEditClick(clase.id)}>
                                                            <i className={`fas ${editableColumn === clase.id ? 'fa-times' : 'fa-edit'}`}></i>
                                                        </Link>
                                                    </div>
                                                    {editableColumn === clase.id && (
                                                        <div className="guardar text-center">
                                                            <Link to="#" onClick={handleSaveClick}>
                                                                <i className="fas fa-save"></i>
                                                            </Link>
                                                        </div>
                                                    )}
                                                    <div className="icono text-center mb-2">
                                                        <Link to="#" onClick={() => deleteClase(clase.id)}>
                                                            <i className="fas fa-trash-alt text-danger"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody key={`tbody-${editableColumn}`}>
                                {estudiantes.map((estudiante) => (
                                    <tr key={estudiante.id}>
                                        <td className='fixed-column' style={{ backgroundColor: 'white', width: '250px' }}>{estudiante.estudiante.nombres}</td>
                                        {clases.map((clase) => (
                                            <td key={clase.id}>
                                                <AsistenciaSelect
                                                    estudianteId={estudiante.id}
                                                    claseId={clase.id}
                                                    initialValue={estudiante.asistencias[clase.id] || ''}
                                                    editableColumn={editableColumn}
                                                    onValueChange={handleValueChange}
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const AsistenciaSelect = ({ estudianteId, claseId, initialValue, editableColumn, onValueChange }) => {
    const [selectedValue, setSelectedValue] = useState(initialValue || '1'); // Valor por defecto es '1'

    useEffect(() => {
        setSelectedValue(initialValue || '1'); // Asegúrate de que el valor por defecto sea '1' si initialValue no está definido
    }, [initialValue]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setSelectedValue(newValue);
        if (onValueChange) {
            onValueChange(estudianteId, claseId, newValue);
        }
    };

    return (
        <select
            className="form-select form-select mb-3"
            name={`asistencia[${estudianteId}][${claseId}]`}
            value={selectedValue}
            onChange={handleChange}
            disabled={editableColumn !== claseId}
        >
            <option value="1">A - Asistencia</option>
            <option value="2">AT - Atraso</option>
            <option value="3">FN - Falta no Justificada</option>
            <option value="4">FJ - Falta Justificada</option>
        </select>
    );
};



export default ParcialMateria;
