import React from 'react';
import { PieChart, Pie, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';

// Función para generar colores aleatorios
const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};
// Componente MyPieChart que incluye datos predeterminados
const MyPieChart = ({ data = [] }) => {
    // Genera un array de colores aleatorios con el mismo tamaño que los datos
    const colors = data.map(() => getRandomColor());

    return (
        <ResponsiveContainer width="100%" height={400}>
            <PieChart>
                <Pie
                    data={data}
                    dataKey="value"    // Clave para los valores en los datos
                    nameKey="name"     // Clave para los nombres en los datos
                    cx="50%"           // Centro en X
                    cy="50%"           // Centro en Y
                    outerRadius={100}  // Radio exterior del gráfico
                    fill="#8884d8"     // Color de las secciones (por defecto)
                    label              // Muestra etiquetas en las secciones
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
        </ResponsiveContainer>
    );
};

export default MyPieChart;
