// src/routes/DocentesRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../componentes/auth/ProtectedRoute';
import Template from '../componentes/client/layout/Template';

// docentes
import Index from '../componentes/client/docentes/Index';
import Create from '../componentes/client/docentes/Create';
import Edit from '../componentes/client/docentes/Edit';

const DocentesRoutes = () => {
    return (
        <Routes>
            <Route
                    path=""
                    element={
                        <ProtectedRoute>
                            <Template>
                                <Index />
                            </Template>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="create"
                    element={
                        <ProtectedRoute>
                            <Template>
                                <Create />
                            </Template>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path=":id"
                    element={
                        <ProtectedRoute>
                            <Template>
                                <Edit />
                            </Template>
                        </ProtectedRoute>
                    }
                />
        </Routes>
    );
};

export default DocentesRoutes;
