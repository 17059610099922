import axios from 'axios';
import React, { useState, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useApiContext } from '../layout/Template';
import QuillEditor  from '../widget/QuillEditor';

const Create = () => {
    const { materiaId, cicloId } = useParams();
    const { apiUrl, accessToken } = useApiContext(); // Ajusta según tu contexto API
    const [errors, setErrors] = useState({});
    const editorRef = useRef(null);
    const navigate = useNavigate();
    //variables
    const [descripcion, setDescripcion] = useState('');
    const [fecha, setFecha] = useState('');
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [entregas, setEntregas] = useState(false);
    const [docEvaluacion, setDocEvaluacion] = useState(null);
    const [docCuestionario, setDocCuestionario] = useState(null);
    const inputFileRefEvaluacion = useRef(null);
    const inputFileRefCuestionario = useRef(null);
    const endpoint = `${apiUrl}/examen/store/${materiaId}/${cicloId}`;

    const store = async (e) => {
        e.preventDefault();
        
        // Obtén el contenido del editor desde el ref
        const editorContent = editorRef.current?.root.innerHTML || '';
        const newErrors = {};

        // Validación de descripción
        const textLength = editorContent.replace(/<[^>]*>/g, '').length; // Elimina etiquetas HTML y mide longitud
        if (textLength > 200) {
            newErrors.descripcion = 'La descripción es obligatoria y debe tener entre 3 y 10000 caracteres.';
        }
    
        // Validación de fechas
        if (!fecha)
            newErrors.fecha = 'La fecha es obligatoria.';
    
        if (entregas) {
            if (!fechaInicio) 
                newErrors.fechaInicio = 'La fecha de inicio es obligatoria.';
            if (!fechaFin)
                newErrors.fechaFin = 'La fecha de fin es obligatoria.';
            if (fechaInicio && fechaFin && new Date(fechaInicio) > new Date(fechaFin))
                newErrors.fechaFin = 'La fecha de fin debe ser posterior a la fecha de inicio.';
        }
    
        // Validación de doc_evaluacion
        if (docEvaluacion && docEvaluacion.size > 10 * 1024 * 1024) {
            newErrors.doc_evaluacion = 'El documento de examen no puede ser mayor a 10MB.';
        }
    
        // Validación de doc_cuestionario
        if (docCuestionario && docCuestionario.size > 10 * 1024 * 1024) {
            newErrors.doc_cuestionario = 'El documento de cuestionario no puede ser mayor a 10MB.';
        }
    
        // Actualiza el estado con los errores encontrados
        setErrors(newErrors);

        // Verifica si hay errores antes de enviar el formulario
        if (Object.keys(newErrors).length === 0) {
            // Aquí podrías enviar los datos del formulario
            console.log({ descripcion: editorContent });
        }
    
        const formData = new FormData();
        formData.append('descripcion', editorContent);
        formData.append('fecha', fecha);
        formData.append('fecha_inicio', fechaInicio);
        formData.append('fecha_fin', fechaFin);
        // Solo agrega los documentos si están seleccionados
        if (docEvaluacion) {
            formData.append('doc_evaluacion', docEvaluacion);
        }
        if (docCuestionario) {
            formData.append('doc_cuestionario', docCuestionario);
        }
        
        const entregasValue = entregas ? 'true' : 'false';
        // Agrega el checkbox al FormData
        formData.append('entregas', entregasValue);
    
        try {
            const response = await axios.post(endpoint, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            });
            navigate(`/Materias/Contenido/Examen/${response.data.id}`);
            window.showSuccessPopup(response.data.message);
            // Maneja la respuesta de éxito aquí
        } catch (err) {
            if (err.response && err.response.status === 422) {
                setErrors(err.response.data.errors);
            } else if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    navigate('/dashboard');
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        }
    };

    // Maneja el cambio del checkbox
    const handleCheckboxChange = (e) => {
        setEntregas(e.target.checked);
    };
    
    return (
        <div className="py-3 px-3 content-wrapper">
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Crear Examen</h2>
                </div>
            </div>
            
            <div className="col-md-6 offset-md-3 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={store} encType="multipart/form-data">
                            <div className="mb-3">
                                <label htmlFor="descripcion" className={`form-control ${errors.descripcion ? 'is-invalid' : ''}`}>Descripción</label>
                                <QuillEditor ref={editorRef} />
                                {errors.descripcion && <div className="invalid-feedback">{errors.descripcion}</div>}
                            </div>
                            <div className="form-check form-check-flat form-check-primary">
                                <label className="form-check-label">
                                    <input
                                        type="checkbox" 
                                        className="form-check-input" 
                                        id="entregasCheckbox"
                                        name="entregas"
                                        checked={entregas} // Usa el estado para controlar el checkbox
                                        onChange={handleCheckboxChange} />
                                        Tarea Virtual
                                    <i className="input-helper"></i>
                                </label>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-12 mb-3">
                                    <label for="fecha">Fecha</label>
                                    <input 
                                        type="date" 
                                        className={`form-control ${errors.fecha ? 'is-invalid' : ''}`} 
                                        id="fecha" 
                                        name="fecha" 
                                        placeholder="fecha"
                                        value={fecha}
                                        onChange={(e) => setFecha(e.target.value)}
                                    />
                                    {errors.fecha && <div className="invalid-feedback">{errors.fecha}</div>}
                                </div>
                                {entregas && (
                                    <>
                                    <div className="col-md-4 col-sm-12 mb-3 virtual">
                                        <label htmlFor="fecha_inicio">Fecha Inicio</label>
                                        <input 
                                            type="datetime-local" 
                                            className={`form-control ${errors.fechaInicio ? 'is-invalid' : ''}`} 
                                            id="fecha_inicio" 
                                            name="fecha_inicio" 
                                            placeholder="fecha_inicio"
                                            value={fechaInicio}
                                            onChange={(e) => setFechaInicio(e.target.value)}
                                        />
                                        {errors.fechaInicio && <div className="invalid-feedback">{errors.fechaInicio}</div>}
                                    </div>
                                    <div className="col-md-4 col-sm-12 mb-3 virtual">
                                        <label htmlFor="fecha_fin">Fecha Fin</label>
                                        <input 
                                            type="datetime-local" 
                                            className={`form-control ${errors.fechaFin ? 'is-invalid' : ''}`} 
                                            id="fecha_fin" 
                                            name="fecha_fin" 
                                            placeholder="fecha_fin"
                                            value={fechaFin}
                                            onChange={(e) => setFechaFin(e.target.value)}
                                        />
                                        {errors.fechaFin && <div className="invalid-feedback">{errors.fechaFin}</div>}
                                    </div>
                                    </>
                                )}
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="doc_evaluacion">Documento Examen</label>
                                    <input 
                                        className={`form-control form-control-sm ${errors.doc_evaluacion ? 'is-invalid' : ''}`} 
                                        id="doc_evaluacion" 
                                        name="doc_evaluacion" 
                                        type="file"
                                        ref={inputFileRefEvaluacion}
                                        onChange={(e) => setDocEvaluacion(e.target.files[0])}
                                    />
                                    {errors.doc_evaluacion && <div className="invalid-feedback">{errors.doc_evaluacion}</div>}
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="doc_cuestionario">Documento Cuestionario (Para estudiantes)</label>
                                    <input 
                                        className={`form-control form-control-sm ${errors.doc_cuestionario ? 'is-invalid' : ''}`}  
                                        id="doc_cuestionario" 
                                        name="doc_cuestionario" 
                                        type="file"
                                        ref={inputFileRefCuestionario}
                                        onChange={(e) => setDocCuestionario(e.target.files[0])}
                                    />
                                    {errors.doc_cuestionario && <div className="invalid-feedback">{errors.doc_cuestionario}</div>}
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary me-2 boton-procesar">
                                <i className="fas fa-save"></i> Crear Examen
                            </button>
                            <Link className="btn btn-warning" to={`/Materias/Contenido/${materiaId}/${cicloId}`}>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;
