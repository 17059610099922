import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import FormContent from './FormContent'; 
import useFetchParametros from './useFetchParametros'

const Create = () => {
    const { apiUrl, accessToken } = useApiContext();
    const parametros = useFetchParametros(apiUrl, accessToken);
    const [formData, setFormData] = useState({
        id_periodo: '',
        id_nivel: '',
        id_seccion: '',
        id_tipo: '',
        id_modalidad: '',
        id_jornada: '',
        id_especialidad: '',
        id_tutor: '',
        matricula: '',
        cuota: '',
    });
    const [errors, setErrors] = useState({}); // Estado para errores de validación
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/cursos`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            const cursoId = response.data.id;  // Obtén el ID del curso creado
            console.log('Curso creado exitosamente:', response.data);
            // Redirige a la pantalla de consulta con el ID del curso recién creado
            navigate(`/MisCursos/Info/${cursoId}`);
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            if (error.response) {
                // Manejar errores de validación
                setErrors(error.response.data.errors);
            } else {
                console.error('Error al crearel curso:', error.response ? error.response.data : error.message);
            }
        }
    };

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Crear Curso</h2>
                </div>
            </div>
            <div className="col-md-8 offset-md-2 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={handleSubmit}>
                            <FormContent
                                formData={formData}
                                setFormData={setFormData}
                                periodos={parametros.periodos}
                                niveles={parametros.niveles}
                                secciones={parametros.secciones}
                                tipos={parametros.tipos}
                                modalidades={parametros.modalidades}
                                especialidades={parametros.especialidades}
                                jornadas={parametros.jornadas}
                                docentes={parametros.docentes}
                                isEdit={false}
                                errors={errors}
                            />
                            <button type="submit" className="btn btn-primary me-2"><i className="fas fa-save"></i> Crear Curso</button>
                            <Link className="btn btn-warning" to='/Cursos'>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;
