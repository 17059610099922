import React, { useState } from 'react';
import axios from 'axios';

const ChangePasswordModal = ({ show, handleClose, apiUrl, accessToken }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validaciones
        if (password.length < 8) {
            setErrorMessage('La nueva contraseña debe tener al menos 8 caracteres.');
            return;
        }
    
        if (password !== confirmPassword) {
            setErrorMessage('Las contraseñas no coinciden.');
            return;
        }
    
        try {
            const response = await axios.post(
            `${apiUrl}/UpdPwdUser`,
            { password, confirmPassword },
            {
                headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: 'application/json',
                },
            }
            );
    
            if (response.status === 200) {
                //alert('Contraseña cambiada con éxito');
                handleClose(); // Cerrar el modal después del éxito
                window.showSuccessPopup(response.data.message);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                // Capturar errores específicos del backend
                const backendError = error.response.data.message || 'Error desconocido en el servidor';
                setErrorMessage(backendError);
            } else {
                // Capturar errores generales (como falta de conexión)
                setErrorMessage('Error al cambiar la contraseña. Intente nuevamente.');
            }
        }
    };

    if (!show) return null; // Si show es false, no renderizamos el modal

    return (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,.5)' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Cambiar Contraseña</h5>
                        <button type="button" className="btn-close" onClick={handleClose}></button>
                    </div>
                    <div className="modal-body">
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Nueva Contraseña</label>
                                <input
                                type="password"
                                className="form-control"
                                id="password"
                                placeholder="Ingrese su nueva contraseña"
                                value={password}
                                maxLength={16}
                                onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="confirmPassword" className="form-label">Confirmar Contraseña</label>
                                <input
                                type="password"
                                className="form-control"
                                id="confirmPassword"
                                placeholder="Confirme su nueva contraseña"
                                value={confirmPassword}
                                maxLength={16}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleClose}>Cerrar</button>
                                <button type="submit" className="btn btn-primary">Guardar Cambios</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordModal;
