import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import FileViewer from '../widget/FileViewer';

const Planificacion = ({ apiUrl, materiaId, accessToken, parcialId }) => {
    const navigate = useNavigate(); // Hook para navegar
    const [archivoFirmado, setArchivoFirmado] = useState(null);
    const [archivoName, setArchivoName] = useState(null);
    const [archivoMacroFirmado, setArchivoMacroFirmado] = useState(null);
    const [archivoMacroName, setArchivoMacroName] = useState(null);
    const [archivo, setArchivo] = useState('');
    const [archivo_macro, setArchivo_macro] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleFileChange = (event) => {
        setArchivo(event.target.files[0]); // Guardar el archivo seleccionado en el estado
    };

    const handleFileChangeMacro = (event) => {
        setArchivo_macro(event.target.files[0]); // Guardar el archivo seleccionado en el estado
    };

    const handleSubmitSemanal = async (event) => {
        event.preventDefault();
        window.showLoaderPopup();

        // Verificar si el archivo está presente y su tamaño
        if (!archivo) {
            window.hideLoaderPopup();
            window.showErrorPopup('Por favor, seleccione un archivo.');
            setError('Por favor, seleccione un archivo.');
            return;
        }

        const maxFileSize = 5 * 1024 * 1024; // 5 MB en bytes
        if (archivo.size > maxFileSize) {
            window.hideLoaderPopup();
            window.showErrorPopup('El archivo no debe pesar más de 5 MB.');
            setError('El archivo no debe pesar más de 5 MB.');
            return;
        }

        const formData = new FormData();
        formData.append('archivo', archivo); // Añadir el archivo al FormData

        try {
            const response = await axios.post(
                `${apiUrl}/planificacion/semanal/upsert/${materiaId}`, 
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data' // Establecer el tipo de contenido
                    }
                }
            );
            // Resetear el formulario
            setArchivo(null);
            // Navegar a otra página
            navigate(`/Materias/Contenido/${materiaId}/${parcialId}`);
            window.hideLoaderPopup();
            window.showSuccessPopup("Archivo Actualizado.");
        } catch (error) {
            window.hideLoaderPopup();
            setError('Error al enviar el archivo.');
            console.error('Error al enviar el archivo:', error);
        }
    };

    const handleSubmitMacro = async (event) => {
        event.preventDefault();
        window.showLoaderPopup();

        // Verificar si el archivo_macro está presente y su tamaño
        if (!archivo_macro) {
            window.hideLoaderPopup();
            window.showErrorPopup('Por favor, seleccione un archivo_macro.');
            setError('Por favor, seleccione un archivo_macro.');
            return;
        }

        const maxFileSize = 5 * 1024 * 1024; // 5 MB en bytes
        if (archivo_macro.size > maxFileSize) {
            window.hideLoaderPopup();
            window.showErrorPopup('El archivo_macro no debe pesar más de 5 MB.');
            setError('El archivo_macro no debe pesar más de 5 MB.');
            return;
        }

        const formData = new FormData();
        formData.append('archivo_macro', archivo_macro); // Añadir el archivo_macro al FormData

        try {
            const response = await axios.post(
                `${apiUrl}/planificacion/macro/upsert/${materiaId}`, 
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data' // Establecer el tipo de contenido
                    }
                }
            );
            // Resetear el formulario
            setArchivo_macro(null);
            // Navegar a otra página
            navigate(`/Materias/Contenido/${materiaId}/${parcialId}`);
            window.hideLoaderPopup();
            window.showSuccessPopup("Archivo Actualizado.");
        } catch (error) {
            window.hideLoaderPopup();
            setError('Error al enviar el archivo_macro.');
            console.error('Error al enviar el archivo_macro:', error);
        }
    };

    const fetchPlanificacion = async () => {
        try {
            const response = await axios.get(`${apiUrl}/planificacion/${materiaId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            const data = response.data;

            setArchivoFirmado(data.archivoFirmado);
            setArchivoName(data.archivoName);
            setArchivoMacroFirmado(data.archivoMacroFirmado);
            setArchivoMacroName(data.archivoMacroName);
            console.log(archivoFirmado)
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        }
    };

    useEffect(() => {
        fetchPlanificacion();
    }, [materiaId, apiUrl, accessToken]);

    return (
        <div className="modal fade" id="planificacionModal" tabIndex="-1" aria-labelledby="planificacionModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="planificacionModalLabel">Planificacion Curricular</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body m-0 p-0">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Semanal</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Macro</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <form onSubmit={handleSubmitSemanal}>
                                    <div className="mb-3">
                                        <label htmlFor="archivo" className="form-label">Planificación Semanal</label>
                                        <input 
                                            type="file" 
                                            className="form-control" 
                                            id="archivo" 
                                            name="archivo" 
                                            onChange={handleFileChange} // Manejar cambio de archivo
                                        />
                                    </div>
                                    <div className='mb-3'>
                                        {archivoFirmado !== null && (
                                            <>
                                            <Link className='btn btn-success' to={archivoFirmado} target='_blank'>Ver Archivo</Link>
                                            </>
                                        )}
                                    </div>
                                    <button type="submit" className="btn btn-primary">Guardar</button>
                                </form>
                            </div>
                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <form onSubmit={handleSubmitMacro}>
                                    <div className="mb-3">
                                        <label htmlFor="archivo_macro" className="form-label">Planificación Macro</label>
                                        <input 
                                            type="file" 
                                            className="form-control" 
                                            id="archivo_macro" 
                                            name="archivo_macro" 
                                            onChange={handleFileChangeMacro} // Manejar cambio de archivo
                                        />
                                    </div>
                                    <div className='mb-3'>
                                        {archivoMacroFirmado !== null && (
                                            <>
                                            <Link className='btn btn-success' to={archivoMacroFirmado} target='_blank'>Ver Archivo Macro</Link>
                                            </>
                                        )}
                                    </div>
                                    <button type="submit" className="btn btn-primary">Guardar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Planificacion;
