import React from 'react';
import TextInput from '../../form/InputText';
import SelectInput from '../../form//SelectInput';
import CheckboxInput from '../../form//CheckboxInput';

const DatosDocente = ({ formData, paises, cantones, errors, setFormData }) => {

    const handleCheckboxChange = (field, subField = null) => (e) => {
        const checked = e.target.checked;
        setFormData(prevFormData => {
            if (subField) {
                // Caso para subcampos
                return {
                    ...prevFormData,
                    [field]: {
                        ...prevFormData[field],
                        [subField]: checked
                    }
                };
            } else {
                // Caso para campos en el nivel superior
                return {
                    ...prevFormData,
                    [field]: checked
                };
            }
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const [section, field] = name.split('.');

        setFormData(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [field]: value
            }
        }));
    };
    return (
        <div className="col-lg-6 col-md-6 col-sm-12 grid-margin stretch-card">
            <div className='card'>
                <div className="card-body">
                    <h4 className="card-title">Datos Docente</h4>
                    <div className="form-group">
                        <div className='row'>
                            <div className="col-8">
                                <TextInput
                                    label="Identificación"
                                    name="docente.identificacion"
                                    value={formData.docente.identificacion}
                                    onChange={handleInputChange}
                                    validacion={formData.docente.extranjero ? 'alfanumerico' : 'entero'}
                                    placeholder="Identificación"
                                    errors={errors}
                                />
                                {errors['docente.identificacion'] && <div className="invalid-feedback">{errors['docente.identificacion'][0]}</div>}
                            </div>
                            <div className="form-check col-4">
                                <CheckboxInput 
                                    id="extranjero_docente"
                                    name="extranjero_docente"
                                    checked={formData.docente.extranjero}
                                    onChange={handleCheckboxChange('docente', 'extranjero')}
                                    label="Extranjero"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <TextInput
                                    id="docente.nombre"
                                    name="docente.nombre"
                                    value={formData.docente.nombre}
                                    onChange={handleInputChange}
                                    placeholder="Nombres"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <TextInput
                                    id="docente.apellido"
                                    name="docente.apellido"
                                    value={formData.docente.apellido}
                                    onChange={handleInputChange}
                                    placeholder="Apellidos"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-6">
                                <TextInput
                                    id="docente.correo"
                                    name="docente.correo"
                                    value={formData.docente.correo}
                                    onChange={handleInputChange}
                                    placeholder="Correo"
                                    type="email"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-6">
                                <TextInput
                                    id="docente.direccion"
                                    name="docente.direccion"
                                    value={formData.docente.direccion}
                                    onChange={handleInputChange}
                                    placeholder="Dirección"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-4">
                                <SelectInput
                                    id="docente.sexo"
                                    name="docente.sexo"
                                    value={formData.docente.sexo}
                                    onChange={handleInputChange}
                                    placeholder="Sexo"
                                    options={[
                                        { value: 'M', label: 'Masculino' },
                                        { value: 'F', label: 'Femenino' }
                                    ]}
                                    error={errors['docente.sexo'] && errors['docente.sexo'][0]}
                                />
                            </div>
                            <div className="col-4">
                                <TextInput
                                    id="docente.celular"
                                    name="docente.celular"
                                    value={formData.docente.celular}
                                    onChange={handleInputChange}
                                    placeholder="Celular"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-4">
                                <TextInput
                                    id="docente.telefono"
                                    name="docente.telefono"
                                    value={formData.docente.telefono}
                                    onChange={handleInputChange}
                                    placeholder="Telefono"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-6">
                                <SelectInput
                                    id="docente.id_canton"
                                    name="docente.id_canton"
                                    value={formData.docente.id_canton}
                                    onChange={handleInputChange}
                                    placeholder="Cantón"
                                    options={cantones.map(canton => ({
                                        value: canton.id,
                                        label: canton.nombre
                                    }))}
                                    error={errors['docente.id_canton'] && errors['docente.id_canton'][0]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DatosDocente;
