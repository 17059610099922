import axios from 'axios';
import React, { useEffect, useState, useRef  } from 'react';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import FileViewer from '../widget/FileViewer';
import { validateAndFormatValue } from '../widget/ValidateAndFormatValue';
import { useApiContext } from '../layout/Template';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const Show = () => {
    const { proyectoId, parcialId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user } = useApiContext();
    const [proyecto, setProyecto] = useState(null);
    const [materia, setMateria] = useState(null);
    const [estudiantes, setEstudiantes] = useState([]);
    const [filtroNombres, setFiltroNombres] = useState('');
    const [filtroCalificacion, setFiltroCalificacion] = useState('');
    const [editableIndex, setEditableIndex] = useState(null);
    const [notas, setNotas] = useState(estudiantes.map(estudiante => estudiante.nota || ''));

    const inputRefs = useRef([]);
    
    const fetchProyecto = async () => {
        try {
            const response = await axios.get(`${apiUrl}/proyecto/notas/${proyectoId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            const data = response.data;
            setProyecto(data.proyecto);
            setMateria(data.materia);
            setEstudiantes(data.estudiantes);
            console.log(data.estudiantes);
            //window.showSuccessPopup('Datos del curso cargados exitosamente.');
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchProyecto();
    }, [proyectoId, apiUrl, accessToken]);
    
    const handleFiltroNombresChange = (e) => {
        setFiltroNombres(e.target.value);
    };

    const handleFiltroCalificacionChange = (e) => {
        setFiltroCalificacion(e.target.value);
    };

    const handleNotaChange = (index, value) => {
        const newNotas = [...notas];
        newNotas[index] = value;
        setNotas(newNotas);
    };

    const estudiantesFiltrados = estudiantes.filter(estudiante => {
        const nombreMatch = estudiante.nombres.toLowerCase().includes(filtroNombres.toLowerCase());
        const calificacionMatch = filtroCalificacion === '' || (filtroCalificacion === 'conCalificacion' && estudiante.nota) || (filtroCalificacion === 'sinCalificacion' && !estudiante.nota);
        return nombreMatch && calificacionMatch;
    });

    const handleEditClick = (index) => {
        setEditableIndex(index);
    };

    useEffect(() => {
        if (editableIndex !== null && inputRefs.current[editableIndex]) {
            inputRefs.current[editableIndex].focus();
        }
    }, [editableIndex]);

    const handleCancelEdit = () => {
        setEditableIndex(null);
    };

    const handleSave = async (index) => {
        const idEstudiante = estudiantesFiltrados[index].id;
        const idProyecto = proyectoId; // Asegúrate de que `proyecto` contenga el ID correcto.
    
        try {
            const response = await axios.post(`${apiUrl}/nota/proyecto`, {
                id_estudiante: idEstudiante,
                id_proyecto: idProyecto,
                calificacion: notas[index] // Asegúrate de que `notas` tenga el valor actualizado.
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
    
            // Muestra el mensaje de éxito
            window.showSuccessPopup(response.data.message);
            
            // Actualiza el array original de estudiantes
            const updatedEstudiantes = estudiantes.map(est => 
                est.id === idEstudiante ? { ...est, nota: parseFloat(notas[index]).toFixed(2) } : est
            );
            setEstudiantes(updatedEstudiantes);
    
            // Cancela la edición después de guardar
            handleCancelEdit();
        } catch (error) {
            console.error('Error al guardar la calificación:', error);
            // Maneja el error según sea necesario
        }
    };

    if (loading) {
        return <Spinner />;
    }
    
    return (
        <div className="py-3 px-3 content-wrapper">
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2>
                        {materia.materia.nombre} {materia.curso.nivel.nombre_corto}/{materia.curso.seccion.descripcion} 
                        {materia.curso.id_especialidad ? ` - ${materia.curso.especialidad.descripcion}` : ' '} - 
                        {materia.curso.jornada.descripcion}
                    </h2>
                </div>
                <div>
                    <div className="btn-group mb-2" role="group">
                        <Link to={`/Materias/Contenido/${materia.id}/${parcialId}`} className="btn btn-warning btn-sm"><i className="fas fa-chevron-circle-left"></i> Volver</Link>
                    </div>
                </div>
            </div>

            <div className='offset-lg-2 col-lg-8 mb-3'>
                <div className="card">
                    <div className="card-body">
                        <header id="page-header">
                            <div className="d-flex align-items-center">
                                <div className="mr-auto">
                                    <h2>
                                        Proyecto Integrador
                                    </h2>
                                    <p dangerouslySetInnerHTML={{ __html: proyecto.descripcion }}></p>
                                </div>
                            </div>
                        </header>
                    </div>
                </div>
            
                <div className="card mt-4">
                    <div className="card-body">
                        {user.estudiante !== 1 && (
                            <div className='row'>
                                <div className="col-lg-4">
                                    <input
                                        type="text"
                                        placeholder="Buscar estudiante..."
                                        value={filtroNombres}
                                        onChange={handleFiltroNombresChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="col-lg-4">
                                    <select
                                        value={filtroCalificacion}
                                        onChange={handleFiltroCalificacionChange}
                                        className="form-select"
                                    >
                                        <option value="">Todos</option>
                                        <option value="conCalificacion">Con Calificación</option>
                                        <option value="sinCalificacion">Sin Calificación</option>
                                    </select>
                                </div>
                            </div>
                        )}
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        {user.estudiante !== 1 && (
                                            <>
                                                <th scope="col" style={{ width: '1%' }}>#</th>
                                                <th scope="col" style={{ width: '40%' }}>Estudiante</th>
                                            </>
                                        )}
                                        <th scope="col-1" className="" style={{ width: '200px' }}>Calificacion</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* .sort((a, b) => a.nombres.toLowerCase().localeCompare(b.nombres.toLowerCase())) */}
                                    {estudiantesFiltrados
                                    .map((estudiante, index) => (
                                        <React.Fragment key={estudiante.id}>
                                        <tr>
                                            {user.estudiante !== 1 && (
                                                <>
                                                    <td>{index + 1}</td>
                                                    <td>{estudiante.nombres}</td>
                                                </>
                                            )}
                                            <td className="text-center">
                                                <form onSubmit={(e) => { e.preventDefault(); handleSave(index); }}>
                                                    <div className="input-group" style={{ width: '200px' }}>
                                                        <input 
                                                            type="text" 
                                                            value={editableIndex === index ? notas[index] : estudiante.nota || ''}
                                                            onInput={(e) => validateAndFormatValue(e, handleNotaChange, index)} // Usar onInput para aplicar la función de validación
                                                            disabled={editableIndex !== index} 
                                                            className="form-control"
                                                            ref={el => inputRefs.current[index] = el} // Asigna la referencia al input
                                                        />
                                                        {(editableIndex === index && (user.estudiante !== 1 && (user.id_docente === materia.id_docente || user.id_rol))) ? (
                                                            <>
                                                            <button 
                                                                type="submit" 
                                                                className="btn btn-sm btn-success input-group-prepend"
                                                            >
                                                                <i className="fa fa-save text-dark"></i>
                                                            </button>
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-sm btn-danger input-group-prepend"
                                                                onClick={handleCancelEdit}
                                                            >
                                                                <i className="fa fa-times"></i>
                                                            </button>
                                                            </>
                                                        ) : (
                                                            (user.estudiante !== 1 && user.id_docente === materia.id_docente || user.id_rol) && (
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-sm btn-primary input-group-prepend"
                                                                onClick={() => handleEditClick(index)}
                                                            >
                                                                <i className="fa fa-edit"></i>
                                                            </button>
                                                            )
                                                        )}
                                                    </div>
                                                </form>
                                            </td>
                                        </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Show;