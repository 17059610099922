import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import { useApiContext } from '../layout/Template';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import Mensaje from './Mensaje';
import Planificacion from './Planificacion';

const CACHE_DURATION = 1 * 60 * 1000; // 2 minutos en milisegundos
const cache = {};

const Contenido = () => {
    const { materiaId, parcialId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user, subdominio } = useApiContext();
    const [materia, setMateria] = useState(null);
    const [ciclos, setCiclos] = useState([]);
    const [subciclos, setParciales] = useState([]);
    const [evaluacion, setEvaluacion] = useState(null);
    const [examen, setExamen] = useState(null);
    const [proyecto, setProyecto] = useState(null);
    const [items, setItems] = useState([]);
    const [parcial, setParcial] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterDate, setFilterDate] = useState('');

    const handleDateChange = (date) => {
        if (date && moment(date, 'YYYY-MM-DD', true).isValid()) {
            const formattedDate = moment(date).format('YYYY-MM-DD');
            setFilterDate(formattedDate);
        } else {
            setFilterDate(''); // Restablecer el filtro si la fecha no es válida
        }
    };

    const filteredItems = Object.entries(items).reduce((acc, [fecha, registros]) => {
        const filteredRegistros = registros.filter(registro =>
            registro.titulo.toLowerCase().includes(searchTerm.toLowerCase())
        );

        if (filteredRegistros.length > 0 && (filterDate === '' || fecha === filterDate)) {
            acc[fecha] = filteredRegistros;
        }
        return acc;
    }, {});
    
    const fetchContenido = async () => {
        const now = Date.now();
        const cacheKey = `contenido-${materiaId}-${parcialId}`;

        if (cache[cacheKey] && (now - cache[cacheKey].timestamp < CACHE_DURATION)) {
            // Usa el caché
            const data = cache[cacheKey].data;
            setMateria(data.materia);
            setCiclos(data.ciclos);
            setParciales(data.subciclos);
            setEvaluacion(data.evaluacion);
            setExamen(data.examen);
            setProyecto(data.proyecto);
            setItems(data.items);
            setParcial(data.parcial);
            setLoading(false);
            return;
        }
        try {
            const response = await axios.get(`${apiUrl}/materia/contenido/${materiaId}/${parcialId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            const data = response.data;

            // Actualiza el caché
            cache[cacheKey] = {
                data: data,
                timestamp: Date.now(),
            };

            setMateria(data.materia);
            setCiclos(data.ciclos);
            setParciales(data.subciclos);
            setEvaluacion(data.evaluacion);
            setExamen(data.examen);
            setProyecto(data.proyecto);
            setItems(data.items);
            setParcial(data.parcial);
            console.log(data.evaluacion);
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchContenido();
    }, [materiaId, parcialId, apiUrl, accessToken]);
    
    const deleteActividad = async (id) => {
        try {
            await axios.delete(`${apiUrl}/actividad/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            fetchContenido(); // Actualiza la lista de productos después de eliminar
        } catch (error) {
            console.error('Error al eliminar la actividad:', error);
            // Manejo de errores según sea necesario
        }
    }
    
    const deleteClase = async (id) => {
        try {
            await axios.delete(`${apiUrl}/clase/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            fetchContenido(); // Actualiza la lista de productos después de eliminar
        } catch (error) {
            console.error('Error al eliminar la clase:', error);
            // Manejo de errores según sea necesario
        }
    }
    
    const deleteEvaluacion = async (evaluacionId) => {
        try {
            await axios.delete(`${apiUrl}/evaluacion/${evaluacionId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            fetchContenido(); // Actualiza la lista de productos después de eliminar
        } catch (error) {
            console.error('Error al eliminar la evaluacion:', error);
            // Manejo de errores según sea necesario
        }
    }
    
    const deleteExamen = async (examenId) => {
        try {
            await axios.delete(`${apiUrl}/examen/${examenId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            fetchContenido(); // Actualiza la lista de productos después de eliminar
        } catch (error) {
            console.error('Error al eliminar el examenn:', error);
            // Manejo de errores según sea necesario
        }
    }
    
    const deleteProyecto = async (proyectoId) => {
        try {
            await axios.delete(`${apiUrl}/proyecto/${proyectoId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            fetchContenido(); // Actualiza la lista de productos después de eliminar
        } catch (error) {
            console.error('Error al eliminar el proyecto:', error);
            // Manejo de errores según sea necesario
        }
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className="py-3 px-3 content-wrapper">
            <div className="d-sm-flex justify-content-between align-items-start mb-3">
                <div>
                    <h2 className="text-primary font-weight-bold">
                        {materia.materia.nombre} {materia.curso.nivel.nombre_corto}/{materia.curso.seccion.descripcion} 
                        {materia.curso.id_especialidad ? ` - ${materia.curso.especialidad.descripcion}` : ''} - 
                        {materia.curso.jornada.descripcion}
                        
                        <div className="btn-group ms-3" role="group">
                            <button className="btn btn-outline-primary btn-sm dropdown-toggle" id="subcicloDropdown" data-bs-toggle="dropdown" aria-expanded={dropdownOpen} onClick={toggleDropdown}>
                                {parcial.descripcion} - {parcial.ciclo.descripcion}
                            </button>
                            <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} aria-labelledby="subcicloDropdown">
                                {subciclos
                                    .sort((a, b) => a.id_ciclo - b.id_ciclo)
                                    .map(subciclo => (
                                        <li key={subciclo.id}>
                                            <Link 
                                                className={`dropdown-item ${subciclo.id === parcial.id ? 'active' : ''}`} 
                                                to={`/Materias/Contenido/${materiaId}/${subciclo.id}`}
                                            >
                                                {subciclo.descripcion} - {subciclo.ciclo.descripcion}
                                            </Link>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </h2>
                </div>
                <div>
                    <div className="btn-group" role="group">
                        <Link to={`/MisCursos/Info/${materia.id_curso}`} className="btn btn-warning btn-sm">
                            <i className="fas fa-chevron-circle-left"></i> <span className="d-none d-lg-inline"> Volver</span>
                        </Link>
                        {(user.estudiante !== 1 && materia.id_docente === user.id_docente || user.id_rol) && (
                            <>
                            <div className="btn-group" role="group">
                                <button className="btn btn-primary btn-sm dropdown-toggle" id="pdfDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="far fa-file-alt"></i> <span className="d-none d-lg-inline"> Calificaciones:</span>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="pdfDropdown">
                                    {/*
                                    <li>
                                        <Link className="dropdown-item" to={'/Calificaciones/'} target="_blank">Acumulado {materia.curso.periodo.descripcion}</Link>
                                    </li>
                                    */}
                                    {ciclos.map(ciclo => (
                                        <div key={ciclo.id}>
                                            <li><Link className="dropdown-item" to={`/Materia/Ciclo/${materia.id}/${ciclo.id}`}>{ciclo.descripcion}</Link></li>
                                            {subciclos
                                                .filter(subciclo => subciclo.id_ciclo === ciclo.id)
                                                .map(subciclo => (
                                                    <li key={subciclo.id}>
                                                        <Link 
                                                            className="dropdown-item" 
                                                            to={`/Materia/Parcial/${materia.id}/${subciclo.id}`}
                                                        >
                                                            {subciclo.descripcion} - {subciclo.ciclo.descripcion}
                                                        </Link>
                                                    </li>
                                                ))}
                                        </div>
                                    ))}
                                </ul>
                            </div>
                            </>
                        )}
                        {(user.estudiante !== 1 && materia.id_docente === user.id_docente || user.id_rol) && (
                            <>
                            <div className="btn-group" role="group">
                                <button className="btn btn-success btn-sm dropdown-toggle" id="configDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fas fa-cog"></i> <span className="d-none d-lg-inline"> Configuración</span>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="configDropdown">
                                    <li>
                                        <Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#mensajeModal">
                                            <i className="fas fa-comment-dots"></i> Mensaje/URL CLase
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#planificacionModal">
                                            <i className="fas fa-calendar-alt"></i> Planificación
                                        </Link>
                                    </li>
                                </ul>
                                <Mensaje apiUrl={apiUrl} materiaId={materiaId} accessToken={accessToken} parcialId={parcialId} />
                                <Planificacion apiUrl={apiUrl} materiaId={materiaId} accessToken={accessToken} parcialId={parcialId} />
                            </div>
                            <div className="btn-group" role="group">
                                <button className="btn btn-info btn-sm dropdown-toggle" id="evaluacionesDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fas fa-book-open"></i> <span className="d-none d-lg-inline"> Evaluaciones</span>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="evaluacionesDropdown">
                                    {/* 
                                        <li>
                                            <Link className="dropdown-item" to={`/Evaluacion/Create/${materia.id}/${parcialId}`}>
                                                <i className="fas fa-pencil-alt"></i> Aporte Parcial
                                            </Link>
                                        </li>
                                    */}
                                    <li>
                                        <Link className="dropdown-item" to={`/Examen/${materia.id}/${parcial.ciclo.id}`}>
                                            <i className="fas fa-clock"></i> Examen Trimestral
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" to={`/Proyecto/${materia.id}/${parcial.ciclo.id}/${parcialId}`}>
                                            <i className="fas fa-folder-open"></i> {subdominio === 'hacialacumbre' ? 'Aporte': 'Proyecto Trimestral'}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* { materia.mensaje && ( */}
                <div className="card container mb-2">
                    <div className="card-body">
                        <div className="">
                            <h4 className="card-title card-title-dash">{ materia.mensaje }</h4>
                            <Link to={`${materia.url}`} target="_blank">{ materia.url }</Link>
                        </div>
                    </div>
                </div>
            {/* )} */}

            <div className="card container">
                <div className="card-body">
                    <div className="d-md-flex justify-content-between align-items-start">
                        <div>
                            <h4 className="card-title card-title-dash">Actividades Curriculares Realizadas</h4>
                        </div>
                        <div className="btn-group mb-2" role="group">
                            {(user.estudiante !== 1 && materia.id_docente === user.id_docente || user.id_rol) && (
                                <>
                                    <Link className="btn btn-sm btn-primary text-white mb-0 me-0" to={`/Materias/Contenido/Actividades/create/${materia.id}/${parcialId}`}>
                                        <i className="fas fa-tasks"></i> <span className="d-none d-lg-inline"> Añadir Actividad</span>
                                    </Link>
                                    <Link className="btn btn-sm btn-success text-white mb-0 me-0" to={`/Materias/Contenido/Clase/create/${materia.id}/${parcialId}`}>
                                        <i className="fas fa-chalkboard-teacher"></i> <span className="d-none d-lg-inline"> Añadir Clase</span>
                                    </Link>
                                    <Link className="btn btn-sm btn-info text-white mb-0 me-0" to={`/Materias/Asistencia/${materia.id}/${parcialId}`}>
                                        <i className="fas fa-clipboard-list"></i> <span className="d-none d-lg-inline"> Asistencias</span>
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>
                    {/* Campo de entrada para filtrar */}
                    <div className='row'>
                        <div className='col-6'>
                            <h4>Buscar por título:</h4>
                            <input
                                type="text"
                                className="form-control" 
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                placeholder="Escribe un título..."
                            />
                        </div>
                        <div className='col-6'>
                            <h4>Filtrar por fecha:</h4>
                            <Datetime
                                onChange={handleDateChange}
                                value={filterDate ? moment(filterDate, 'YYYY-MM-DD').toDate() : null}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false} // Deshabilitar la hora
                                inputProps={{ placeholder: 'Selecciona una fecha' }} // Placeholder
                            />
                        </div>
                    </div>
                    <div className="card-text content mt-3">
                        <div className="p-0">
                            <div className="tab-pane show fade">
                                <div className="pb-2">
                                    {examen &&
                                        <div className="flex-column pt-2 pb-0 px-2">
                                            <div className="d-sm-flex justify-content-between align-items-start">
                                                <div className="d-flex mr-auto pb-1 mw-100">
                                                    <div className="align-self-top align-self-center mx-3 mb-1 mb-sm-0 text-nowrap">
                                                        <i className="fas fa-clock"></i>
                                                    </div>
                                                    <div className="event-name-container flex-grow-1 line-height-3 nowrap">
                                                        <div className="d-flex">
                                                            <h6 className="mb-0 pb-1">
                                                                <Link to={`/Materias/Contenido/Examen/${examen.id}`}>
                                                                    Examen
                                                                </Link>
                                                            </h6>
                                                        </div>
                                                        <small className="mb-0">
                                                            Examen
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="me-2">
                                                        <h6>
                                                            <Link className="btn btn-primary btn-sm text-nowrap text-capitalize" to={`/Materias/Contenido/Examen/${examen.id}`}>
                                                                Ver Examen
                                                            </Link>
                                                        </h6>
                                                    </div>
                                                    {(user.estudiante !== 1 && materia.id_docente === user.id_docente || user.id_rol) && (
                                                        <>
                                                        <div className="dropdown">
                                                            <button className="btn btn-sm btn-secondary" type="button" id="examen" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="fas fa-cogs"></i>
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="examen">
                                                                <li>
                                                                    <Link className="dropdown-item" to={`/Examen/edit/${examen.id}`}>Editar</Link>
                                                                </li>
                                                                <li><a className="dropdown-item text-danger" href="#" onClick={()=>deleteExamen(examen.id)}>Eliminar</a></li>
                                                            </ul>
                                                        </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="pt-2 border-bottom"></div>
                                        </div>
                                    }
                                    {proyecto &&
                                        <div className="flex-column pt-2 pb-0 px-2">
                                            <div className="d-sm-flex justify-content-between align-items-start">
                                                <div className="d-flex mr-auto pb-1 mw-100">
                                                    <div className="align-self-top align-self-center mx-3 mb-1 mb-sm-0 text-nowrap">
                                                        <i className="fas fa-clock"></i>
                                                    </div>
                                                    <div className="event-name-container flex-grow-1 line-height-3 nowrap">
                                                        <div className="d-flex">
                                                            <h6 className="mb-0 pb-1">
                                                                <Link to={`/Materias/Contenido/Proyecto/${proyecto.id}/${parcialId}`}>
                                                                    {subdominio === 'hacialacumbre' ? 'Aporte': 'Proyecto'}
                                                                </Link>
                                                            </h6>
                                                        </div>
                                                        <small className="mb-0">
                                                            Proyecto
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="me-2">
                                                        <h6>
                                                            <Link className="btn btn-primary btn-sm text-nowrap text-capitalize" to={`/Materias/Contenido/Proyecto/${proyecto.id}/${parcialId}`}>
                                                                Ver Proyecto
                                                            </Link>
                                                        </h6>
                                                    </div>
                                                    {(user.estudiante !== 1 && materia.id_docente === user.id_docente || user.id_rol) && (
                                                        <>
                                                        <div className="dropdown">
                                                            <button className="btn btn-sm btn-secondary" type="button" id="proyecto" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="fas fa-cogs"></i>
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="proyecto">
                                                                <li>
                                                                    <Link className="dropdown-item" to={`/Proyecto/edit/${proyecto.id}/${parcialId}`}>Editar</Link>
                                                                </li>
                                                                <li><a className="dropdown-item text-danger" href="#" onClick={()=>deleteProyecto(proyecto.id)}>Eliminar</a></li>
                                                            </ul>
                                                        </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="pt-2 border-bottom"></div>
                                        </div>
                                    }
                                    {evaluacion &&
                                        <div className="flex-column pt-2 pb-0 px-2">
                                            <div className="d-sm-flex justify-content-between align-items-start">
                                                <div className="d-flex mr-auto pb-1 mw-100">
                                                    <div className="align-self-top align-self-center mx-3 mb-1 mb-sm-0 text-nowrap">
                                                        <i className="fas fa-pencil-alt"></i>
                                                    </div>
                                                    <div className="event-name-container flex-grow-1 line-height-3 nowrap">
                                                        <div className="d-flex">
                                                            <h6 className="mb-0 pb-1">
                                                                <Link to={`/Materias/Contenido/Actividades/${evaluacion.id}`}>
                                                                    Evaluacion
                                                                </Link>
                                                            </h6>
                                                        </div>
                                                        <small className="mb-0">
                                                            Evaluacion
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="me-2">
                                                        <h6>
                                                            <Link className="btn btn-primary btn-sm text-nowrap text-capitalize" to={`/Materias/Contenido/Actividades/${evaluacion.id}`}>
                                                                Ver Evaluacion
                                                            </Link>
                                                        </h6>
                                                    </div>
                                                    {(user.estudiante !== 1 && materia.id_docente === user.id_docente || user.id_rol) && (
                                                        <>
                                                        <div className="dropdown">
                                                            <button className="btn btn-sm btn-secondary" type="button" id="evaluacion" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="fas fa-cogs"></i>
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="evaluacion">
                                                                <li>
                                                                    <Link className="dropdown-item" to={`/Materias/Contenido/Actividades/edit/${evaluacion.id}`}>Editar</Link>
                                                                </li>
                                                                <li><a className="dropdown-item text-danger" href="#" onClick={()=>deleteActividad(evaluacion.id)}>Eliminar</a></li>
                                                            </ul>
                                                        </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="pt-2 border-bottom"></div>
                                        </div>
                                    }
                                    {Object.entries(filteredItems).map(([fecha, registros]) => (
                                        <div key={fecha} className="mt-3">
                                            <h5 className="h6 d-inline font-weight-bold px-2">{fecha}</h5>
                                            <div className="list-group list-group-flush">
                                                {registros.map((registro) => (
                                                    <div key={registro.id} className="flex-column pt-2 pb-0 px-2">
                                                        <div className="d-sm-flex justify-content-between align-items-start">
                                                            <div className="d-flex mr-auto pb-1 mw-100">
                                                                {registro.tipo === "clase" && (
                                                                    <small className="text-right text-nowrap align-self-center ml-1">
                                                                        {registro.realizada === 1 ? (
                                                                            <div className="badge badge-success">Realizada</div>
                                                                        ) : (
                                                                            <div className="badge badge-warning">Pendiente</div>
                                                                        )}
                                                                    </small>
                                                                )}
                                                                <div className="align-self-top align-self-center mx-3 mb-1 mb-sm-0 text-nowrap">
                                                                    <i className={`fas ${registro.tipo === 'actividad' ? 'fa-paste' : 'fa-chalkboard'} fs-3`}></i>
                                                                </div>
                                                                <div className="event-name-container flex-grow-1 line-height-3 nowrap">
                                                                    <div className="d-flex">
                                                                        <h6 className="mb-0 pb-1">
                                                                            <Link to={registro.tipo === "actividad" ? `/Materias/Contenido/Actividades/${registro.id}` : `/Materias/Contenido/Clase/${registro.id}`}>
                                                                                {registro.titulo}
                                                                            </Link>
                                                                        </h6>
                                                                    </div>
                                                                    <small className="mb-0">
                                                                        {registro.resumen}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="me-2">
                                                                    <h6>
                                                                        <Link className="btn btn-primary btn-sm text-nowrap text-capitalize" to={registro.tipo === "actividad" ? `/Materias/Contenido/Actividades/${registro.id}` : `/Materias/Contenido/Clase/${registro.id}`}>
                                                                            Ver {registro.tipo}
                                                                        </Link>
                                                                    </h6>
                                                                </div>
                                                                {(user.estudiante !== 1 && materia.id_docente === user.id_docente || user.id_rol) && (
                                                                    <>
                                                                    <div className="dropdown">
                                                                        <button className="btn btn-sm btn-secondary" type="button" id={`dropdownMenuButton-${registro.id}`} data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="fas fa-cogs"></i>
                                                                        </button>
                                                                        <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${registro.id}`}>
                                                                            <li>
                                                                                <Link className="dropdown-item" to={registro.tipo === "actividad" ? `/Materias/Contenido/Actividades/edit/${registro.id}` : `/Materias/Contenido/Clase/edit/${registro.id}`}>Editar</Link>
                                                                            </li>
                                                                            <li><a className="dropdown-item text-danger" href="#" onClick={registro.tipo === "actividad" ? ()=>deleteActividad(registro.id) : ()=>deleteClase(registro.id)}>Eliminar</a></li>
                                                                        </ul>
                                                                    </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="pt-2 border-bottom"></div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contenido;