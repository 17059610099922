import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import imgbanner from '../../assets/img/edusnap/logo_hotizontal.png';

const Login = ({ logoCli }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    
    const hostname = window.location.hostname;
    const subdominio = hostname.split('.')[0];
    const tenantUrl = process.env.REACT_APP_API_TENANT_BASE_URL.replace('tenant', subdominio);

    const apiUrl = tenantUrl;
    const endpoint = `${apiUrl}/login`;

    const store = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(endpoint, { username, password });
            const { accessToken, user } = response.data;

            // Guardar el token y los datos del usuario en localStorage
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('user', JSON.stringify(user));

            // Redirigir al dashboard u otra página después del inicio de sesión exitoso
            navigate('/Dashboard');
        } catch (error) {
            //console.error('Error al iniciar sesión:', error);
            setError('Credenciales incorrectas.');
        }
    };
    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-center auth px-0">
                    <div className="row w-100 mx-0">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                {logoCli ? (
                                <div className="align-self-center justify-content-between text-center">
                                    <img src={logoCli} alt="edusnap" style={{ width: '15rem' }} />
                                </div>
                                ) : (
                                    <div className="brand-logo">
                                        <img src={imgbanner} alt="edusnap" style={{ width: '15rem' }} />
                                        </div>
                                )}
                                <h4>¡Hola! empecemos</h4>
                                <h6 className="fw-light">Inicia sesión para continuar.</h6>

                                {error && <div className="alert alert-danger mt-3">{error}</div>}

                                <form className="pt-3" onSubmit={store} method="POST">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="username"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            className="form-control form-control-lg"
                                            id="exampleInputEmail1"
                                            placeholder="Identificación"
                                            autoFocus
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="form-control form-control-lg"
                                            id="exampleInputPassword1"
                                            placeholder="Contraseña"
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <input
                                            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                            type="submit"
                                            value="INICIAR SESIÓN"
                                        />
                                    </div>
                                </form>
                                <div className="my-2 d-flex justify-content-between align-items-center">
                                    <Link to={`/Reset`} className="auth-link text-black">¿Has olvidado tu contraseña?</Link>
                                </div>
                                <div className="my-2 d-flex justify-content-between align-items-center">
                                    {/*<button className="auth-link text-black">Registro de Estudiante</button>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
