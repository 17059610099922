// src/routes/TipoCursoRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../componentes/auth/ProtectedRoute';
import Template from '../componentes/client/layout/Template';

// tipocurso
import Index from '../componentes/client/tipocurso/Index';
import Create from '../componentes/client/tipocurso/Create';
import Edit from '../componentes/client/tipocurso/Edit';

const TipoCursoRoutes = () => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <ProtectedRoute>
                        <Template>
                            <Index />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="create"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Create />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path=":tipoId"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Edit />
                        </Template>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default TipoCursoRoutes;
