import React, { useState, useEffect } from 'react';
import TextInput from '../../form/InputText';
import SelectInput from '../../form//SelectInput';
import CheckboxInput from '../../form//CheckboxInput';

const DatosFacturacion = ({ formData, provincias, cantones, errors, setFormData }) => {
    const [filteredCantones, setFilteredCantones] = useState([]);

    // Función que maneja los cambios de selección en la provincia
    const handleProvinciaChange = (e) => {
        const selectedProvinciaId = e.target.value;

        // Actualiza el valor de la provincia seleccionada en formData
        handleInputChange({
            target: {
                name: 'facturacion.id_provincia',
                value: selectedProvinciaId
            }
        });

        // Filtra los cantones basados en la provincia seleccionada
        const cantonesFiltrados = cantones.filter(canton => canton.id_provincia === parseInt(selectedProvinciaId));
        setFilteredCantones(cantonesFiltrados);

        // Resetea el cantón seleccionado
        //handleInputChange({
        //    target: {
        //        name: 'facturacion.id_canton',
        //        value: ''
        //    }
        //});
    };

    useEffect(() => {
        // Inicializa los cantones filtrados cuando cambien las provincias
        if (formData.facturacion.id_provincia) {
            handleProvinciaChange({ target: { value: formData.facturacion.id_provincia } });
        }
    }, [formData.facturacion.id_provincia, cantones]);

    useEffect(() => {
        if (formData.facturacion.consumidor_final && formData.isCliente ) {
            setFormData(prevData => ({
                ...prevData,
                facturacion: {
                    ...prevData.facturacion,
                    tipo_persona: '00',
                    ruc_ci: '9999999999999',
                    razon_social: 'Consumidor Final',
                    telefono_fact: '9999999999',
                    correo_fact: 'sincorreo@mail.com',
                    direccion_comprador: 'Ecuador',
                    id_provincia: '9',
                    id_canton: '75'
                }
            }));
        } else {
            setFormData(prevData => ({
                ...prevData,
                facturacion: {
                    ...prevData.facturacion,
                    consumidor_final: false, 
                    ruc_ci: '',
                    razon_social: '',
                    telefono_fact: '',
                    correo_fact: '',
                    direccion_comprador: '',
                    id_provincia: '',
                    id_canton: ''
                }
            }));
        }
    }, [formData.facturacion.consumidor_final, formData.isCliente]);

    const handleCheckboxChange = (field, subField = null) => (e) => {
        const checked = e.target.checked;
        setFormData(prevFormData => {
            if (subField) {
                // Caso para subcampos
                return {
                    ...prevFormData,
                    [field]: {
                        ...prevFormData[field],
                        [subField]: checked
                    }
                };
            } else {
                // Caso para campos en el nivel superior
                return {
                    ...prevFormData,
                    [field]: checked
                };
            }
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const [section, field] = name.split('.');

        setFormData(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [field]: value
            }
        }));
    };

    return (
        <div className="col-lg-5 col-md-6 col-sm-12 grid-margin stretch-card">
            <div className='card'>
                <div className="card-body">
                    <h4 className="card-title">Datos Facturación</h4>
                    <div className="form-group">
                        <div className='row'>
                            {formData.isCliente && (
                                <div className="form-check col-4">
                                    <CheckboxInput
                                        id="facturacion.consumidor_final"
                                        name="facturacion.consumidor_final"
                                        checked={formData.facturacion.consumidor_final}
                                        onChange={handleCheckboxChange('facturacion', 'consumidor_final')}
                                        label="Consumidor Final"
                                    />
                                </div>
                            )}
                            <div className="form-check col-4">
                                <CheckboxInput
                                    id="facturacion.contribuyente_especial"
                                    name="facturacion.contribuyente_especial"
                                    checked={formData.facturacion.contribuyente_especial}
                                    onChange={handleCheckboxChange('facturacion', 'contribuyente_especial')}
                                    label="Contribuyente Especial"
                                    disabled={formData.facturacion.consumidor_final === true}
                                />
                            </div>
                            {formData.facturacion.tipo_persona !== '02' && (
                                <div className="form-check col-4">
                                    <CheckboxInput 
                                        id="facturacion.extranjero"
                                        name="facturacion.extranjero"
                                        checked={formData.facturacion.extranjero}
                                        onChange={handleCheckboxChange('facturacion', 'extranjero')}
                                        label="Persona Extranjera"
                                        disabled={formData.facturacion.consumidor_final === true}
                                    />
                                </div>
                            )}
                            <div className="col-6">
                                <SelectInput
                                    id="facturacion.tipo_persona"
                                    name="facturacion.tipo_persona"
                                    value={formData.facturacion.tipo_persona}
                                    onChange={handleInputChange}
                                    placeholder="Tipo persona"
                                    options={[
                                        { value: '01', label: 'Natural' },
                                        { value: '02', label: 'Juridica' },
                                        { value: '00', label: 'Sin RUC/CI' }
                                    ]}
                                    error={errors['facturacion.tipo_persona'] && errors['facturacion.tipo_persona'][0]}
                                    disabled={formData.facturacion.consumidor_final === true}
                                />
                            </div>
                            <div className="col-6">
                                <TextInput
                                    id="ruc_ci"
                                    name="facturacion.ruc_ci"
                                    value={formData.facturacion.ruc_ci}
                                    onChange={handleInputChange}
                                    validacion={'entero'}
                                    placeholder="RUC/CI"
                                    errors={errors}
                                    readonly={formData.facturacion.consumidor_final === true}
                                />
                            </div>
                            <div className="col-lg-8 col-md-8">
                                <TextInput
                                    id="razon_social"
                                    name="facturacion.razon_social"
                                    value={formData.facturacion.razon_social}
                                    onChange={handleInputChange}
                                    placeholder="Razón Social"
                                    errors={errors}
                                    readonly={formData.facturacion.consumidor_final === true}
                                />
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <TextInput
                                    id="facturacion.telefono_fact"
                                    name="facturacion.telefono_fact"
                                    value={formData.facturacion.telefono_fact}
                                    onChange={handleInputChange}
                                    placeholder="Teléfono"
                                    errors={errors}
                                    readonly={formData.facturacion.consumidor_final === true}
                                />
                            </div>
                            <div className="col-lg-6">
                                <TextInput
                                    id="facturacion.correo_fact"
                                    name="facturacion.correo_fact"
                                    value={formData.facturacion.correo_fact}
                                    onChange={handleInputChange}
                                    placeholder="Correo"
                                    errors={errors}
                                    type="email"
                                    readonly={formData.facturacion.consumidor_final === true}
                                />
                            </div>
                            <div className="col-lg-6">
                                <TextInput
                                    id="facturacion.direccion_comprador"
                                    name="facturacion.direccion_comprador"
                                    value={formData.facturacion.direccion_comprador}
                                    onChange={handleInputChange}
                                    placeholder="Dirección"
                                    errors={errors}
                                    readonly={formData.facturacion.consumidor_final === true}
                                />
                            </div>
                            <div className="col-6">
                                <SelectInput
                                    id="facturacion.id_provincia"
                                    name="facturacion.id_provincia"
                                    value={formData.facturacion.id_provincia}
                                    onChange={handleProvinciaChange}
                                    placeholder="Provincia"
                                    options={provincias.map(provincia => ({
                                        value: provincia.id,
                                        label: provincia.nombre
                                    }))}
                                    error={errors['facturacion.id_provincia'] && errors['facturacion.id_provincia'][0]}
                                    disabled={formData.facturacion.consumidor_final === true}
                                />
                            </div>
                            <div className="col-6">
                                <SelectInput
                                    id="facturacion.id_canton"
                                    name="facturacion.id_canton"
                                    value={formData.facturacion.id_canton}
                                    onChange={handleInputChange}
                                    placeholder="Cantón"
                                    options={filteredCantones.map(canton => ({
                                        value: canton.id,
                                        label: canton.nombre
                                    }))}
                                    error={errors['facturacion.id_canton'] && errors['facturacion.id_canton'][0]}
                                    disabled={formData.facturacion.consumidor_final === true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DatosFacturacion;
