import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useApiContext } from '../layout/Template';
import { useNavigate } from 'react-router-dom';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import Parciales from './Parciales';
import MyBarChart from '../charts/MyBarChart';
import MyPieChart from '../charts/MyPieChart';

const Ciclo = () => {
    const { materiaId, cicloId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user, subdominio } = useApiContext();
    const [isLoading, setIsLoading] = useState(false);
    
    const [estudiantes, setEstudiantes] = useState([]);
    const [titulo, setTitulo] = useState([]);
    const [inicial, setInicial] = useState(null);
    const [elementos, setElementos] = useState([]);
    const [notas, setNotas] = useState([]);
    const [tests, setTests] = useState([]);
    const [notatests, setNotatests] = useState([]);
    const [examen, setExamen] = useState(null);
    const [notaexamenes, setNotaexamenes] = useState([]);
    const [integrador, setIntegrador] = useState(null);
    const [notaproyectos, setNotaproyectos] = useState([]);
    const [ciclos, setCiclos] = useState([]);
    const [subciclos, setSubciclos] = useState([]);
    const [subciclosMat, setSubciclosMat] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/sabana/materia/ciclo/${materiaId}/${cicloId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            const data = response.data;
            setEstudiantes(data.estudiantes);
            setTitulo(data.titulo);
            setInicial(data.inicial);
            setElementos(data.elementos);
            setNotas(data.notas);
            setTests(data.tests);
            setNotatests(data.notatests);
            setExamen(data.examen);
            setNotaexamenes(data.notaexamenes);
            setIntegrador(data.integrador);
            setNotaproyectos(data.notaproyectos);
            setCiclos(data.ciclos);
            setSubciclos(data.subciclos);
            setSubciclosMat(data.subciclosMat);

            console.log(data)
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    //navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [materiaId, cicloId]); 

    const pdf = async () => {
        if (isLoading) return; // Evitar ejecutar si ya está cargando

        setIsLoading(true);
        console.log("procesando");
        try {
            const response = await axios.get(`${apiUrl}/sabana/materia/ciclo/pdf/${materiaId}/${cicloId}/${promedio}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/pdf', // Asegúrate de que la respuesta sea un PDF
                },
                responseType: 'blob' // Importante para manejar la respuesta como archivo binario
            });
    
            // Crear un enlace de descarga
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
    
            // Forma el nombre del archivo con un formato seguro para nombres de archivo
            const nombreArchivo = `${titulo.descripcion.replace(/[\/\\?%*:|"<>]/g, '-')}.pdf`;
    
            link.setAttribute('download', nombreArchivo);
            document.body.appendChild(link);
            link.click();
    
            // Liberar el objeto URL y eliminar el enlace del DOM
            window.URL.revokeObjectURL(url);
            link.remove();
        } catch (err) {
            console.error('Error al descargar el PDF:', err);
            window.showErrorPopup('Error al descargar el archivo PDF.');
        } finally {
            setIsLoading(false);
        }
        console.log("fin");
    };

    const totalSubciclos = subciclosMat.length;
    
    const tipoElementoClase = {
        1: 'tarea',
        2: 'leccion',
        3: 'individual',
        4: 'grupal',
        5: 'exposicion'
    };

    const calcularDatosEstudiantes = () => {
        const totalesArray = [];

        estudiantes.forEach((estudiante) => {
            let sumaParcial = 0;
            const totalSubciclos = subciclosMat.length; // Total de subciclos para el cálculo del promedio

            subciclosMat.forEach((subciclo) => {
                // Filtra los elementos del subciclo actual
                const elementosSubciclo = elementos.filter(elemento =>
                    elemento.id_subciclo === subciclo.id
                );
                const totalElementos = elementosSubciclo.length;

                // Calcula la suma de las calificaciones para el subciclo actual
                const sumaSubciclo = elementosSubciclo.reduce((subtotal, elemento) => {
                    const nota = notas.find(nota =>
                        nota.id_estudiante === estudiante.id &&
                        nota.id_elemento === elemento.id
                    );
                    return subtotal + (parseFloat(nota?.calificacion) || 0);
                }, 0);

                // Calcula el promedio
                const promedioElementos = totalElementos > 0 ? sumaSubciclo / totalElementos : 0;
                const porcElementos = promedioElementos * 0.7;

                // Filtra los tests del subciclo actual
                const testSubciclo = tests.filter(test =>
                    test.id_subciclo === subciclo.id
                );
                const notaTest = notatests.find(nota =>
                    testSubciclo.some(test =>
                        test.id === nota.id_elemento && nota.id_estudiante === estudiante.id
                    )
                );
                const calificacionTest = notaTest ? parseFloat(notaTest.calificacion) : 0;
                const porcTest = calificacionTest * 0.3;
                const promParcial = porcElementos + porcTest;

                sumaParcial += promParcial;
            });

            // Calcula el promedio final y el porcentaje
            const promFormativo = totalSubciclos > 0 ? sumaParcial / totalSubciclos : 0;
            const porcFormativo = promFormativo * 0.7;

            const notaExamen = examen ? 
                notaexamenes.find(notaex =>
                    notaex.id_estudiante === estudiante.id &&
                    notaex.id_examen === examen.id
                )?.calificacion || 0
                : 0;
            const porcExamen = notaExamen * 0.2;

            const notaProy = integrador ? 
                notaproyectos.find(notap =>
                    notap.id_estudiante === estudiante.id &&
                    notap.id_proyecto === integrador.id
                )?.calificacion || 0
                : 0;
            const porcProy = notaProy * 0.1;

            const porcSumativa = porcExamen + porcProy;

            const notaFinal = porcFormativo + porcSumativa;

            totalesArray.push({ 
                valor: parseFloat(notaFinal).toFixed(2),
                cualitativa: getCualtitativa(notaFinal),
                aprovechamiento: getAprovechamiento(notaFinal) // Puedes eliminar esto si no lo necesitas aún
            });
        });

        return totalesArray;
    };
    
    const getCualtitativa = (notaFInal) => {
        if(inicial === 1){
            if (notaFInal < 4) return "N.E.";
            if (notaFInal >= 4.01 && notaFInal <= 6.99) return "I";
            if (notaFInal >= 7 && notaFInal < 8.99) return "EP";
            if (notaFInal >= 9 && notaFInal <= 10) return "A";
            return "N.E.";
        }else{
            if (notaFInal >= 1 && notaFInal < 2) return "E-";
            if (notaFInal >= 2 && notaFInal < 3) return "E+";
            if (notaFInal >= 3 && notaFInal < 4) return "D-";
            if (notaFInal >= 4 && notaFInal < 5) return "D+";
            if (notaFInal >= 5 && notaFInal < 6) return "C-";
            if (notaFInal >= 6 && notaFInal < 7) return "C+";
            if (notaFInal >= 7 && notaFInal < 8) return "B-";
            if (notaFInal >= 8 && notaFInal < 9) return "B+";
            if (notaFInal >= 9 && notaFInal < 10) return "A-";
            if (notaFInal === 10) return "A+";
            return "E-";
        }
    };

    const getAprovechamiento = (notaFInal) => {
        if (notaFInal >= 0 && notaFInal < 4) return "NAAR";
        if (notaFInal >= 4.01 && notaFInal < 6.99) return "PAAR";
        if (notaFInal >= 7.00 && notaFInal < 8.99) return "AAR";
        if (notaFInal >= 9 && notaFInal === 10) return "DAR";
    };

    const getEscalasDisponibles = () => {
        if (inicial === 1) {
            return ["A", "EP", "I", "N.E."];
        } else {
            return ["A+", "A-", "B+", "B-", "C+", "C-", "D+", "D-", "E+", "E-"];
        }
    };

    const getEscalaAprovechamiento = () => {
        return ["DAR", "AAR", "PAAR", "NAAR"];
    };

    // Calcula totalesArray
    const totalesArray = calcularDatosEstudiantes();

    // Función para calcular el promedio de los valores del campo 'valor'
    const calcularPromedio = (array) => {
        if (array.length === 0) return 0; // Retorna 0 si el array está vacío
    
        // Suma todos los valores del campo 'valor', convirtiéndolos a números
        const suma = array.reduce((acc, item) => acc + parseFloat(item.valor), 0);
    
        // Calcula y retorna el promedio
        return suma / array.length;
    };
    
    // Llama a la función para obtener el promedio
    const promedio = calcularPromedio(totalesArray);
    
    console.log('promedio: ' + promedio); // Imprime el promedio en la consola

    const contarEscalas = (totalesArray, escalas) => {
        const conteo = {};
    
        // Inicializa el conteo con 0 para cada escala
        escalas.forEach(escala => {
            conteo[escala] = 0;
        });
    
        // Cuenta cuántas veces aparece cada escala
        totalesArray.forEach(item => {
            const escala = item.cualitativa;
            if (conteo.hasOwnProperty(escala)) {
                conteo[escala] += 1;
            }
        });
    
        // Convierte el conteo en formato para el gráfico
        return escalas.map(escala => ({
            cualitativa: escala,
            valor: conteo[escala] || 0
        }));
    };
    // Preparar los datos para el gráfico
    const escalasDisponibles = getEscalasDisponibles();
    const datosGrafico = contarEscalas(totalesArray, escalasDisponibles);

    const contarAprovechamiento = (totalesArray, escalasAprovechamiento) => {
        const conteo = {};
    
        // Inicializa el conteo con 0 para cada escala de aprovechamiento
        escalasAprovechamiento.forEach(escala => {
            conteo[escala] = 0;
        });
    
        // Cuenta cuántas veces aparece cada escala de aprovechamiento
        totalesArray.forEach(item => {
            const escala = item.aprovechamiento;
            if (conteo.hasOwnProperty(escala)) {
                conteo[escala] += 1;
            }
        });
    
        // Convierte el conteo en formato para el gráfico
        return escalasAprovechamiento.map(escala => ({
            aprovechamiento: escala,
            valor: conteo[escala] || 0
        }));
    };
    const aprovchamientos = getEscalaAprovechamiento();
    const datosGrafico2 = contarAprovechamiento(totalesArray, aprovchamientos);
    const datosTransformados = datosGrafico2.map(item => ({
        name: item.aprovechamiento, // Asigna la clave 'name'
        value: item.valor            // Asigna la clave 'value'
    }));
    /*
    if (loading) {
        return <Spinner />;
    }
    */

    return (
        <div className='py-3 px-3 content-wrapper'>
            <style>
                {`
                    div.vertical{
                    width: 45px;
                    transform: rotate(-90deg);
                    -webkit-transform: rotate(-90deg); /* Safari/Chrome */
                    -moz-transform: rotate(-90deg); /* Firefox */
                    -o-transform: rotate(-90deg); /* Opera */
                    -ms-transform: rotate(-90deg); /* IE 9 */
                    }

                    th.vertical{
                    height: 115px;
                    padding-bottom: 0;
                    margin-bottom: 0;
                    }

                    .tarea {
                        border-bottom: 10px solid #1F3BB3 !important;
                    }

                    .leccion {
                        border-bottom: 10px solid rgb(224, 129, 66) !important;
                    }

                    .individual {
                        border-bottom: 10px solid rgb(29, 209, 241) !important;
                    }

                    .grupal {
                        border-bottom: 10px solid rgb(138, 140, 255) !important;
                    }

                    .exposicion {
                        border-bottom: 10px solid rgb(57, 206, 149) !important;
                    }

                    .formativo {
                        border-bottom: 10px solid #0d6efd !important;
                    }

                    .proyecto {
                        border-bottom: 10px solid yellow !important;
                    }

                    .promedio {
                        border-bottom: 10px solid #198754 !important;
                    }
                `}
            </style>
            <div className="d-sm-flex justify-content-between align-items-start mb-2">
                <div>
                    <h2><strong>{ titulo.descripcion }</strong></h2>
                </div>
                <div>
                    <div class="btn-group mb-2" role="group" aria-label="Basic outlined example">
                        <Link to={`/Materias/Contenido/${materiaId}/${cicloId}`} class="btn btn-warning btn-sm"><i class="fas fa-chevron-circle-left"></i> <span className="d-none d-lg-inline"> Volver</span></Link>
                    </div>
                </div>
            </div>
            <div className='row'>
                <h2>Promedio: {parseFloat(promedio).toFixed(2)} - Cualitativa Prom: {getCualtitativa(promedio)}</h2>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <MyBarChart data={datosGrafico} dataKeyX={"cualitativa"} />
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <MyBarChart data={datosGrafico2} dataKeyX={"aprovechamiento"}/>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <MyPieChart data={datosTransformados}/>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="d-sm-flex justify-content-between align-items-start mb-2">
                        <div>
                            <h2><strong>{ titulo.subtitulo }</strong></h2>
                            <Parciales ciclos={ciclos} subciclos={subciclos} materiaId={materiaId} />
                        </div>
                        <div>
                            <button class="btn btn-primary" onClick={pdf} disabled={isLoading}><i class="fas fa-file-pdf"></i> <span className="d-none d-lg-inline"> Generar PDF</span></button>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>
                                        Estudiantes
                                    </th>
                                    {subciclosMat.map((subciclo, index) => {
                                        // Determina la clase basada en id_tipoelemento

                                        return (
                                            <th
                                                key={index}
                                                style={{ width: "45px" }}
                                                className='formativo'
                                            >
                                                <div className="vertical">
                                                    <a href="#" className="text-decoration-none">
                                                        {subciclo.descripcion}
                                                    </a>
                                                </div>
                                            </th>
                                        );
                                    })}
                                    <th style={{ width: "45px" }} className="vertical formativo"><div className="vertical">Formativos</div></th>
                                    <th style={{ width: "45px" }} className="vertical formativo"><div className="vertical">70%</div></th>
                                    <th style={{ width: "45px" }} className="vertical proyecto"><div className="vertical">Examen</div></th>
                                    <th style={{ width: "45px" }} className="vertical proyecto"><div className="vertical">{subdominio === 'hacialacumbre' ? 'Aporte': 'Proyecto'}Proyecto</div></th>
                                    {/* <th style={{ width: "45px" }} className="vertical proyecto"><div className="vertical">Sumativa</div></th> */}
                                    <th style={{ width: "45px" }} className="vertical proyecto"><div className="vertical">30%</div></th>
                                    <th style={{ width: "45px" }} className="vertical promedio"><div className="vertical">Promedio</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {estudiantes.map((estudiante, estIndex) => {
                                    // Inicializa sumaParcial para cada estudiante
                                    let sumaParcial = 0;
                                    const totalSubciclos = subciclosMat.length; // Total de subciclos para el cálculo del promedio

                                    // Calcula el promedio formativo para cada subciclo
                                    subciclosMat.forEach((subciclo) => {
                                        // Filtra los elementos del subciclo actual
                                        const elementosSubciclo = elementos.filter(elemento =>
                                            elemento.id_subciclo === subciclo.id
                                        );
                                        const totalElementos = elementosSubciclo.length;

                                        // Calcula la suma de las calificaciones para el subciclo actual
                                        const sumaSubciclo = elementosSubciclo.reduce((subtotal, elemento) => {
                                            const nota = notas.find(nota =>
                                                nota.id_estudiante === estudiante.id &&
                                                nota.id_elemento === elemento.id
                                            );
                                            return subtotal + (parseFloat(nota?.calificacion) || 0);
                                        }, 0);

                                        // Calcula el promedio
                                        const promedioElementos = totalElementos > 0 ? sumaSubciclo / totalElementos : 0;
                                        const porcElementos = promedioElementos * 0.7;

                                        // Filtra los tests del subciclo actual
                                        const testSubciclo = tests.filter(test =>
                                            test.id_subciclo === subciclo.id
                                        );
                                        // Encuentra la nota del test para el estudiante
                                        const notaTest = notatests.find(nota =>
                                            testSubciclo.some(test =>
                                                test.id === nota.id_elemento && nota.id_estudiante === estudiante.id
                                            )
                                        );
                                        //linea 164, 480, 553
                                        //test.id === nota.id_evaluacion && nota.id_estudiante === estudiante.id
                                        const calificacionTest = notaTest ? parseFloat(notaTest.calificacion) : 0;
                                        const porcTest = calificacionTest * 0.3;
                                        const promParcial = porcElementos + porcTest;

                                        // Acumula la suma parcial
                                        sumaParcial += promParcial;
                                    });

                                    // Calcula el promedio final y el porcentaje
                                    const promFormativo = totalSubciclos > 0 ? sumaParcial / totalSubciclos : 0;
                                    const porcFormativo = promFormativo * 0.7;
                                    
                                    // Calcula la calificación de examen si existe
                                    const notaExamen = examen ? 
                                        notaexamenes.find(notaex =>
                                            notaex.id_estudiante === estudiante.id &&
                                            notaex.id_examen === examen.id
                                        )?.calificacion || 0
                                        : 0;

                                    const porcExamen = notaExamen * 0.2;
                                    
                                    // Calcula la calificación de examen si existe
                                    const notaProy = integrador ? 
                                        notaproyectos.find(notap =>
                                            notap.id_estudiante === estudiante.id &&
                                            notap.id_proyecto === integrador.id
                                        )?.calificacion || 0
                                        : 0;

                                    const porcProy = notaProy * 0.1;

                                    const porcSumativa = porcExamen + porcProy;

                                    const notaFInal = porcFormativo + porcSumativa;

                                    return (
                                        <tr key={estIndex}>
                                            <td>{estudiante.estudiante.nombres}</td>
                                            {subciclosMat.map((subciclo, subIndex) => {
                                                // Filtra los elementos del subciclo actual
                                                const elementosSubciclo = elementos.filter(elemento =>
                                                    elemento.id_subciclo === subciclo.id
                                                );
                                                const totalElementos = elementosSubciclo.length;

                                                // Calcula la suma de las calificaciones para el subciclo actual
                                                const sumaSubciclo = elementosSubciclo.reduce((subtotal, elemento) => {
                                                    const nota = notas.find(nota =>
                                                        nota.id_estudiante === estudiante.id &&
                                                        nota.id_elemento === elemento.id
                                                    );
                                                    return subtotal + (parseFloat(nota?.calificacion) || 0);
                                                }, 0);

                                                // Calcula el promedio
                                                const promedioElementos = totalElementos > 0 ? sumaSubciclo / totalElementos : 0;
                                                const porcElementos = promedioElementos * 0.7;

                                                // Filtra los tests del subciclo actual
                                                const testSubciclo = tests.filter(test =>
                                                    test.id_subciclo === subciclo.id
                                                );
                                                // Encuentra la nota del test para el estudiante
                                                const notaTest = notatests.find(nota =>
                                                    testSubciclo.some(test =>
                                                        test.id === nota.id_elemento && nota.id_estudiante === estudiante.id
                                                    )
                                                );
                                                const calificacionTest = notaTest ? parseFloat(notaTest.calificacion) : 0;
                                                const porcTest = calificacionTest * 0.3;
                                                const promParcial = porcElementos + porcTest;

                                                return (
                                                    <td 
                                                        key={subIndex}
                                                        style={{ width: "45px" }}
                                                        className={parseFloat(promParcial) < 7 ? 'text-danger' : ''}
                                                    >
                                                        {promParcial.toFixed(2)}
                                                    </td>
                                                );
                                            })}
                                            <td 
                                                style={{ width: "45px" }}
                                                className={parseFloat(promFormativo) < 7 ? 'text-danger' : ''}
                                            >
                                                {promFormativo.toFixed(2)}
                                            </td>
                                            <td style={{ width: "45px" }}>
                                                {porcFormativo.toFixed(2)}
                                            </td>
                                            <td 
                                                style={{ width: "45px" }}
                                                className={parseFloat(notaExamen) < 7 ? 'text-danger' : ''}
                                            >
                                                {parseFloat(notaExamen).toFixed(2)}
                                            </td>
                                            <td 
                                                style={{ width: "45px" }}
                                                className={parseFloat(notaProy) < 7 ? 'text-danger' : ''}
                                            >
                                                {parseFloat(notaProy).toFixed(2)}
                                            </td>
                                            <td style={{ width: "45px" }}>
                                                {porcSumativa.toFixed(2)}
                                            </td>
                                            <td 
                                                style={{ width: "45px" }}
                                                className={parseFloat(notaFInal) < 7 ? 'text-danger' : ''}
                                            >
                                                {parseFloat(notaFInal).toFixed(2)}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ciclo;
