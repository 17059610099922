import React from 'react';
import { Link } from 'react-router-dom';

const ParcialesCurso = ({ ciclos, subciclos, cursoId }) => {

    return (
        <div className="btn-group ms-3" role="group">
            <button
                className="btn btn-outline-primary btn-sm dropdown-toggle"
                id="subcicloDropdown"
                data-bs-toggle="dropdown"
            >
                Parciales
            </button>
            <ul className={`dropdown-menu`} aria-labelledby="subcicloDropdown">
                {/*
                <li> <Link className="dropdown-item" to={`/Materia/Acumulado/${materiaId}`} >
                        Acumulado
                    </Link>
                </li>
                */}
                {ciclos
                .sort((a, b) => a.id - b.id)
                .map(ciclo => (
                    <React.Fragment key={ciclo.id}>
                    {subciclos
                        .filter(subciclo => subciclo.id_ciclo === ciclo.id)
                        .map(subciclo => (
                        <li key={subciclo.id}>
                            <Link
                            className="dropdown-item"
                            to={`/MisCursos/Comportamiento/${cursoId}/${subciclo.id}`}
                            >
                            {subciclo.descripcion} - {subciclo.ciclo.descripcion}
                            </Link>
                        </li>
                        ))}
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );
};

export default ParcialesCurso;
