import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useApiContext } from '../layout/Template';
import Spinner from '../widget/Spinner';
import DatosGeneralesContext from '../widget/DatosGeneralesContext';
import DatosRepresentante from './DatosRepresentante';
import DatosFacturacion from './DatosFacturacion';
import DatosTipo from './DatosTipo';

const Edit = () => {
    const { apiUrl, accessToken } = useApiContext();
    const { id } = useParams(); // Obtén el ID del parámetro de la URL
    const [formData, setFormData] = useState({
        isCliente: false,
        isProveedor: false,
        representante: {
            identificacion: '',
            extranjero: false,
            nombre: '',
            correo: '',
            celular: '',
            telefono: '',
            direccion: '',
            sexo: '',
        },
        facturacion: {
            consumidor_final: 0,
            tipo_persona: '01',
            contribuyente_especial: false,
            extranjero: false,
            ruc_ci: '',
            razon_social: '',
            telefono_fact: '',
            correo_fact: '',
            direccion_comprador: '',
            id_provincia: '',
            id_canton: ''
        }
    });
    const [estudiantes, setEstudiantes] = useState([]);

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const { cantones, provincias } = DatosGeneralesContext(apiUrl);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/personas/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    }
                });

                const personaData = response.data.persona;

                setFormData({
                    isCliente: personaData.cliente || false,
                    isProveedor: personaData.proveedor || false,
                    representante: {
                        identificacion: personaData.identificacion || '',
                        extranjero: personaData.extranjero || false,
                        nombre: personaData.nombres || '',
                        correo: personaData.correo || '',
                        celular: personaData.celular || '',
                        telefono: personaData.telefono || '',
                        direccion: personaData.direccion || '',
                        sexo: personaData.sexo || '',
                    },
                    facturacion: {
                        consumidor_final: personaData.consumidor_final || 0,
                        tipo_persona: personaData.tipo_persona || '01',
                        contribuyente_especial: personaData.contribuyente_especial || false,
                        extranjero: personaData.extranjero_fact || false,
                        ruc_ci: personaData.ruc_ci || '',
                        razon_social: personaData.razon_social || '',
                        telefono_fact: personaData.telefono_fact || '',
                        correo_fact: personaData.correo_fact || '',
                        direccion_comprador: personaData.direccion_comprador || '',
                        id_provincia: personaData.id_provincia || '',
                        id_canton: personaData.id_canton || ''
                    }
                });

                setEstudiantes(response.data.estudiantes);
                console.log(response.data.estudiantes)
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener los datos para editar:', error.response ? error.response.data : error.message);
                setLoading(false);
            }
        };

        fetchData();
    }, [id, apiUrl, accessToken]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!formData.isCliente && !formData.isProveedor) {
            window.showErrorPopup("Debe seleccionar al menos uno: Cliente o Proveedor.");
            return;
        }
        try {
            const response = await axios.put(`${apiUrl}/personas/${id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            console.log('Persona actualizada exitosamente:', response.data);
            navigate('/personas');
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            if (error.response) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error al actualizar la persona:', error.response ? error.response.data : error.message);
            }
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Editar Persona</h2>
                </div>
            </div>
            
            <form onSubmit={handleSubmit}>
                <div className='row'>
                    <DatosTipo
                        formData={formData}
                        setFormData={setFormData}
                    />
                    <DatosRepresentante
                        formData={formData}
                        setFormData={setFormData}
                        errors={errors}
                    />
                    <DatosFacturacion
                        formData={formData}
                        setFormData={setFormData}
                        provincias={provincias}
                        cantones={cantones}
                        errors={errors}
                    />
                </div>
                <button type="submit" className="btn btn-primary me-2"><i className="fas fa-save"></i> Guardar Cambios</button>
                <Link className="btn btn-warning" to='/personas'>Volver</Link>
            </form>
        </div>
    );
};

export default Edit;
