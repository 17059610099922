// src/routes/BoletinesRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../componentes/auth/ProtectedRoute';
import Template from '../componentes/client/layout/Template';

// niveles
import Subciclo from '../componentes/client/boletines/Subciclo';
import Ciclo from '../componentes/client/boletines/Ciclo';
import Acumulado from '../componentes/client/boletines/Acumulado';

const BoletinesRoutes = () => {
    return (
        <Routes>
            <Route
                path="Parcial/:matriculaId/:subcicloId"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Subciclo />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="Ciclo/:matriculaId/:cicloId"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Ciclo />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="Acumulado/:matriculaId"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Acumulado />
                        </Template>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default BoletinesRoutes;
