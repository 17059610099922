import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import { useApiContext } from '../layout/Template';
import QuillEditor  from '../widget/QuillEditor';

const Test = () => {
    const { proyectoId, parcialId } = useParams();
    const { apiUrl, accessToken } = useApiContext();
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const editorRef = useRef(null);
    //variables
    const [descripcion, setDescripcion] = useState('');
    const [fecha, setFecha] = useState('');
    const [materia, setMateria] = useState(0);
    const [parcial, setParcial] = useState(0);
    const endpoint = `${apiUrl}/proyecto/update/${proyectoId}`;

    const fetchProyecto = async () => {
        try {
            const response = await axios.get(`${apiUrl}/proyecto/${proyectoId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}` // Agrega el token de acceso si es necesario
                }
            });
            const proyecto = response.data.proyecto;
            setMateria(proyecto.id_materiacurso || '');
            setParcial(proyecto.id_subciclo || '');

            // Establecer el valor de la descripción
            setDescripcion(proyecto.descripcion || '');
            if (editorRef.current) {
                editorRef.current.root.innerHTML = proyecto.descripcion || '';
            }
            setFecha(proyecto.fecha || '');
        } catch (err) {
            if (err.response && err.response.status === 422) {
                setErrors(err.response.data.errors);
            } else if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    navigate('/dashboard');
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false); // Finaliza el estado de carga
        }
    };

    useEffect(() => {
        fetchProyecto();
    }, [proyectoId, apiUrl, accessToken]);
    
    useEffect(() => {
        if (editorRef.current && descripcion) {
            editorRef.current.clipboard.dangerouslyPasteHTML(descripcion);
        }
    }, [descripcion]);

    const update = async (e) => {
        e.preventDefault();
        
        // Obtén el contenido del editor desde el ref
        const editorContent = editorRef.current?.root.innerHTML || '';
        const newErrors = {};

        // Validación de descripción
        const textLength = editorContent.replace(/<[^>]*>/g, '').length; // Elimina etiquetas HTML y mide longitud
        if (textLength > 50000) {
            newErrors.descripcion = 'La descripción es obligatoria y debe tener entre 3 y 10000 caracteres.';
        }
    
        // Validación de fechas
        if (!fecha)
            newErrors.fecha = 'La fecha es obligatoria.';
    
        // Actualiza el estado con los errores encontrados
        setErrors(newErrors);

        // Verifica si hay errores antes de enviar el formulario
        if (Object.keys(newErrors).length === 0) {
            // Aquí podrías enviar los datos del formulario
            console.log({ descripcion: editorContent });
        }
    
        const formData = new FormData();
        formData.append('descripcion', editorContent);
        formData.append('fecha', fecha);
    
        try {
            const response = await axios.post(endpoint, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            });
            //navigate(`/Materias/Contenido/Proyecto/${response.data.id}`);
            window.showSuccessPopup(response.data.message);
            // Maneja la respuesta de éxito aquí
        } catch (err) {
            if (err.response && err.response.status === 422) {
                setErrors(err.response.data.errors);
            } else if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    navigate('/dashboard');
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className="container-scroller">
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Actualizar Proyecto</h2>
                </div>
            </div>

            <div className="col-md-6 offset-md-3 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={update} encType="multipart/form-data">
                            <div className="mb-3">
                                <label htmlFor="descripcion" className={`form-control ${errors.descripcion ? 'is-invalid' : ''}`}>Descripción</label>
                                <QuillEditor ref={editorRef} />
                                {errors.descripcion && <div className="invalid-feedback">{errors.descripcion}</div>}
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-12 mb-3">
                                    <label for="fecha">Fecha</label>
                                    <input 
                                        type="date" 
                                        className={`form-control ${errors.fecha ? 'is-invalid' : ''}`} 
                                        id="fecha" 
                                        name="fecha" 
                                        placeholder="fecha"
                                        value={fecha}
                                        onChange={(e) => setFecha(e.target.value)}
                                    />
                                    {errors.fecha && <div className="invalid-feedback">{errors.fecha}</div>}
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary me-2 boton-procesar">
                                <i className="fas fa-save"></i> Actualizar Proyecto
                            </button>
                            <Link className="btn btn-warning" to={`/Materias/Contenido/${materia}/${parcialId}`}>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Test;
