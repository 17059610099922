// src/componentes/client/cursos/ListaEstudiantes.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import { validateAndFormatValue } from '../widget/ValidateAndFormatValue';

// Objeto de caché
const cache = {};
const CACHE_DURATION = 10 * 60 * 60 * 1000; // 10 horas en milisegundos

const ListaEstudiantes = ({ apiUrl, accessToken, user, curso, ciclos, subciclos, materias, ocultarBoletinDocente }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [estudiantes, setEstudiantes] = useState([]);
    const [cantones, setCantones] = useState([]);
    const [representantes, setRepresentantes] = useState([]);
    const [search, setSearch] = useState('');
    const [estado, setEstado] = useState('');
    const [extranjero, setExtranjero] = useState('');
    const [sexo, setSexo] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [representante, setRepresentante] = useState('');
    const [estudiantesPagination, setEstudiantesPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedEstudiante, setSelectedEstudiante] = useState(null);
    const [cursos, setCursos] = useState([])  // Estado para almacenar los datos obtenidos del endpoint
    const [selectedCurso, setSelectedCurso] = useState(null);
    const [showModalProm, setShowModalProm] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);

    const handleShowModal = async (estudiante) => {
        setSelectedEstudiante(estudiante);
        setShowModal(true);
        try {
            // Realiza la consulta al endpoint cuando se abre el modal
            const response = await axios.get(`${apiUrl}/cursos/listar/${curso.id_periodo}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            setCursos(response.data.cursos);  // Almacena los datos en el estado
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    //navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedEstudiante(null);
        setCursos([]);  // Limpia los datos del estado cuando se cierra el modal
        setSelectedCurso(null); // Reinicia el curso seleccionado
    };

    const getCursoNombre = () => {
        const cursoSeleccionado = cursos.find(curso => curso.id === selectedCurso);
        return cursoSeleccionado ? cursoSeleccionado.nombre : 'Ninguno';
    };

    const trasladarEstudiante = async () => {
        if (!selectedEstudiante || !selectedCurso) {
            alert('Por favor, selecciona un estudiante y un curso.');
            return;
        }

        try {
            const response = await axios.post(`${apiUrl}/transladar`, {
                estudiante_id: selectedEstudiante.id,
                curso_id: selectedCurso
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            handleCloseModal(); // Cierra el modal después de la transferencia
            navigate(`/MisCursos/Info/${selectedCurso}`, { replace: true }); // Reemplaza la entrada en el historial para evitar acumulación
            window.showSuccessPopup(response.data.message);
            return;
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    //navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            handleCloseModal();
        }
    };

    const handleShowModalPromedio = async (estudiante) => {
        setSelectedEstudiante(estudiante);
        setShowModalProm(true);
        
    };

    const handleCloseModalPromedio = () => {
        setShowModalProm(false);
        setSelectedEstudiante(null);
    };

    const generarPromedios = async (event) => {
        event.preventDefault(); // Evita que el formulario se envíe de forma predeterminada
    
        const formData = new FormData(event.target);
        const materiasData = [];
    
        // Itera sobre los valores del formulario y filtra solo aquellos con promedios válidos
        for (let [name, value] of formData.entries()) {
            if (name.includes('promedio') && value !== '' && parseFloat(value) !== 0) {
                const index = name.match(/\d+/)[0];
                const id_materia = formData.get(`materias[${index}][id_materia]`);
                const id_estudiante = formData.get(`materias[${index}][id_estudiante]`);
                
                materiasData.push({
                    id_materia,
                    id_estudiante,
                    promedio: value,
                });
            }
        }
    
        if (materiasData.length > 0) {
            try {
                const response = await axios.post(`${apiUrl}/generarPromedios`, {
                    materias: materiasData
                }, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    }
                });
                handleCloseModalPromedio();
                window.showSuccessPopup(response.data.message);
            } catch (err) {
                if (err.response) {
                    const errorMessage = err.response.data.error;
                    const errorCode = err.response.data.code;
                    if (errorCode === 403) {
                        window.showErrorPopup(errorMessage);
                        return;
                    }
                    window.showErrorPopup(errorMessage);
                } else if (err.request) {
                    window.showErrorPopup('No se recibió respuesta del servidor.');
                } else {
                    window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
                }
            } finally {
                handleCloseModalPromedio();
            }
        } else {
            window.showErrorPopup('No se ingresaron promedios válidos.');
        }
    };

    const handleNotaChange = (index, value) => {
        console.log(`Index: ${index}, Value: ${value}`);
    };

    //eliminar
    const handleShowDelete = async (estudiante) => {
        setSelectedEstudiante(estudiante);
        setShowModalDelete(true);
    };

    const handleCloseModalDelete = () => {
        setShowModalDelete(false);
        setSelectedEstudiante(null);
    };

    const eliminarEstudiante = async () => {
        if (!selectedEstudiante) {
            alert('Por favor, selecciona un estudiante.');
            return;
        }

        try {
            const response = await axios.delete(`${apiUrl}/estudiantecurso/${selectedEstudiante.id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            setShowModalDelete(); // Cierra el modal después de la transferencia
            window.showSuccessPopup(response.data.message);
            return;
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    //navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setShowModalDelete();
        }
    };
    
    const fetchEstudiantes = async () => {

        const cacheKey = `estudiantes-${curso.id}-${currentPage}-${search}-${estado}-${extranjero}-${sexo}-${ciudad}-${representante}`;
        const now = Date.now();

        // Verifica si el caché existe y es válido
        if (cache[cacheKey] && (now - cache[cacheKey].timestamp < CACHE_DURATION)) {
            setEstudiantes(cache[cacheKey].data.estudiantes.data);
            setEstudiantesPagination(cache[cacheKey].data.estudiantes);
            setCantones(cache[cacheKey].data.cantones);
            setRepresentantes(cache[cacheKey].data.representantes);
            setLoading(false);
            return;
        }

        try {
            const params = {
                page: currentPage,
                search,
                estado,
                extranjero,
                sexo,
                ciudad,
                representante,
            };
            const response = await axios.get(`${apiUrl}/miscursos/matriculados/${curso.id}?page=${currentPage}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                },
                params,
            });
            const data = response.data;

            // Actualiza el caché
            cache[cacheKey] = {
                data: data,
                timestamp: now // Marca de tiempo para la expiración
            };

            setEstudiantes(data.estudiantes.data);
            setEstudiantesPagination(data.estudiantes); // Guarda el objeto de paginación
            setCantones(data.cantones);
            setRepresentantes(data.representantes);
            console.log(data.estudiantes);
        } catch (err) {
            setError(err.response?.data?.error || 'Error al cargar los estudiantes');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (search.length >= 3 || !search) {
            fetchEstudiantes();
        }
    }, [apiUrl, accessToken, currentPage, search, estado, extranjero, sexo, ciudad, representante, curso]);

    const handleFilterChange = (setter) => (e) => {
        setter(e.target.value);
        setCurrentPage(1); // Reiniciar a la primera página
    };

    const clearFilters = () => {
        setSearch('');
        setEstado('');
        setExtranjero('');
        setSexo('');
        setCiudad(null); // Resetear a null
        setRepresentante(null); // Resetear a null
        fetchEstudiantes(); // Volver a cargar sin filtros
    };

    const representanteOptions = representantes.map(representante => ({
        value: representante.id,
        label: representante.nombres
    }));

    const ciudadOptions = cantones.map(canton => ({
        value: canton.id,
        label: canton.nombre
    }));

    const ulStyle = {
        maxHeight: '200px', // Ajusta según sea necesario
        overflowY: 'auto',
        padding: 0,
        listStyleType: 'none',
        margin: 0
    };

    const liStyle = {
        borderBottom: '1px solid #ddd',
        padding: '8px',
        display: 'flex',
        alignItems: 'center'
    };


    if (loading) return <div>Cargando estudiantes...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="card mt-3 mb-0 pb-0">
            <div className="card-body pb-0">
                <form>
                    <div className="row g-2">
                        <div className="col-md-8">
                            <label className="form-label">Palabras Clave</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                name="search" 
                                value={search} 
                                onChange={handleFilterChange(setSearch)} 
                                placeholder="Buscar..."
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault(); // Evita el envío del formulario
                                    }
                                }}
                            />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            <label className="form-label">Estado Inscripción</label>
                            <select className="form-select" name="estado" value={estado} onChange={handleFilterChange(setEstado)}>
                                <option value="">Todos</option>
                                <option value="S">Activo</option>
                                <option value="N">Inactivo</option>
                            </select>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-4">
                            <label className="form-label">Extranjero</label>
                            <select className="form-select" name="extranjero" value={extranjero} onChange={handleFilterChange(setExtranjero)}>
                                <option value="">Todos</option>
                                <option value="S">Sí</option>
                                <option value="N">No</option>
                            </select>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-4">
                            <label className="form-label">Sexo</label>
                            <select className="form-select" name="sexo" value={sexo} onChange={handleFilterChange(setSexo)}>
                                <option value="">Todos</option>
                                <option value="M">Masculino</option>
                                <option value="F">Femenino</option>
                                <option value="O">Otro</option>
                            </select>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            <label className="form-label">Representante</label>
                            <Select
                                options={representanteOptions}
                                value={representante} // Usar el valor del estado
                                onChange={setRepresentante}
                                isClearable
                                placeholder="Seleccionar Representante"
                            />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4">
                            <label className="form-label">Ciudad</label>
                            <Select
                                options={ciudadOptions}
                                value={ciudad} // Usar el valor del estado
                                onChange={setCiudad}
                                isClearable
                                placeholder="Seleccionar Ciudad"
                            />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 mt-2">
                        <button type="button" className="btn btn-outline-danger" onClick={clearFilters}>Limpiar filtros</button>
                    </div>
                </form>
            </div>
            <div className="card-body pt-0">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Estudiante</th>
                                <th scope="col" className="d-none d-sm-table-cell">Representante</th>
                                {(curso.id_tutor === user.id_docente || user.id_rol) && (
                                    <>
                                    <th scope="col">Información</th>
                                    <th scope="col">Boletín</th>
                                    </>
                                )}
                                <th scope="col" className="d-none d-sm-table-cell">Estado</th>
                                {( user.id_rol ) && (
                                    <>
                                    <th scope="col">OPC. <i className="fas fa-user-cog"></i></th>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {estudiantes.map(estudiante => (
                                <tr key={estudiante.id}>
                                    <th>{estudiante.estudiante.nombres} <span className="d-none d-lg-inline"> - {estudiante.estudiante.identificacion}</span></th>
                                    <td className="d-none d-sm-table-cell">{estudiante.estudiante.representante.nombres}</td>
                                    {(curso.id_tutor === user.id_docente || user.id_rol) && (
                                        <>
                                        <td className='text-center'><a href="#" className="links fs-6"><i className="far fa-file-alt"></i> <span className="d-none d-lg-inline"> Información Matricula</span></a></td>
                                        <td>
                                            {user.docente === 1 && ocultarBoletinDocente === 1 ? (
                                                <div>No disponible</div>
                                            ) : (
                                                <div className="dropdown">
                                                    <button className="btn btn-success" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="menu-icon fas fa-clipboard-list"></i> <span className="d-none d-lg-inline"> Ver Boletín</span>
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        {ciclos.map((ciclo) => (
                                                            <React.Fragment key={ciclo.id}>
                                                                <li>
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        to={`/Boletin/Ciclo/${estudiante.id}/${ciclo.id}`}
                                                                    >
                                                                        {ciclo.descripcion}
                                                                    </Link>
                                                                </li>
                                                                {subciclos.filter(subciclo => subciclo.id_ciclo === ciclo.id).map((subciclo) => (
                                                                    <li key={subciclo.id}>
                                                                        <Link
                                                                            className="dropdown-item"
                                                                            to={`/Boletin/Parcial/${estudiante.id}/${subciclo.id}`}
                                                                        >
                                                                            {subciclo.descripcion} - {subciclo.ciclo.descripcion}
                                                                        </Link>
                                                                    </li>
                                                                ))}
                                                            </React.Fragment>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </td>
                                        </>
                                    )}
                                    <td className="d-none d-sm-table-cell">
                                        <label className={`badge badge-${estudiante.estado === 1 ? 'success' : 'danger'}`}>{estudiante.estado === 1 ? 'Activo' : 'Inactivo'}</label>
                                    </td>
                                    {(user.id_rol) && (
                                        <>
                                        <td>
                                            <div className="dropdown">
                                                <button className="btn btn-primary" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="fas fa-user-cog"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <li>
                                                        <button 
                                                            className="dropdown-item" 
                                                            type="button" 
                                                            onClick={() => handleShowModal(estudiante)}
                                                        >
                                                            Trasladar
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button 
                                                            className="dropdown-item" 
                                                            type="button" 
                                                            onClick={() => handleShowModalPromedio(estudiante)}
                                                        >
                                                            Generar Promedios
                                                        </button>
                                                    </li>
                                                    {/* 
                                                    <li>
                                                        <Link 
                                                            className="dropdown-item" 
                                                            to={`#`}
                                                        >
                                                            Editar
                                                        </Link>
                                                    </li>
                                                    */}
                                                    <li>
                                                        <button 
                                                            className="dropdown-item" 
                                                            type="button" 
                                                            onClick={() => handleShowDelete(estudiante)}
                                                        >
                                                            Eliminar
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                        </>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="row mt-2 pb-0 mb-0">
                    <div className="col-sm-12 col-md-5">
                        <div>
                            Mostrando {estudiantesPagination.from} a {estudiantesPagination.to} de {estudiantesPagination.total} resultados
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-7">
                        <ul className="pagination">
                            {estudiantesPagination.links.map((link) => (
                                <li key={link.label} className={`paginate_button page-item ${link.active ? 'active' : ''}`}>
                                    <button 
                                        onClick={() => link.url && setCurrentPage(link.label)}
                                        className="page-link"
                                        disabled={!link.url}
                                        dangerouslySetInnerHTML={{ __html: link.label }}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            {showModal && (
                <div className="modal fade show d-block" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Trasladar Estudiante</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}></button>
                            </div>
                            <div className="modal-body py-0">
                                {selectedEstudiante ? (
                                <div>
                                    <p><strong>Estudiante:</strong> {selectedEstudiante.estudiante.nombres}</p>
                                    <p><strong>Identificación:</strong> {selectedEstudiante.estudiante.identificacion}</p>

                                    <h6>Cursos Disponibles:</h6>
                                    {cursos.length > 0 ? (
                                        <ul style={ulStyle}>
                                            {cursos.map((curso) => (
                                                <li key={curso.id} style={liStyle}>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="curso"
                                                            id={`curso-${curso.id}`}
                                                            value={curso.id}
                                                            checked={selectedCurso === curso.id}
                                                            onChange={() => setSelectedCurso(curso.id)}
                                                        />
                                                        <label className="form-check-label" htmlFor={`curso-${curso.id}`}>
                                                            {curso.nombre}
                                                        </label>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>Cargando...</p>
                                    )}

                                </div>
                                ) : (
                                <p>No se seleccionó ningún estudiante.</p>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Cerrar</button>
                                <button type="button" className="btn btn-primary" onClick={trasladarEstudiante}>Confirmar Traslado</button>
                            </div>
                            <div className="modal-footer">
                                <p><strong>Curso a transferir:</strong> {getCursoNombre()}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showModalProm && (
                <div className="modal fade show d-block" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Generar Promedios</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModalPromedio}></button>
                            </div>
                            <form onSubmit={generarPromedios}>
                                <div className="modal-body py-0">
                                    {selectedEstudiante ? (
                                        <div>
                                            <p><strong>Estudiante:</strong> {selectedEstudiante.estudiante.nombres}</p>
                                            <p><strong>Identificación:</strong> {selectedEstudiante.estudiante.identificacion}</p>
                                            <h6>Materias Disponibles:</h6>
                                            <ul className="list-unstyled">
                                                {materias.map((materia, index) => (
                                                    <li key={index} className="d-flex justify-content-between align-items-center mb-2">
                                                        <span>{materia.materia.nombre}</span>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            onInput={(e) => validateAndFormatValue(e, handleNotaChange, index)}
                                                            name={`materias[${index}][promedio]`}
                                                            placeholder="Prom."
                                                            style={{ width: '70px' }}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name={`materias[${index}][id_materia]`}
                                                            value={materia.id}
                                                        />
                                                        <input
                                                            type="hidden"
                                                            name={`materias[${index}][id_estudiante]`}
                                                            value={selectedEstudiante.id}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    ) : (
                                        <p>No se seleccionó ningún estudiante.</p>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={handleCloseModalPromedio}>Cerrar</button>
                                    <button type="submit" className="btn btn-primary">Generar Promedios</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {showModalDelete && (
                <div className="modal fade show d-block" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Eliminar Estudiante</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModalDelete}></button>
                            </div>
                            <div className="modal-body py-0">
                                {selectedEstudiante ? (
                                <div>
                                    <p><strong>Estudiante:</strong> {selectedEstudiante.estudiante.nombres}</p>
                                    <p><strong>Identificación:</strong> {selectedEstudiante.estudiante.identificacion}</p>
                                </div>
                                ) : (
                                <p>No se seleccionó ningún estudiante.</p>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={handleCloseModalDelete}>Cerrar</button>
                                <button type="button" className="btn btn-danger" onClick={eliminarEstudiante}>Eliminar</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListaEstudiantes;
