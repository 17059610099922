import React from 'react';
import PdfModal from './PDFModal';
import ImageGallery from './ImageGallery';

const FileViewer = ({ fileName, filePath }) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();

    switch (fileExtension) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
            return <ImageGallery archivos={[filePath]} />;
        case 'pdf':
            return <PdfModal pdfUrl={filePath} id={fileName} />;
        default:
            return (
                <a
                    href={filePath}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                >
                    Ver/Descargar Archivo
                </a>
            );
    }
};

export default FileViewer;
