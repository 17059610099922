import React, { useState, useEffect } from 'react';
import TextInput from '../../form/InputText';
import SelectInput from '../../form//SelectInput';

const DatosFicha = ({ formData, errors, setFormData }) => {

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const [section, field] = name.split('.');

        setFormData(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [field]: value
            }
        }));
    };

    return (
        <div className="col-lg-6 col-md-6 col-sm-12 grid-margin stretch-card">
            <div className='card'>
                <div className="card-body">
                    <h4 className="card-title">Contactos</h4>
                    <div className="form-group">
                        <h5>Contacto 1</h5>
                        <div className='row'>
                            <div className="col-12">
                                <SelectInput
                                    id="ficha.parentezco1"
                                    name="ficha.parentezco1"
                                    value={formData.ficha.parentezco1}
                                    onChange={handleInputChange}
                                    placeholder="Parentezco"
                                    options={[
                                        { value: 'Padre', label: 'Padre' },
                                        { value: 'Madre', label: 'Madre' },
                                        { value: 'Hermano/a', label: 'Hermano/a' },
                                        { value: 'Tio/a', label: 'Tio/a' },
                                        { value: 'Abuelo/a', label: 'Abuelo/a' },
                                        { value: 'Otro', label: 'Otro' },
                                    ]}
                                    error={errors['ficha.parentezco1'] && errors['ficha.parentezco1'][0]}
                                />
                            </div>
                            <div className="col-lg-6">
                                <TextInput
                                    id="ficha.nombre_contacto1"
                                    name="ficha.nombre_contacto1"
                                    value={formData.ficha.nombre_contacto1}
                                    onChange={handleInputChange}
                                    placeholder="Nombres"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-lg-6">
                                <TextInput
                                    id="ficha.celular_contacto1"
                                    name="ficha.celular_contacto1"
                                    value={formData.ficha.celular_contacto1}
                                    onChange={handleInputChange}
                                    placeholder="Cel/Telf"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-lg-6">
                                <TextInput
                                    id="ficha.domicilio_contacto1"
                                    name="ficha.domicilio_contacto1"
                                    value={formData.ficha.domicilio_contacto1}
                                    onChange={handleInputChange}
                                    placeholder="Dir. Domicilio"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-lg-6">
                                <TextInput
                                    id="ficha.dir_trabajo_contacto1"
                                    name="ficha.dir_trabajo_contacto1"
                                    value={formData.ficha.dir_trabajo_contacto1}
                                    onChange={handleInputChange}
                                    placeholder="Dir. Trabajo"
                                    errors={errors}
                                />
                            </div>
                        </div>
                        <h5>Contacto 2</h5>
                        <div className='row'>
                            <div className="col-12">
                                <SelectInput
                                    id="ficha.parentezco2"
                                    name="ficha.parentezco2"
                                    value={formData.ficha.parentezco2}
                                    onChange={handleInputChange}
                                    placeholder="Parentezco"
                                    options={[
                                        { value: 'Padre', label: 'Padre' },
                                        { value: 'Madre', label: 'Madre' },
                                        { value: 'Hermano/a', label: 'Hermano/a' },
                                        { value: 'Tio/a', label: 'Tio/a' },
                                        { value: 'Abuelo/a', label: 'Abuelo/a' },
                                        { value: 'Otro', label: 'Otro' },
                                    ]}
                                    error={errors['ficha.parentezco2'] && errors['ficha.parentezco2'][0]}
                                />
                            </div>
                            <div className="col-lg-6">
                                <TextInput
                                    id="ficha.nombre_contacto2"
                                    name="ficha.nombre_contacto2"
                                    value={formData.ficha.nombre_contacto2}
                                    onChange={handleInputChange}
                                    placeholder="Nombres"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-lg-6">
                                <TextInput
                                    id="ficha.celular_contacto2"
                                    name="ficha.celular_contacto2"
                                    value={formData.ficha.celular_contacto2}
                                    onChange={handleInputChange}
                                    placeholder="Cel/Telf"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-lg-6">
                                <TextInput
                                    id="ficha.domicilio_contacto2"
                                    name="ficha.domicilio_contacto2"
                                    value={formData.ficha.domicilio_contacto2}
                                    onChange={handleInputChange}
                                    placeholder="Dir. Domicilio"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-lg-6">
                                <TextInput
                                    id="ficha.dir_trabajo_contacto2"
                                    name="ficha.dir_trabajo_contacto2"
                                    value={formData.ficha.dir_trabajo_contacto2}
                                    onChange={handleInputChange}
                                    placeholder="Dir. Trabajo"
                                    errors={errors}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DatosFicha;
