import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useApiContext } from '../layout/Template';
import { useNavigate } from 'react-router-dom';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../widget/Spinner';
//import MyAreaChart from '../charts/MyAreaChart';
import MyBarChart from '../charts/MyBarChart';
import MyPieChart from '../charts/MyPieChart';
import Parciales from './Parciales';

const Subciclo = () => {
    const { materiaId, subcicloId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user } = useApiContext();
    const [isLoading, setIsLoading] = useState(false);
    
    const [estudiantes, setEstudiantes] = useState([]);
    const [titulo, setTitulo] = useState([]);
    const [parcial, setParcial] = useState(null);
    const [materia, setMateria] = useState(null);
    const [curso, setCurso] = useState(null);
    const [inicial, setInicial] = useState(null);
    const [elementos, setElementos] = useState([]);
    const [notas, setNotas] = useState([]);
    const [evaluacion, setEvaluacion] = useState(null);
    const [notaevaluaciones, setNotaevaluaciones] = useState([]);
    const [ciclos, setCiclos] = useState([]);
    const [subciclos, setSubciclos] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/sabana/materia/subciclo/${materiaId}/${subcicloId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            const data = response.data;
            setEstudiantes(data.estudiantes);
            setTitulo(data.titulo);
            setParcial(data.parcial);
            setMateria(data.materia);
            setCurso(data.curso);
            setInicial(data.inicial);
            setElementos(data.elementos);
            setNotas(data.notas);
            setEvaluacion(data.evaluacion);
            setNotaevaluaciones(data.notaevaluaciones);
            setCiclos(data.ciclos);
            setSubciclos(data.subciclos);

            console.log(data)
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [materiaId, subcicloId]); 

    const pdf = async () => {
        if (isLoading) return; // Evitar ejecutar si ya está cargando

        setIsLoading(true);
        console.log("procesando");
        try {
            const response = await axios.get(`${apiUrl}/sabana/materia/subciclo/pdf/${materiaId}/${subcicloId}/${promedio}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/pdf',
                },
                responseType: 'blob'
            });
    
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
    
            const nombreArchivo = `${titulo.descripcion.replace(/[\/\\?%*:|"<>]/g, '-')}.pdf`;
    
            link.setAttribute('download', nombreArchivo);
            document.body.appendChild(link);
            link.click();
    
            window.URL.revokeObjectURL(url);
            link.remove();
        } catch (err) {
            console.error('Error al descargar el PDF:', err);
            window.showErrorPopup('Error al descargar el archivo PDF.');
        } finally {
            setIsLoading(false);
        }
        console.log("fin");
    };

    const totalElementos = elementos.length;
    
    const tipoElementoClase = {
        1: 'tarea',
        2: 'leccion',
        3: 'individual',
        4: 'grupal',
        5: 'exposicion'
    };

    const calcularDatosEstudiantes = () => {
        const totalesArray = [];
    
        estudiantes.forEach((estudiante, index) => {
            // Filtra las notas del estudiante
            const notasEstudiante = notas.filter(nota =>
                nota.id_estudiante === estudiante.id
            );
            
            // Calcula la suma acumulada de las calificaciones
            const sumaElementos = notasEstudiante.reduce((acumulador, nota) => {
                const calificacion = parseFloat(nota.calificacion) || 0;
                return acumulador + calificacion;
            }, 0);
    
            // Calcula el promedio
            const promedio = totalElementos > 0 ? sumaElementos / totalElementos : 0;
    
            // Calcula la calificación de evaluación si existe
            const calificacionEvaluacion = evaluacion ? 
                notaevaluaciones.find(notaeval =>
                    notaeval.id_estudiante === estudiante.id &&
                    notaeval.id_elemento === evaluacion.id
                )?.calificacion || 0
                : 0;
    
            const total = (promedio * 0.7) + (calificacionEvaluacion * 0.3);
    
            // Añade el total y su valor cualitativo al array de totales
            totalesArray.push({
                valor: parseFloat(total).toFixed(2),
                cualitativa: getCualtitativa(total),
                aprovechamiento: getAprovechamiento(total)
            });
        });
    
        // Log para verificar los datos acumulados
        console.log("Totales Array:", totalesArray);
    
        return totalesArray;
    };

    // Función para calcular la escala cuantitativa
    const getCualtitativa = (valorTotal) => {
        if(inicial === 1){
            if (valorTotal < 4) return "N.E.";
            if (valorTotal >= 4.01 && valorTotal <= 6.99) return "I";
            if (valorTotal >= 7 && valorTotal < 8.99) return "EP";
            if (valorTotal >= 9 && valorTotal <= 10) return "A";
            return "N.E.";
        }else{
            if (valorTotal >= 1 && valorTotal < 2) return "E-";
            if (valorTotal >= 2 && valorTotal < 3) return "E+";
            if (valorTotal >= 3 && valorTotal < 4) return "D-";
            if (valorTotal >= 4 && valorTotal < 5) return "D+";
            if (valorTotal >= 5 && valorTotal < 6) return "C-";
            if (valorTotal >= 6 && valorTotal < 7) return "C+";
            if (valorTotal >= 7 && valorTotal < 8) return "B-";
            if (valorTotal >= 8 && valorTotal < 9) return "B+";
            if (valorTotal >= 9 && valorTotal < 10) return "A-";
            if (valorTotal === 10) return "A+";
            return "E-";
        }
    };

    const getEscalasDisponibles = () => {
        if (inicial === 1) {
            return ["A", "EP", "I", "N.E."];
        } else {
            return ["A+", "A-", "B+", "B-", "C+", "C-", "D+", "D-", "E+", "E-"];
        }
    };

    const getAprovechamiento = (valorTotal) => {
        if (valorTotal >= 0 && valorTotal < 4) return "NAAR";
        if (valorTotal >= 4.01 && valorTotal < 6.99) return "PAAR";
        if (valorTotal >= 7.00 && valorTotal < 8.99) return "AAR";
        if (valorTotal >= 9 && valorTotal === 10) return "DAR";
    };

    const getEscalaAprovechamiento = () => {
        return ["DAR", "AAR", "PAAR", "NAAR"];
    };

    // Llama a la función para obtener los datos
    const totalesArray = calcularDatosEstudiantes();

    // Función para calcular el promedio de los valores del campo 'valor'
    const calcularPromedio = (array) => {
        if (array.length === 0) return 0; // Retorna 0 si el array está vacío
    
        // Suma todos los valores del campo 'valor', convirtiéndolos a números
        const suma = array.reduce((acc, item) => acc + parseFloat(item.valor), 0);
    
        // Calcula y retorna el promedio
        return suma / array.length;
    };
    
    // Llama a la función para obtener el promedio
    const promedio = calcularPromedio(totalesArray);
    
    console.log('promedio: ' + promedio); // Imprime el promedio en la consola

    const contarEscalas = (totalesArray, escalas) => {
        const conteo = {};
    
        // Inicializa el conteo con 0 para cada escala
        escalas.forEach(escala => {
            conteo[escala] = 0;
        });
    
        // Cuenta cuántas veces aparece cada escala
        totalesArray.forEach(item => {
            const escala = item.cualitativa;
            if (conteo.hasOwnProperty(escala)) {
                conteo[escala] += 1;
            }
        });
    
        // Convierte el conteo en formato para el gráfico
        return escalas.map(escala => ({
            cualitativa: escala,
            valor: conteo[escala] || 0
        }));
    };
    // Preparar los datos para el gráfico
    const escalasDisponibles = getEscalasDisponibles();
    const datosGrafico = contarEscalas(totalesArray, escalasDisponibles);

    const contarAprovechamiento = (totalesArray, escalasAprovechamiento) => {
        const conteo = {};
    
        // Inicializa el conteo con 0 para cada escala de aprovechamiento
        escalasAprovechamiento.forEach(escala => {
            conteo[escala] = 0;
        });
    
        // Cuenta cuántas veces aparece cada escala de aprovechamiento
        totalesArray.forEach(item => {
            const escala = item.aprovechamiento;
            if (conteo.hasOwnProperty(escala)) {
                conteo[escala] += 1;
            }
        });
    
        // Convierte el conteo en formato para el gráfico
        return escalasAprovechamiento.map(escala => ({
            aprovechamiento: escala,
            valor: conteo[escala] || 0
        }));
    };
    
    const aprovchamientos = getEscalaAprovechamiento();
    const datosGrafico2 = contarAprovechamiento(totalesArray, aprovchamientos);
    const datosTransformados = datosGrafico2.map(item => ({
        name: item.aprovechamiento, // Asigna la clave 'name'
        value: item.valor            // Asigna la clave 'value'
    }));
    console.log(datosGrafico2)

    /*
    if (loading) {
        return <Spinner />;
    }
    */

    return (
        <div className='py-3 px-3 content-wrapper'>
            <style>
                {`
                    div.vertical{
                    width: 45px;
                    transform: rotate(-90deg);
                    -webkit-transform: rotate(-90deg); /* Safari/Chrome */
                    -moz-transform: rotate(-90deg); /* Firefox */
                    -o-transform: rotate(-90deg); /* Opera */
                    -ms-transform: rotate(-90deg); /* IE 9 */
                    }

                    th.vertical{
                    height: 115px;
                    padding-bottom: 0;
                    margin-bottom: 0;
                    }

                    .tarea {
                        border-bottom: 10px solid #1F3BB3 !important;
                    }

                    .leccion {
                        border-bottom: 10px solid rgb(224, 129, 66) !important;
                    }

                    .individual {
                        border-bottom: 10px solid rgb(29, 209, 241) !important;
                    }

                    .grupal {
                        border-bottom: 10px solid rgb(138, 140, 255) !important;
                    }

                    .exposicion {
                        border-bottom: 10px solid rgb(57, 206, 149) !important;
                    }

                    .formativo {
                        border-bottom: 10px solid #0d6efd !important;
                    }

                    .proyecto {
                        border-bottom: 10px solid yellow !important;
                    }

                    .promedio {
                        border-bottom: 10px solid #198754 !important;
                    }
                `}
            </style>
            <div className="d-sm-flex justify-content-between align-items-start mb-2">
                <div>
                    <h2><strong>{ titulo.descripcion }</strong></h2>
                </div>
                <div>
                    <div class="btn-group mb-2" role="group" aria-label="Basic outlined example">
                        <Link to={`/Materias/Contenido/${materiaId}/${subcicloId}`} class="btn btn-warning btn-sm"><i class="fas fa-chevron-circle-left"></i> <span className="d-none d-lg-inline"> Volver</span></Link>
                    </div>
                </div>
            </div>
            <div className='row'>
                <h2>Promedio: {parseFloat(promedio).toFixed(2)} - Cualitativa Prom: {getCualtitativa(promedio)}</h2>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <MyBarChart data={datosGrafico} dataKeyX={"cualitativa"} />
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <MyBarChart data={datosGrafico2} dataKeyX={"aprovechamiento"}/>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12'>
                    <MyPieChart data={datosTransformados}/>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="d-sm-flex justify-content-between align-items-start mb-2">
                        <div>
                            <h2><strong>{ titulo.subtitulo }</strong></h2>
                            <Parciales ciclos={ciclos} subciclos={subciclos} materiaId={materiaId} />
                        </div>
                        <div>
                            <button class="btn btn-primary" onClick={pdf} disabled={isLoading}><i class="fas fa-file-pdf"></i> <span className="d-none d-lg-inline"> Generar PDF</span></button>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>
                                        Estudiantes
                                    </th>
                                    {elementos.map((elemento, index) => {
                                        // Determina la clase basada en id_tipoelemento
                                        const clase = tipoElementoClase[elemento.id_tipoelemento] || '';

                                        return (
                                            <th
                                                key={index}
                                                className={`${clase}`}  // Aplica la clase condicional
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title={elemento.titulo}
                                                style={{ width: "45px" }}
                                            >
                                                <div className="vertical">
                                                    <a href="#" className="text-decoration-none">
                                                        { elemento.id_tipoelemento ? elemento.tipoelemento.alias : '' }
                                                    </a>
                                                </div>
                                            </th>
                                        );
                                    })}
                                    <th style={{ width: "45px" }} className="vertical formativo"><div className="vertical">Formativos</div></th>
                                    <th style={{ width: "45px" }} className="vertical formativo"><div className="vertical">70%</div></th>
                                    <th style={{ width: "45px" }} className="vertical proyecto"><div className="vertical">Sumativa</div></th>
                                    <th style={{ width: "45px" }} className="vertical proyecto"><div className="vertical">30%</div></th>
                                    <th style={{ width: "45px" }} className="vertical promedio"><div className="vertical">Promedio</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {estudiantes.map((estudiante, index) => {
                                    // Filtra las notas del estudiante
                                    const notasEstudiante = notas.filter(nota =>
                                        nota.id_estudiante === estudiante.id
                                    );
                                    
                                    // Calcula la suma acumulada de las calificaciones
                                    const sumaElementos = notasEstudiante.reduce((acumulador, nota) => {
                                        // Asegúrate de convertir la calificación a número
                                        const calificacion = parseFloat(nota.calificacion) || 0;
                                        return acumulador + calificacion;
                                    }, 0);

                                    // Calcula el promedio
                                    const promedio = totalElementos > 0 ? sumaElementos / totalElementos : 0;

                                    // Calcula la calificación de evaluación si existe
                                    const calificacionEvaluacion = evaluacion ? 
                                        notaevaluaciones.find(notaeval =>
                                            notaeval.id_estudiante === estudiante.id &&
                                            notaeval.id_elemento === evaluacion.id
                                        )?.calificacion || 0
                                        : 0;
                                    const total = (promedio * 0.7) + (calificacionEvaluacion * 0.3);
                                    //linea 147, 432
                                    //notaeval.id_evaluacion === evaluacion.id

                                    return (
                                        <tr key={index}>
                                            <td>{estudiante.estudiante.nombres}</td>
                                            {elementos.map((elemento, elemIndex) => {
                                                // Busca el registro de nota que coincida con el estudiante.id y elemento.id
                                                const nota = notas.find(nota =>
                                                    nota.id_estudiante === estudiante.id &&
                                                    nota.id_elemento === elemento.id
                                                );
                                                // Renderiza el valor de la nota o un mensaje por defecto si no se encuentra
                                                return (
                                                    <td style={{ width: "45px" }} key={elemIndex}>
                                                        {nota ? parseFloat(nota.calificacion).toFixed(2) || parseFloat(0).toFixed(2) : parseFloat(0).toFixed(2)}
                                                    </td>
                                                );
                                            })}
                                            {/* <td>{sumaElementos.toFixed(2)}</td> */}
                                            <td style={{ width: "45px" }}>{parseFloat(promedio).toFixed(2)}</td> {/* Muestra el promedio con dos decimales */}
                                            <td style={{ width: "45px" }}>{parseFloat(promedio * 0.7).toFixed(2)}</td>
                                            <td style={{ width: "45px" }}>{parseFloat(calificacionEvaluacion).toFixed(2)}</td>
                                            <td style={{ width: "45px" }}>{parseFloat(calificacionEvaluacion * 0.3).toFixed(2)}</td>
                                            <td
                                                style={{ width: "45px" }}
                                                className={parseFloat(total) < 7 ? 'text-danger' : ''}
                                            >
                                                {parseFloat(total).toFixed(2)}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Subciclo;
