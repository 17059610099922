// components/NotificationDropdown.js
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const NotificationDropdown = ({ apiUrl, accessToken }) => {
    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [unreadCount, setUnreadCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const dropdownRef = useRef(null);
    const dropdownToggleRef = useRef(null);

    // Fetch notifications when the dropdown opens
    useEffect(() => {
        const handleDropdownShow = () => {
            fetchNotifications(1); // Fetch notifications for the first page
        };

        const dropdownElement = dropdownToggleRef.current;
        if (dropdownElement) {
            dropdownElement.addEventListener('show.bs.dropdown', handleDropdownShow);
            return () => {
                dropdownElement.removeEventListener('show.bs.dropdown', handleDropdownShow);
            };
        }
    }, [apiUrl, accessToken]);

    // Fetch notifications when page changes
    useEffect(() => {
        fetchNotifications(currentPage);
    }, [currentPage]);

    // Handle scroll event for infinite scrolling
    useEffect(() => {
        const dropdownElement = dropdownRef.current;
        if (dropdownElement) {
            const handleScroll = () => {
                if (dropdownElement.scrollTop + dropdownElement.clientHeight >= dropdownElement.scrollHeight - 10) {
                    if (currentPage < totalPages && !loading) {
                        setCurrentPage((prevPage) => prevPage + 1);
                    }
                }
            };

            dropdownElement.addEventListener('scroll', handleScroll);
            return () => {
                dropdownElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [loading, currentPage, totalPages]);

    // Fetch notifications from the API
    const fetchNotifications = async (page) => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/notifications?page=${page}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            setNotifications((prevNotifications) => {
                if (page === 1) {
                    return response.data.data;
                } else {
                    return [...prevNotifications, ...response.data.data];
                }
            });
            setTotalPages(response.data.last_page);
            setUnreadCount(response.data.unread_count);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        } finally {
            setLoading(false);
        }
    };

    const getIconClass = (tipo) => {
        switch (tipo) {
            case 'tarea':
                return "fas fa-clipboard-list";
            case 'citatorio':
                return "mdi mdi-alert";
            case 'calificacion':
                return "fas fa-clipboard";
            default:
                return "fas fa-exclamation-circle";
        }
    };

    return (
        <li className="nav-item dropdown">
            <Link 
                to="#" 
                className="nav-link count-indicator" 
                id="countDropdown" 
                data-bs-toggle="dropdown" 
                aria-expanded="false" 
                data-bs-auto-close="outside"
                ref={dropdownToggleRef}
            >
                <i className="fas fa-bell"></i>
                <span className="count">{unreadCount}</span>
            </Link>
            <div
                className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list pb-0 dropdown-menu-clickable-inside"
                style={{ maxHeight: '600px', overflowY: 'auto' }}
                ref={dropdownRef}
            >
                <Link to="#" className="dropdown-item py-3">
                    <p className="mb-0 font-weight-medium float-left">{unreadCount} Notificaciones Sin Leer</p>
                    <span className="badge badge-pill badge-primary float-right">Ver Todas</span>
                </Link>
                <div className="dropdown-divider"></div>
                <div>
                    {notifications.map((notification) => (
                        <div
                            key={notification.id}
                            id={`notification_${notification.id}`}
                            className={`dropdown-item d-flex justify-content-between preview-item ${notification.read_at ? 'visto1' : ''}`}
                        >
                            <Link
                                to={notification.data.url ? `${notification.data.url}` : "#"}
                                className="d-flex justify-content-between preview-item text-decoration-none"
                            >
                                <div className="preview-thumbnail">
                                    <i className={`${getIconClass(notification.data.tipo)} m-auto text-${notification.data.prioridad === 1 ? 'danger' : 'primary'}`}></i>
                                </div>
                                <div className="preview-item-content">
                                    <h6 className={`preview-subject fw-normal mb-1 text-dark`}>{notification.data.titulo}</h6>
                                    <p className={`fw-light small-text mb-0 text-${notification.data.prioridad === 1 ? 'danger' : 'primary'}`}>{notification.created_at}</p>
                                </div>
                            </Link>
                            <div className="align-self-center">
                                <Link
                                    to="#"
                                    className="read-notification"
                                    data-notification-id={notification.id}
                                    data-read={notification.read_at ? 'true' : 'false'}
                                >
                                    <i
                                        id={`notification_icon_${notification.id}`}
                                        className={`fas ${notification.read_at ? 'fa-low-vision' : 'fa-eye'} text-${notification.data.prioridad === 1 ? 'danger' : 'primary'}`}
                                    ></i>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
                {loading && <div className="loading">Cargando más notificaciones...</div>}
            </div>
        </li>
    );
};

export default NotificationDropdown;
