import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { useNavigate, useParams , Link } from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';
import Cuotas from './Cuotas';  // Importa el nuevo componente
import moment from 'moment';
import 'moment/locale/es'; 

const Create = () => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [periodo, setPeriodo] = useState('');
    const [meses, setMeses] = useState([]);
    const [parciales, setParciales] = useState([]);
    const [selectedParcial, setSelectedParcial] = useState('');
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const {periodoId} = useParams();

    const getPeriodo = async () => {
        try {
            const response = await axios.get(`${apiUrl}/periodos/${periodoId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            const { periodo, parciales } = response.data;
            setPeriodo(periodo);
            setMeses(response.data.meses);
            setParciales(parciales);
            setSelectedParcial(periodo.parcial_actual || '');
            setLoading(false);
        } catch (error) {
            console.error('Error al obtener el periodo:', error.response ? error.response.data : error.message);
        }
    };

    useEffect( () =>{
        getPeriodo();
    }, [] );

    const handleParcialChange = async (e) => {
        const parcialId = e.target.value;
        setSelectedParcial(parcialId);
        console.log("test")
        try {
            await axios.patch(`${apiUrl}/periodos/${periodoId}/updateParcial`, {
                parcial_actual: parcialId,
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
        } catch (error) {
            console.error('Error al actualizar el parcial:', error.response ? error.response.data : error.message);
            
            setErrors(error.response.data.errors);
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">{periodo.descripcion}</h2>
                </div>
                <div>
                    <Link to='/periodos' className='btn btn-sm btn-warning'>Volver</Link>
                </div>
            </div>
            
            <div className="col-lg-8 offset-lg-2 col-md-12 offset-md-0 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <div className='mb-2'>
                            <label htmlFor="parcialSelect">Parcial Actual:</label>
                            <select
                                id="parcialSelect"
                                className="form-control"
                                value={selectedParcial}
                                onChange={handleParcialChange}
                                style={{
                                    color: '#000',  // Color del texto
                                    backgroundColor: '#fff',  // Color de fondo
                                    borderColor: '#ced4da',  // Color del borde
                                }}
                            >
                                <option value="" disabled>Seleccione un parcial</option>
                                {parciales.map((parcial) => (
                                    <option key={parcial.id} value={parcial.id}>
                                        {parcial.descripcion} - {parcial.ciclo.descripcion}
                                    </option>
                                ))}
                            </select>
                            {errors.parcial_actual && (
                                <div className="text-danger mt-2">
                                    {errors.parcial_actual}
                                </div>
                            )}
                        </div>
                        <Cuotas apiUrl={apiUrl} accessToken={accessToken} periodoId={periodoId} meses={meses} getPeriodo={getPeriodo} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;
