import axios from 'axios';
import React, { useState } from 'react';
import imgbanner from '../../assets/img/edusnap/logo_hotizontal.png';
import {Link} from 'react-router-dom';
import { obtenerCliente } from '../../App';

const BASE_URL = process.env.REACT_APP_BASE_URL;
//const Acceder = ({ subdominio }) => { /* exportar variable de subdominio del appjs */
const Acceder = () => {
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const [successTenants, setSuccessTenants] = useState([]);

    const subdominio = obtenerCliente();
    if (subdominio !== 'admin') {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        window.location.href = `http://admin.${baseUrl}`;
    }

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${apiUrl}/Clientes`;
    console.log(endpoint);

    const store = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(endpoint, { username });
            
            console.log('Respuesta del servidor:', response.data); // Mostrar respuesta en consola

            if (response.data.status === 'success') {
                if (response.data.tenants && response.data.tenants.length > 0) {
                    setSuccessTenants(response.data.tenants);
                }
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            setError('El usuario no pertenece a una institución.');
        }
    };

    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-center auth px-0">
                    <div className="row w-100 mx-0">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                <div className="brand-logo">
                                    <img src={imgbanner} alt="edusnap" style={{ width: '15rem' }} />
                                </div>
                                <h4>¡Hola! empecemos</h4>
                                <h6 className="fw-light">Ingresa tu núnmero de cedula para continuar.</h6>

                                {error && <div className="alert alert-danger mt-3">{error}</div>}

                                {successTenants.length > 0 && (
                                    <div className="alert alert-success" role="alert">
                                        <h4 className="alert-heading">Instituciones Disponibles:</h4>
                                        <ul>
                                            {successTenants.map((tenant, index) => (
                                                <li key={index}>
                                                    {/* 
                                                    <Link to={`http://${tenant.id}.${BASE_URL}Login`} rel="noopener noreferrer">
                                                        {tenant.nombre}
                                                    </Link>
                                                    */}
                                                    <Link to={`https://${tenant.id}.client.edusnapec.com/Login`} rel="noopener noreferrer">
                                                        {tenant.nombre}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                                <form className="pt-3" onSubmit={store} method="POST">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="username"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            className="form-control form-control-lg"
                                            id="exampleInputEmail1"
                                            placeholder="Identificación"
                                            autoFocus
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <input
                                            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                            type="submit"
                                            value="Acceder"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Acceder;
