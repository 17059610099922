import $ from 'jquery';

$(document).ready(function() {
  var body = $('body');
  var contentWrapper = $('.content-wrapper');
  var scroller = $('.container-scroller');
  var footer = $('.footer');
  var sidebar = $('.sidebar');
  var subnivel = $('.subnivel');

  sidebar.on('show.bs.collapse', '.collapse', function() {
    sidebar.find('.collapse.show').collapse('hide');
  });
  
  subnivel.on('show.bs.collapse', '.collapse', function() {
    subnivel.find('.collapse2.collapse.show').collapse('hide');
  });

  //checkbox and radios
  $(".form-check label,.form-radio label").append('<i class="input-helper"></i>');

  //Horizontal menu in mobile
  $('[data-toggle="horizontal-menu-toggle"]').on("click", function() {
    $(".horizontal-menu .bottom-navbar").toggleClass("header-toggled");
  });
  // Horizontal menu navigation in mobile menu on click
  var navItemClicked = $('.horizontal-menu .page-navigation >.nav-item');
  navItemClicked.on("click", function(event) {
    if(window.matchMedia('(max-width: 991px)').matches) {
      if(!($(this).hasClass('show-submenu'))) {
        navItemClicked.removeClass('show-submenu');
      }
      $(this).toggleClass('show-submenu');
    }        
  })
    
  if ($( ".navbar" ).hasClass( "fixed-top" )) {
    document.querySelector('.page-body-wrapper').classList.remove('pt-0');
    document.querySelector('.navbar').classList.remove('pt-5');
  }
});




$(window).scroll(function() {
  if(window.matchMedia('(min-width: 992px)').matches) {
    var header = $('.horizontal-menu');
    if ($(window).scrollTop() >= 70) {
      $(header).addClass('fixed-on-scroll');
    } else {
      $(header).removeClass('fixed-on-scroll');
    }
  }
});