import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'; // Asegúrate de que el path sea correcto
import { useApiContext } from '../layout/Template';
import FileViewer from '../widget/FileViewer';
import QuillEditor  from '../widget/QuillEditor';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'bootstrap-fileinput/css/fileinput.min.css';
import 'bootstrap-fileinput/js/fileinput.min.js';
import 'bootstrap-fileinput/themes/fa5/theme.js';
import 'bootstrap-fileinput/js/locales/es';

const Entrega = ({ examenId, onEntregaSuccess }) => {
    const navigate = useNavigate(); // Hook para navegar
    const { apiUrl, accessToken } = useApiContext();
    const inputFileRef = useRef(null);
    const editorRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [entregaEdit, setEntregaEdit] = useState({})
    const [showFileViewer, setShowFileViewer] = useState(false);
    const [fileName, setFileName] = useState('');
    const [filePath, setFilePath] = useState('');

    useEffect(() => {
        const fetchEntrega = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(`${apiUrl}/examen/entrega/get/${examenId}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json'
                    }
                });

                const data = response.data;
                setEntregaEdit(data.entrega || {});

                
                if (data.entrega) {
                    if (editorRef.current) {
                        editorRef.current.root.innerHTML = data.entrega.descripcion || '';
                    }
                }
                if (data.entrega && data.entrega.archivo_firmado_url && data.entrega.archivo !== '') {
                    // Si el archivo existe y tiene una URL, actualiza los estados para mostrar FileViewer
                    setShowFileViewer(true);
                    setFileName(data.entrega.archivo);
                    setFilePath(data.entrega.archivo_firmado_url);
                } else {
                    // Si no hay archivo, no se debe mostrar FileViewer
                    setShowFileViewer(false);
                }
            } catch (error) {
                setError("Error al obtener la entrega");
                console.error("Error al obtener la entrega:", error.response?.data || error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchEntrega();
    }, [examenId, apiUrl, accessToken]);
    
    $(document).ready(function () {
        if (inputFileRef.current) {
            $(inputFileRef.current).fileinput({
                maxFileSize: 10240,
                maxFilesNum: 1,
                language: 'es',
                previewFileType: 'any',
                theme: 'fa5',
                showUpload: false,
                dropZoneEnabled: true,
                inputGroupClass: 'input-group-sm',
                allowedFileExtensions: ['jpg', 'jpeg', 'png', 'pdf'],
                showPreview: true,
                fileActionSettings: {
                    showUpload: false,
                    showZoom: false  // Aquí se ocultan los botones de zoom
                }
            });

            // Manejar el evento de modal oculto después de la vista de zoom
            $(inputFileRef.current).on('filezoomhidden', function(event, params) {
                console.log('File zoom hidden', params.sourceEvent, params.previewId, params.modal);
                // Aquí puedes realizar cualquier acción adicional después de que se oculte el modal de zoom
            });
        }
    });

    // Función para capturar el contenido del editor
    const handleEntregaSubmit = async (e) => {
        window.showLoaderPopup();
        e.preventDefault();
    
        const descripcion = editorRef.current.root.innerHTML;
        const file = inputFileRef.current?.files[0];

        // Muestra el contenido del editor y el archivo seleccionado en la consola
        console.log('Contenido del editor:', descripcion);
        console.log('Archivo:', file);

        // Verifica si hay un archivo seleccionado o si la ruta del archivo existe
        if (!file && !filePath) {
            window.showErrorPopup('Debe seleccionar un archivo.');
            return;
        }

        const formData = new FormData();
        formData.append('descripcion', descripcion);
        formData.append('id_examen', examenId);

        // Solo agrega el archivo si no hay una ruta de archivo
        if (!filePath && file) {
            formData.append('file', file);
        }

        // Log de FormData para depuración
        for (const [key, value] of formData.entries()) {
            console.log(`${key}:`, value);
        }

        try {
            const response = await axios.post(`${apiUrl}/examen/entrega/store`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data', // Asegúrate de que este encabezado esté presente
                },
            });
            // Llama a la función pasada para actualizar el componente padre
            onEntregaSuccess();
            navigate(`/Materias/Contenido/Examen/${examenId}`);
            window.hideLoaderPopup();
            window.showSuccessPopup("Entrega Realizada.");
        } catch (error) {
            console.error('Error al guardar la tarea:', error.response ? error.response.data : error.message);
        }
    };

    return (
        <div className="card">
            <div className="card-body container">
                <form encType="multipart/form-data" onSubmit={handleEntregaSubmit}>
                    <div className="text-center">
                        <QuillEditor ref={editorRef} />
                        <div>
                            {showFileViewer && (
                                <FileViewer
                                    fileName={fileName}
                                    filePath={filePath}
                                />
                            )}
                        </div>
                        <input 
                            ref={inputFileRef}
                            type="file"
                            name="file"
                            accept="image/*, application/pdf"
                            className="mt-2 imagen"
                        />
                        <button type="submit" className="btn btn-primary mt-3">Enviar</button>
                    </div>
                </form>
            </div>
            <style>
                {`
                    .entrega-card img {
                        border-radius: 0 !important;
                        width: 1000px;
                        height: 1000px;
                    }
                    .table td img {
                        border-radius: 0 !important;
                        width: 350px;
                        height: 350px;
                    }
                `}
            </style>
        </div>
    );
};

export default Entrega;