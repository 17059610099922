import React from 'react';

/**
 * CheckboxInput - Un componente para renderizar un checkbox.
 *
 * @param {string} id - El ID del checkbox.
 * @param {string} name - El nombre del checkbox.
 * @param {boolean} checked - Si el checkbox está marcado o no.
 * @param {function} onChange - Función a llamar cuando cambia el estado del checkbox.
 * @param {string} label - El texto que se muestra junto al checkbox.
 * @param {boolean} [disabled=false] - Si el checkbox debe estar deshabilitado.
 *
 * @returns {JSX.Element} - El componente de checkbox.
 */
const CheckboxInput = ({ id, name, checked, onChange, label, disabled = false }) => (
    <div className="form-check">
        <label className="form-check-label" htmlFor={id}>
            <input 
                type="checkbox" 
                className="form-check-input" 
                id={id} 
                name={name}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
            <i className="input-helper"></i>
            {label}
        </label>
    </div>
);

export default CheckboxInput;