import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useApiContext } from '../layout/Template';
import { useNavigate } from 'react-router-dom';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import logoRect from '../../../assets/img/edusnap/logo_hotizontal.png'; 
import logo_mineud from '../../../assets/img/min_edu2024.png'; 

const Subciclo = () => {
    const { matriculaId, subcicloId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user } = useApiContext();
    
    const [estudiante, setEstudiante] = useState(null);
    const [escuela, setEscuela] = useState(null);
    const [ocultarBoletin, setOcultarBoletin] = useState(null);
    const [curso, setCurso] = useState(null);
    const [ciclos, setCiclos] = useState([]);
    const [materias, setMaterias] = useState([]);
    const [elementos, setElementos] = useState([]);
    const [notas, setNotas] = useState([]);
    const [tests, setTests] = useState([]);
    const [notatests, setNotatests] = useState([]);
    const [conducta, setConducta] = useState([]);
    const [subcicloActual, setSubcicloActual] = useState(null);
    const [bitacoras, setBitacoras] = useState([]);
    const [asistencias, setAsistencias] = useState([]);

    const logoUrl = escuela?.logo_firmado
        ? `${escuela.logo_firmado}` // Ajusta la URL según tu configuración
        : logoRect;
    
    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/boletin/parcial/${matriculaId}/${subcicloId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            const data = response.data;

            setEstudiante(data.estudiante);
            setEscuela(data.escuela);
            setOcultarBoletin(data.ocultarBoletin);
            setCurso(data.curso);
            setCiclos(data.ciclos);
            setMaterias(data.materias);
            setElementos(data.elementos);
            setNotas(data.notas);
            setTests(data.tests);
            setNotatests(data.notatests);
            setConducta(data.conducta);
            setSubcicloActual(data.subcicloActual);
            setBitacoras(data.bitacoras);
            setAsistencias(data.asistencias);

            console.log(data)

        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (user && user.estudiante === 1 && ocultarBoletin === 1) {
        window.showErrorPopup("Boletines Inhabilitados");
        return;
    }

    //Logica boletin
    let sumaGeneral = 0;
    const totalMaterias = materias.length;
    const diasLaborados = bitacoras.length;
    let sumaProcesoAcumulado = 0;
    let sumaAportesAcumulado = 0;
    let injustificadas = 0;
    let justificadas = 0;
    let atrasos = 0;
    asistencias.forEach(asistencia => {
        switch (asistencia.asistencia) {
            case 3:
                injustificadas++;
            break;
            case 2:
                atrasos++;
            break;
            case 4:
                justificadas++;
            break;
            default:
            break;
        }
    });

    // Función para validar y obtener un número
    const toNumber = (value) => {
        return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    };

    // Función para calcular la escala cuantitativa
    const getCuantitativa = (promFinal) => {
        if(curso.inicial === 1){
            if (promFinal < 4) return "N.E.";
            if (promFinal >= 4.01 && promFinal <= 6.99) return "I";
            if (promFinal >= 7 && promFinal < 8.99) return "EP";
            if (promFinal >= 9 && promFinal <= 10) return "A";
            return "N.E.";
        }else{
            if (promFinal >= 1 && promFinal < 2) return "E-";
            if (promFinal >= 2 && promFinal < 3) return "E+";
            if (promFinal >= 3 && promFinal < 4) return "D-";
            if (promFinal >= 4 && promFinal < 5) return "D+";
            if (promFinal >= 5 && promFinal < 6) return "C-";
            if (promFinal >= 6 && promFinal < 7) return "C+";
            if (promFinal >= 7 && promFinal < 8) return "B-";
            if (promFinal >= 8 && promFinal < 9) return "B+";
            if (promFinal >= 9 && promFinal < 10) return "A-";
            if (promFinal === 10) return "A+";
            return "E-";
        }
    };

    const generarPDF = async () => {
        console.log("procesando");
        try {
            const response = await axios.get(`${apiUrl}/boletin/parcial/pdf/${matriculaId}/${subcicloId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/pdf', // Asegúrate de que la respuesta sea un PDF
                },
                responseType: 'blob' // Importante para manejar la respuesta como archivo binario
            });
    
            // Crear un enlace de descarga
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
    
            // Forma el nombre del archivo con un formato seguro para nombres de archivo
            const nombreArchivo = `Boletin ${estudiante.estudiante.nombres}.pdf`;
            link.setAttribute('download', nombreArchivo);
            document.body.appendChild(link);
            link.click();
    
            // Liberar el objeto URL y eliminar el enlace del DOM
            window.URL.revokeObjectURL(url);
            link.remove();
        } catch (err) {
            console.error('Error al descargar el PDF:', err);
            window.showErrorPopup('Error al descargar el archivo PDF.');
        }
        console.log("fin");
    };

    if (loading) {
        return <Spinner />;
    }
    
    return (
        <div className="py-3 px-3 content-wrapper">
            <style>
                {`
                    .boletin {
                        padding: 6px;
                        border: 1px solid black;
                        font-size: 13px;
                    }
                    
                    .bold {
                        font-weight: bold;
                    }
                    .mayusculas{
                        text-transform: uppercase;
                    }
                    .firma-container {
                        display: flex;
                        justify-content: space-between;
                        margin: 20px;
                    }
            
                    .firma {
                        width: 200px;
                        border-top: 1px solid #000;
                        margin-top: 10px;
                        padding-top: 10px;
                        text-align: center;
                    }
            
                    .firma-name {
                        font-weight: bold;
                    }
            
                    .firma-title {
                        margin-top: 5px;
                    }
                `}
            </style>
            <button className="btn btn-primary" onClick={generarPDF}>
                <i className="fas fa-file-pdf"></i> <span className="d-none d-lg-inline"> Generar PDF</span>
            </button>
            <div className='m-3' style={{ padding: "16px 16px 2px 16px", border: "2px solid", display: "flow-root", backgroundColor: "white" }}>
                <div>
                    <table cellPadding="0" cellSpacing="0" width="100%">
                        <tbody>
                            <tr>
                                <td style={{ width: '25%', textAlign: 'center' }}>
                                    <img src={logoUrl} alt="Logo" width="100" />
                                </td>
                                <td style={{ textAlign: 'center' }}> {/* Cambiado a un objeto */}
                                    <div style={{ fontSize: '17px' }}><strong>{escuela.nombre}</strong></div>
                                    <div style={{ fontSize: '19px' }} className="mayusculas">
                                        <strong> INFORME DE RENDIMIENTO ACADÉMICO</strong>
                                    </div>
                                    <div style={{ fontSize: '14px' }}>
                                        Correo: {escuela.correo}
                                    </div>
                                    <div className="d-sm-flex justify-content-between align-items-start px-5" style={{ fontSize: '14px' }}>
                                        <div>Cantón: {escuela.canton.nombre}</div>
                                        <div>Parroquia: {escuela.parroquia}</div>
                                    </div>
                                </td>
                                <td style={{ width: '25%', textAlign: 'center' }}>
                                    <img src={logo_mineud} alt="" width="120"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <table
                        style={{
                            fontSize: '14px',
                            textAlign: 'center',
                            borderCollapse: 'collapse',
                            width: '100%',
                        }}
                        border="1"
                    >
                        <tbody className="mayusculas">
                            <tr>
                                <th className="boletin">NOMBRES:</th>
                                <td className="boletin">{estudiante.estudiante.nombres}</td>
                                <th className="boletin">AÑO LECTIVO:</th>
                                <td className="boletin">{curso.periodo.descripcion}</td>
                            </tr>
                            <tr>
                                <th className="boletin">CURSO:</th>
                                <td className="boletin" style={{ fontSize: '13px', fontWeight: 'bold' }}>
                                    {curso.nivel.nombre} {curso.tipo.alias} {curso.seccion.descripcion}
                                </td>
                                <th className="boletin" colSpan="2">
                                    {subcicloActual.descripcion} - {subcicloActual.ciclo.descripcion}
                                </th>
                            </tr>
                            <tr>
                                <th className="boletin" colSpan="2">Docente:</th>
                                <td className="boletin" colSpan="2">
                                    {curso.tutor.nombres}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <div
                        style={{
                            overflowX: 'scroll',
                            overflowX: 'visible',
                            paddingBottom: '5px',
                            width: 'auto'
                        }}
                    >
                        <table cellpadding="0" cellspacing="0" width="100%" border="1">
                            <tbody>
                                <tr>
                                    <td colspan="1" rowspan="2" className="boletin text-center bold"> Materias/Asignaturas </td>
                                    <td colspan="6" className="boletin text-center bold"> {subcicloActual.descripcion} - {subcicloActual.ciclo.descripcion} </td>
                                </tr>
                                <tr className="mayusculas">
                                    <th className="boletin text-center">Proceso</th>
                                    <th className="boletin text-center">70%</th>
                                    <th className="boletin text-center">Evaluacion</th>
                                    <th className="boletin text-center">30%</th>
                                    <th className="boletin text-center">PROM. FINAL</th>
                                    <th className="boletin text-center">Cualitativa</th>
                                </tr>
                                {materias.map((materia) => {
                                let proceso = 0;
                                let procesoPorcentaje = 0;
                                let testPorcentaje = 0;
                                let notasProceso = 0;
                                let notaAporte = 0;
                                const elementosMateria = elementos.filter(e => e.id_materiacurso === materia.id);
                                const totalElementos = elementosMateria.length;

                                // Calcular notas para elementos parciales
                                elementosMateria.forEach(elemento => {
                                    const nota = notas.find(n => n.id_elemento === elemento.id);
                                    notasProceso += toNumber(nota ? nota.calificacion : 0);
                                });
                                proceso = (totalElementos > 0) ? (notasProceso / totalElementos) : 0;
                                procesoPorcentaje = proceso * 0.7;

                                // Calcular notas para evaluaciones
                                const testsMateria = tests.filter(e => e.id_materiacurso === materia.id);
                                testsMateria.forEach(evaluacion => {
                                    const notatest = notatests.find(n => n.id_elemento === evaluacion.id);
                                    notaAporte = toNumber(notatest ? notatest.calificacion : 0);
                                    testPorcentaje = notaAporte * 0.3;
                                });
                                //linea 326
                                //const notatest = notatests.find(n => n.id_evaluacion === evaluacion.id);

                                const promFinal = procesoPorcentaje + testPorcentaje;
                                sumaGeneral += promFinal;
                                sumaProcesoAcumulado += proceso;
                                sumaAportesAcumulado += notaAporte;

                                return (
                                    <tr key={materia.id}>
                                        <td className='boletin'>{materia.materia.nombre}</td>
                                        <td className="boletin text-center">{proceso.toFixed(2)}</td>
                                        <td className="boletin text-center">{procesoPorcentaje.toFixed(2)}</td>
                                        <td className="boletin text-center">{notaAporte.toFixed(2)}</td>
                                        <td className="boletin text-center">{testPorcentaje.toFixed(2)}</td>
                                        <td className="boletin text-center">{promFinal.toFixed(2)}</td>
                                        <td className="boletin text-center">{getCuantitativa(promFinal)}</td>
                                    </tr>
                                );
                                })}

                                {totalMaterias > 0 && (
                                <tr>
                                    <td className="boletin bold">PROMEDIO GENERAL</td>
                                    <td className="boletin text-center">
                                    {((sumaProcesoAcumulado / totalMaterias) || 0).toFixed(2)}
                                    </td>
                                    <td className="boletin text-center">
                                    {(((sumaProcesoAcumulado / totalMaterias) || 0) * 0.8).toFixed(2)}
                                    </td>
                                    <td className="boletin text-center">
                                    {((sumaAportesAcumulado / totalMaterias) || 0).toFixed(2)}
                                    </td>
                                    <td className="boletin text-center">
                                    {((((sumaAportesAcumulado / totalMaterias) || 0) * 0.2)).toFixed(2)}
                                    </td>
                                    <td className="boletin text-center">
                                    {((sumaGeneral / totalMaterias) || 0).toFixed(2)}
                                    </td>
                                    <td className="boletin text-center">
                                    {getCuantitativa((sumaGeneral / totalMaterias) || 0)}
                                    </td>
                                </tr>
                                )}
                                <tr>
                                    <td className="boletin contenidobody bold">
                                            COMPORTAMIENTO
                                    </td>
                                    <td colspan="6" className="boletin contenidobody text-center bold">
                                        { conducta ? conducta.conducta : '-' }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="boletin contenidobody">
                                    Días Laborados
                                    </td>
                                    <td colspan="6" className="boletin contenidobody text-center">
                                        {diasLaborados}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="boletin contenidobody">
                                    Faltas Injustificadas
                                    </td>
                                    <td colspan="6" className="boletin contenidobody text-center">
                                        {injustificadas}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="boletin contenidobody">
                                        Atrasos
                                    </td>
                                    <td colspan="6" className="boletin contenidobody text-center">
                                        {atrasos}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="boletin contenidobody">
                                        Faltas Justificadas
                                    </td>
                                    <td colspan="6" className="boletin contenidobody text-center">
                                        {justificadas}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br/>
                    <table cellpadding="0" cellspacing="0" border="1" width="100%">       
                        <tbody>
                            <tr>
                                <td className="boletin" valign="top">
                                    <center><strong>ESCALA DE APROVECHAMIENTO</strong></center> 
                                    <div>
                                        <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                            <tbody>
                                                {curso.inicial === 1 ? (
                                                    <>
                                                    <tr>
                                                        <td style={{width: "80%;"}}><div className="escalas">A - Destrezas o Aprendizajes Alcanzados.</div></td>
                                                        <td><div className="escalas">(9.00 a 10.00)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}><div className="escalas">EP - Destrezas o aprendizajes en Proceso de Desarrollo.</div></td>
                                                        <td><div className="escalas">(7.00 a 8.99)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}> <div className="escalas"> I - Destrezas o Aprendizaje Iniciado. </div></td>
                                                        <td><div className="escalas">(4.01 a 6.99)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}> <div className="escalas"> N.E. - Destrezas o Aprendizaje no Alcanzados. </div></td>
                                                        <td><div className="escalas">(0.00 a 4.00)</div></td>
                                                    </tr>
                                                    </>
                                                ) : (
                                                    <>
                                                    <tr>
                                                        <td style={{width: "80%;"}}><div className="escalas">DAR - Domina los aprendizajes requeridos.</div></td>
                                                        <td><div className="escalas">(9.00 a 10.00)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}><div className="escalas">AAR - Alcanza los aprendizajes requeridos.</div></td>
                                                        <td><div className="escalas">(7.00 a 8.99)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}> <div className="escalas"> PAAR - Está próximo a alcanzar los aprendizajes requeridos. </div></td>
                                                        <td><div className="escalas">(4.01 a 6.99)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}> <div className="escalas"> NAAR - No alcanza los aprendizajes requeridos. </div></td>
                                                        <td><div className="escalas">(0.00 a 4.00)</div></td>
                                                    </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="boletin">
                                    <center><strong>ESCALA COMPORTAMIENTO</strong></center>
                                    <div>
                                        S - Siempre idera el cumplimiento de los compromisos establecidos para la sana convivencia social. <br/>
                                        F - Frecuentemente cumple con los compromisos establecidos para la sana convivencia social. <br/>
                                        O - Ocasionalmente falla en el cumplimiento de los compromisos establecidos para la sana convivencia social. <br/>
                                        N - No cumple con los compromisos establecidos para la sana convivencia social. <br/>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <div className="firma-container">
                        <div className="firma">
                            <div className="firma-line"></div>
                            <div className="firma-name">{escuela.rector}</div>
                            <div className="firma-title">Director/a</div>
                        </div>
                        <div className="firma">
                            <div className="firma-line"></div>
                            <div className="firma-name">{curso.tutor.nombres}</div>
                            <div className="firma-title">Tutor/a</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Subciclo;
