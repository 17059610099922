import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import { useApiContext } from '../layout/Template';
import QuillEditor  from '../widget/QuillEditor';
import $ from 'jquery';
import 'bootstrap-fileinput/css/fileinput.min.css';
import 'bootstrap-fileinput/js/fileinput.min.js';
import 'bootstrap-fileinput/themes/fa5/theme.js';
import 'bootstrap-fileinput/js/locales/es';

const Create = () => {
    const { materiaId, parcialId } = useParams();
    const { apiUrl, accessToken } = useApiContext(); // Ajusta según tu contexto API
    const [errors, setErrors] = useState({});
    const editorRef = useRef(null);
    const inputFileRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [tipoElementos, setTipoElementos] = useState([]); 
    const navigate = useNavigate();
    //variables
    const [titulo, setTitulo] = useState('');
    const [resumen, setResumen] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [url, setUrl] = useState('');
    const [tipoActividad, setTipoActividad] = useState(0);
    const [fecha, setFecha] = useState('');
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [archivo, setArchivo] = useState(null);
    const [entregas, setEntregas] = useState(false);
    const [foro, setForo] = useState(false);
    const [tipo, setTipo] = useState("actividad");
    const endpoint = `${apiUrl}/actividad/store/${materiaId}/${parcialId}`;
    const [isSubmitting, setIsSubmitting] = useState(false);

    const fetchTipos = async () => {
        try {
            const response = await axios.get(`${apiUrl}/tipoelementos`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}` // Agrega el token de acceso si es necesario
                }
            });
            setTipoElementos(response.data); // Asigna los datos a tipoElementos
            console.log(response.data)
        } catch (error) {
            setErrors(error.response?.data || { message: "Error fetching data" }); // Manejo de errores
        } finally {
            setLoading(false); // Finaliza el estado de carga
        }
    };

    useEffect(() => {
        fetchTipos(); // Llama a fetchTipos cuando el componente se monta
    }, [apiUrl, accessToken]);

    const store = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); 
        
        // Obtén el contenido del editor desde el ref
        const editorContent = editorRef.current?.root.innerHTML || '';
        const file = inputFileRef.current?.files[0];
        const newErrors = {};

        // Validaciones de texto
        if (!titulo.trim() || titulo.length < 3 || titulo.length > 250)
            newErrors.titulo = 'El título es obligatorio y debe tener entre 3 y 250 caracteres.';
        
        
        if (url.length > 250)
            newErrors.url = 'El título es obligatorio y debe tener entre 3 y 250 caracteres.';

        if (!resumen.trim() || resumen.length < 3 || resumen.length > 100)
            newErrors.resumen = 'El resumen es obligatorio y debe tener entre 3 y 100 caracteres.';

        // Validación de descripción
        const textLength = editorContent.replace(/<[^>]*>/g, '').length; // Elimina etiquetas HTML y mide longitud
        if (textLength > 10000) {
            newErrors.descripcion = 'La descripción es obligatoria y debe tener entre 3 y 10000 caracteres.';
        }
    
        if (tipo === "actividad" && (!tipoActividad || isNaN(tipoActividad))) {
            newErrors.id_tipoelemento = 'El tipo de actividad es obligatorio y debe ser numérico.';
        }
    
        // Validación de fechas
        if (!fecha)
            newErrors.fecha = 'La fecha es obligatoria.';
    
        if (entregas) {
            if (!fechaInicio) 
                newErrors.fechaInicio = 'La fecha de inicio es obligatoria.';
            if (!fechaFin)
                newErrors.fechaFin = 'La fecha de fin es obligatoria.';
            if (fechaInicio && fechaFin && new Date(fechaInicio) > new Date(fechaFin))
                newErrors.fechaFin = 'La fecha de fin debe ser posterior a la fecha de inicio.';
        }
    
        // Validación de archivo
        if (archivo && archivo.size > 10240 * 1024)
            newErrors.archivo = 'El archivo no puede ser mayor a 5MB.';
    
        // Actualiza el estado con los errores encontrados
        setErrors(newErrors);

        // Verifica si hay errores antes de enviar el formulario
        if (Object.keys(newErrors).length === 0) {
            // Aquí podrías enviar los datos del formulario
            console.log({ titulo, resumen, descripcion: editorContent });
        }
    
        const formData = new FormData();
        formData.append('titulo', titulo);
        formData.append('resumen', resumen);
        formData.append('descripcion', editorContent);
        formData.append('url', url);
        formData.append('id_tipoelemento', tipoActividad);
        formData.append('tipo', tipo);
        formData.append('fecha', fecha);
        formData.append('fecha_inicio', fechaInicio);
        formData.append('fecha_fin', fechaFin);


        // Solo agrega el archivo si no hay una ruta de archivo
        if (file) {
            formData.append('archivo', file);
        }

        // Agrega el checkbox al FormData
        const entregasValue = entregas ? 'true' : 'false';
        const foroValue = foro ? 'true' : 'false';
        // Agrega el checkbox al FormData
        formData.append('entregas', entregasValue);
        formData.append('foro', foroValue);
    
        try {
            const response = await axios.post(endpoint, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            });
            navigate(`/Materias/Contenido/Actividades/${response.data.id}`);
            window.showSuccessPopup(response.data.message);
            // Maneja la respuesta de éxito aquí
        } catch (err) {
            if (err.response && err.response.status === 422) {
                setErrors(err.response.data.errors);
            } else if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    navigate('/dashboard');
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setIsSubmitting(false); // Reactiva el botón después de finalizar
        }
    };

    // Maneja el cambio del checkbox
    const handleCheckboxChange = (e) => {
        setEntregas(e.target.checked);
    };

    // Maneja el cambio del checkbox
    const handleCheckboxChangeForo = (e) => {
        setForo(e.target.checked);
    };
    
    $(document).ready(function () {
        if (inputFileRef.current) {
            $(inputFileRef.current).fileinput({
                maxFileSize: 10240,
                maxFilesNum: 1,
                language: 'es',
                previewFileType: 'any',
                theme: 'fa5',
                showUpload: false,
                dropZoneEnabled: true,
                inputGroupClass: 'input-group-sm',
                allowedFileExtensions: ['jpg', 'jpeg', 'png', 'pdf'],
                showPreview: true,
                fileActionSettings: {
                    showUpload: false,
                    showZoom: false  // Aquí se ocultan los botones de zoom
                }
            });

            // Manejar el evento de modal oculto después de la vista de zoom
            $(inputFileRef.current).on('filezoomhidden', function(event, params) {
                console.log('File zoom hidden', params.sourceEvent, params.previewId, params.modal);
                // Aquí puedes realizar cualquier acción adicional después de que se oculte el modal de zoom
            });
        }
    });

    const obtenerSubdominio = () => window.location.hostname.split('.')[0];
    const subdominio = obtenerSubdominio();
    console.log(subdominio); // "escuela1"

    if (loading) {
        return <Spinner />;
    }
    
    return (
        <div className="py-3 px-3 content-wrapper">
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Crear Actividad Curricular</h2>
                </div>
            </div>
            
            <div className="col-md-6 offset-md-3 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={store} encType="multipart/form-data">
                            <div className="mb-3">
                                <label htmlFor="titulo">Título</label>
                                <input 
                                    type="text" 
                                    className={`form-control ${errors.titulo ? 'is-invalid' : ''}`} 
                                    id="titulo" 
                                    name="titulo" 
                                    placeholder="Título" 
                                    maxLength="250"
                                    value={titulo}
                                    onChange={(e) => setTitulo(e.target.value)}
                                />
                                {errors.titulo && <div className="invalid-feedback">{errors.titulo}</div>}
                            </div>
                            <div className="mb-3">
                                <label for="resumen">Resumen</label>
                                <input 
                                    type="text" 
                                    className={`form-control ${errors.resumen ? 'is-invalid' : ''}`} 
                                    id="resumen" 
                                    name="resumen" 
                                    placeholder="Resumen" 
                                    maxLength="100"
                                    value={resumen}
                                    onChange={(e) => setResumen(e.target.value)}
                                />
                                {errors.resumen && <div className="invalid-feedback">{errors.resumen}</div>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="descripcion" className={`form-control ${errors.descripcion ? 'is-invalid' : ''}`}>Descripción</label>
                                <QuillEditor ref={editorRef} />
                                {errors.descripcion && <div className="invalid-feedback">{errors.descripcion}</div>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="titulo">Video Youtube</label>
                                <input 
                                    type="text" 
                                    className={`form-control ${errors.url ? 'is-invalid' : ''}`} 
                                    id="url" 
                                    name="url" 
                                    placeholder="Título" 
                                    maxLength="250"
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                />
                                {errors.url && <div className="invalid-feedback">{errors.url}</div>}
                            </div>
                            <div className="mb-3">
                                <label>Tipo de Insumo</label>
                                <div className='ms-4'>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="tipo"
                                            id="tipoActividad"
                                            value="actividad"
                                            checked={tipo === "actividad"}
                                            onChange={(e) => setTipo(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="tipoActividad">
                                            Actividad
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="tipo"
                                            id="tipoEvaluacion"
                                            value="evaluacion"
                                            checked={tipo === "evaluacion"}
                                            onChange={(e) => setTipo(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="tipoEvaluacion">
                                            Evaluación (Aporte)
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {tipo === "actividad" && (
                                <div className="mb-3">
                                    <label htmlFor="id_tipoelemento">Tipo de Actividad</label>
                                    <select 
                                        className={`form-select ${errors.id_tipoelemento ? 'is-invalid' : ''}`} 
                                        aria-label="Default select example" 
                                        id="id_tipoelemento" 
                                        name="id_tipoelemento"
                                        value={tipoActividad}
                                        onChange={(e) => setTipoActividad(e.target.value)}
                                    >
                                        <option selected>
                                            Seleccionar
                                        </option>
                                        {tipoElementos.map((tipo) => (
                                            <option key={tipo.id} value={tipo.id}>
                                                {tipo.descripcion} - {tipo.alias}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.id_tipoelemento && <div className="invalid-feedback">{errors.id_tipoelemento}</div>}
                                </div>
                            )}
                            <div className="form-check form-check-flat form-check-primary">
                                <label className="form-check-label">
                                    <input
                                        type="checkbox" 
                                        className="form-check-input" 
                                        id="entregasCheckbox"
                                        name="entregas"
                                        checked={entregas} // Usa el estado para controlar el checkbox
                                        onChange={handleCheckboxChange} />
                                    Tarea Virtual
                                    <i className="input-helper"></i>
                                </label>
                            </div>
                            {entregas && (
                                <>
                                <div className="form-check form-check-flat form-check-primary">
                                    <label className="form-check-label">
                                        <input
                                            type="checkbox" 
                                            className="form-check-input" 
                                            name="foro"
                                            checked={foro} 
                                            onChange={handleCheckboxChangeForo} />
                                            Foro
                                        <i className="input-helper"></i>
                                    </label>
                                </div>
                                </>
                            )}
                            <div className="row">
                                <div className="col-md-4 col-sm-12 mb-3">
                                    <label for="fecha">Fecha</label>
                                    <input 
                                        type="date" 
                                        className={`form-control ${errors.fecha ? 'is-invalid' : ''}`} 
                                        id="fecha" 
                                        name="fecha" 
                                        placeholder="fecha"
                                        value={fecha}
                                        onChange={(e) => setFecha(e.target.value)}
                                    />
                                    {errors.fecha && <div className="invalid-feedback">{errors.fecha}</div>}
                                </div>
                                {entregas && (
                                    <>
                                    <div className="col-md-4 col-sm-12 mb-3 virtual">
                                        <label htmlFor="fecha_inicio">Fecha Inicio</label>
                                        <input 
                                            type="datetime-local" 
                                            className={`form-control ${errors.fechaInicio ? 'is-invalid' : ''}`} 
                                            id="fecha_inicio" 
                                            name="fecha_inicio" 
                                            placeholder="fecha_inicio"
                                            value={fechaInicio}
                                            onChange={(e) => setFechaInicio(e.target.value)}
                                        />
                                        {errors.fechaInicio && <div className="invalid-feedback">{errors.fechaInicio}</div>}
                                    </div>
                                    <div className="col-md-4 col-sm-12 mb-3 virtual">
                                        <label htmlFor="fecha_fin">Fecha Fin</label>
                                        <input 
                                            type="datetime-local" 
                                            className={`form-control ${errors.fechaFin ? 'is-invalid' : ''}`} 
                                            id="fecha_fin" 
                                            name="fecha_fin" 
                                            placeholder="fecha_fin"
                                            value={fechaFin}
                                            onChange={(e) => setFechaFin(e.target.value)}
                                        />
                                        {errors.fechaFin && <div className="invalid-feedback">{errors.fechaFin}</div>}
                                    </div>
                                    </>
                                )}
                            </div>
                            <input 
                                ref={inputFileRef}
                                type="file"
                                name="file"
                                accept="image/*, application/pdf"
                                className="mt-2 imagen"
                            />
                            <button 
                                type="submit" 
                                className="btn btn-primary me-2 boton-procesar" 
                                disabled={isSubmitting}
                            >
                                {isSubmitting && (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                )}
                                {isSubmitting ? 'Guardando...' : <><i className="fas fa-save"></i> Crear Actividad</>}
                            </button>
                            <Link className="btn btn-warning" to={`/Materias/Contenido/${materiaId}/${parcialId}`}>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;
