// src/routes/NotaMateriaRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../componentes/auth/ProtectedRoute';
import Template from '../componentes/client/layout/Template';

// niveles
import Parcial from '../componentes/client/notamaterias/Subciclo';
import Ciclo from '../componentes/client/notamaterias/Ciclo';

const NotaMateriaRoutes = () => {
    return (
        <Routes>
            <Route
                path="Parcial/:materiaId/:subcicloId"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Parcial />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="Ciclo/:materiaId/:cicloId"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Ciclo />
                        </Template>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default NotaMateriaRoutes;
