import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useApiContext } from '../layout/Template';
import Spinner from '../widget/Spinner';
import DatosGeneralesContext from '../widget/DatosGeneralesContext';
import DatosDocente from './DatosDocente';

const Edit = () => {
    const { apiUrl, accessToken } = useApiContext();
    const { id } = useParams(); // Obtén el ID del parámetro de la URL
    const [formData, setFormData] = useState({
        docente: {
            identificacion: '',
            extranjero: false,
            nombre: '',
            apellido: '',
            correo: '',
            celular: '',
            telefono: '',
            direccion: '',
            sexo: '',
            id_canton: '',
            id_nacionalidad: '',
        },
    });
    const [usuario, setUsuario] = useState(null);
    const [docente, setDocente] = useState(null);

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const { cantones, paises } = DatosGeneralesContext(apiUrl);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/docentes/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    }
                });

                const docenteData = response.data.docente;

                setFormData({
                    docente: {
                        identificacion: docenteData.identificacion || '',
                        extranjero: docenteData.extranjero || false,
                        nombre: docenteData.nombre || '',
                        apellido: docenteData.apellido || '',
                        correo: docenteData.correo || '',
                        celular: docenteData.celular || '',
                        telefono: docenteData.telefono || '',
                        direccion: docenteData.direccion || '',
                        sexo: docenteData.sexo || '',
                        id_canton: docenteData.id_canton || '',
                        id_nacionalidad: docenteData.id_nacionalidad || '',
                    }
                });
                setUsuario(response.data.usuario);
                setDocente(response.data.docente);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener los datos para editar:', error.response ? error.response.data : error.message);
                setLoading(false);
            }
        };

        fetchData();
    }, [id, apiUrl, accessToken]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.put(`${apiUrl}/docentes/${id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            console.log('Docente actualizado exitosamente:', response.data);
            navigate('/docentes');
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            if (error.response) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error al actualizar el Docente:', error.response ? error.response.data : error.message);
            }
        }
    };

    const reestablecerPwd = async (idUsuario) => {
        try {
            const response = await axios.post(`${apiUrl}/restorePwd/${idUsuario}`, {}, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
    
            console.log('Contraseña restablecida exitosamente:', response.data);
            // Si deseas redirigir o realizar alguna acción adicional
            // navigate('/algun/ruta');  // Redirecciona a una página específica
            window.showSuccessPopup(response.data.message); // Muestra un popup con éxito
        } catch (error) {
            window.showErrorPopup("Ocurrio un error"); // Muestra un popup con éxito
        }
    };
    

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Editar Docente</h2>
                </div>
            </div>
            
            <form onSubmit={handleSubmit}>
                <div className='row'>
                    <DatosDocente
                        formData={formData}
                        setFormData={setFormData}
                        paises={paises}
                        cantones={cantones}
                        errors={errors}
                    />
                </div>
                <button type="submit" className="btn btn-primary me-2"><i className="fas fa-save"></i> Guardar Cambios</button>
                {usuario && (
                    <>
                        <Link to='#' className="btn btn-info me-2" onClick={() => reestablecerPwd(usuario.id)}>
                            <i className="fas fa-lock"></i> Reestablecer Contraseña
                        </Link>
                    </>
                )}
                <Link className="btn btn-warning" to='/docentes'>Volver</Link>
            </form>
        </div>
    );
};

export default Edit;
