import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useApiContext } from '../layout/Template';
import { useNavigate } from 'react-router-dom';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import logoRect from '../../../assets/img/edusnap/logo_hotizontal.png'; 
import logo_mineud from '../../../assets/img/min_edu2024.png'; 

const Ciclo = () => {
    const { matriculaId, cicloId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user,subdominio } = useApiContext();
    
    const [estudiante, setEstudiante] = useState(null);
    const [escuela, setEscuela] = useState(null);
    const [ocultarBoletin, setOcultarBoletin] = useState(null);
    const [curso, setCurso] = useState(null);
    const [ciclos, setCiclos] = useState([]);
    const [materias, setMaterias] = useState([]);
    const [elementos, setElementos] = useState([]);
    const [notas, setNotas] = useState([]);
    const [tests, setTests] = useState([]);
    const [notatests, setNotatests] = useState([]);
    const [examenes, setExamenes] = useState([]);
    const [notaexamenes, setNotaexamenes] = useState([]);
    const [proyectos, setProyectos] = useState([]);
    const [notaproyectos, setNotaproyectos] = useState([]);
    const [conducta, setConducta] = useState([]);
    const [cicloActual, setCicloActual] = useState(null);
    const [subciclos, setSubciclos] = useState([]);
    const [bitacoras, setBitacoras] = useState([]);
    const [asistencias, setAsistencias] = useState([]);

    const logoUrl = escuela?.logo_firmado
        ? `${escuela.logo_firmado}` // Ajusta la URL según tu configuración
        : logoRect;
    
    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/boletin/ciclo/${matriculaId}/${cicloId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            const data = response.data;

            setEstudiante(data.estudiante);
            setEscuela(data.escuela);
            setOcultarBoletin(data.ocultarBoletin);
            setCurso(data.curso);
            setCiclos(data.ciclos);
            setMaterias(data.materias);
            setElementos(data.elementos);
            setNotas(data.notas);
            setTests(data.tests);
            setNotatests(data.notatests);
            setExamenes(data.examenes);
            setNotaexamenes(data.notaexamenes);
            setProyectos(data.proyectos);
            setNotaproyectos(data.notaproyectos);
            setConducta(data.conducta);
            setCicloActual(data.cicloActual);
            setSubciclos(data.subciclos);
            setBitacoras(data.bitacoras);
            setAsistencias(data.asistencias);

            console.log(data)

        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    //navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (user && user.estudiante === 1 && ocultarBoletin === 1) {
        window.showErrorPopup("Boletines Inhabilitados");
        return;
    }

    const renderTableRows = () => {
        let sumaCiclo = 0;
        let promGeneralSubciclos = Array(subciclos.length).fill(0);

        return materias.map((materia) => {
            let sumaGeneral = 0;
            let sumaProcesos = 0;
            let valParciales = 0;
            let sumaAportes = 0;
            let valAportes = 0;
            let promParcialesMat = 0;
            let porcParcialesMat = 0;

            const subcicloCells = subciclos.map((subciclo, index) => {
                let notasProceso = 0;
                let proceso = 0;
                let procesoPorcentaje = 0;
                let testPorcentaje = 0;
                let notaAporte = 0;
                let totalElementos = elementos.filter(
                    (e) => e.id_subciclo === subciclo.id && e.id_materiacurso === materia.id
                ).length;

                elementos
                    .filter((e) => e.id_subciclo === subciclo.id && e.id_materiacurso === materia.id)
                    .forEach((elemento) => {
                        const nota = notas.find((n) => n.id_elemento === elemento.id);
                        notasProceso += nota ? parseFloat(nota.calificacion) : 0;
                    });

                proceso = totalElementos > 0 ? parseFloat((notasProceso / totalElementos).toFixed(2)) : 0;
                procesoPorcentaje = parseFloat((proceso * 0.7).toFixed(2));

                tests
                    .filter((t) => t.id_subciclo === subciclo.id && t.id_materiacurso === materia.id)
                    .forEach((evaluacion) => {
                        const notatest = notatests.find((nt) => nt.id_elemento === evaluacion.id);
                        notaAporte = notatest ? parseFloat(notatest.calificacion) : 0;
                        testPorcentaje = parseFloat((notaAporte * 0.3).toFixed(2));
                    });
                //linea 138, 235
                //const notatest = notatests.find((nt) => nt.id_evaluacion === evaluacion.id);

                const promFinal = parseFloat((procesoPorcentaje + testPorcentaje).toFixed(2));
                sumaGeneral += promFinal;
                promGeneralSubciclos[index] += promFinal;
                sumaProcesos += proceso;
                sumaAportes += notaAporte;

                //return <td className="text-center boletin" key={`${materia.id}-${subciclo.id}`}>{promFinal}</td>;
            });
            valParciales = subciclos.length > 0 ? parseFloat((sumaProcesos / subciclos.length).toFixed(2)) : 0;
            valAportes = subciclos.length > 0 ? parseFloat((sumaAportes / subciclos.length).toFixed(2)) : 0;
            promParcialesMat = subciclos.length > 0 ? parseFloat((sumaGeneral / subciclos.length).toFixed(2)) : 0;
            porcParcialesMat = parseFloat((promParcialesMat * 0.7).toFixed(2));

            let valExamen = 0;
            const examenMateria = examenes.filter((ex) => ex.id_materiacurso === materia.id);

            if (examenMateria.length > 0) {
                examenMateria.forEach((examen) => {
                    const notaexamen = notaexamenes.find((ne) => ne.id_examen === examen.id);
                    valExamen = notaexamen ? parseFloat(notaexamen.calificacion) : 0;
                });
            }

            let valProy = 0;
            const proyMateria = proyectos.filter((proy) => proy.id_materiacurso === materia.id);

            if (proyMateria.length > 0) {
                proyMateria.forEach((proyecto) => {
                    const notaproyecto = notaproyectos.find((ne) => ne.id_proyecto === proyecto.id);
                    valProy = notaproyecto ? parseFloat(notaproyecto.calificacion) : 0;
                });
            }

            const porcExamen = parseFloat((valExamen * 0.2).toFixed(2));
            const porcProy = parseFloat((valProy * 0.1).toFixed(2));
            const totalMateria = parseFloat((porcParcialesMat + porcExamen + porcProy).toFixed(2));
            sumaCiclo += totalMateria;

            return (
                <tr key={materia.id}>
                    <td className="boletin contenido body">{materia.materia.nombre}</td>
                    {subcicloCells}
                    <td className="text-center boletin">{valParciales}</td>
                    <td className="text-center boletin">{valAportes}</td>
                    <td className="text-center boletin">{promParcialesMat}</td>
                    <td className="text-center boletin">{porcParcialesMat}</td>
                    <td className="text-center boletin">{valExamen}</td>
                    <td className="text-center boletin">{porcExamen}</td>
                    <td className="text-center boletin">{valProy}</td>
                    <td className="text-center boletin">{porcProy}</td>
                    <td className="text-center boletin">{totalMateria}</td>
                    <td className="boletin text-center">{getCuantitativa(totalMateria)}</td>
                </tr>
            );
        });
    };

    const calculatePromedioGeneral = () => {
        let sumaCiclo = 0;
        let sumaExam = 0;
        let sumaProy = 0;
        let sumaProms = 0;
        let promGeneralSubciclos = Array(subciclos.length).fill(0);

        materias.forEach((materia) => {
            let sumaGeneral = 0;

            subciclos.forEach((subciclo, index) => {
                let notasProceso = 0;
                let proceso = 0;
                let procesoPorcentaje = 0;
                let testPorcentaje = 0;
                let notaAporte = 0;
                let totalElementos = elementos.filter(
                    (e) => e.id_subciclo === subciclo.id && e.id_materiacurso === materia.id
                ).length;

                elementos
                    .filter((e) => e.id_subciclo === subciclo.id && e.id_materiacurso === materia.id)
                    .forEach((elemento) => {
                        const nota = notas.find((n) => n.id_elemento === elemento.id);
                        notasProceso += nota ? parseFloat(nota.calificacion) : 0;
                    });

                proceso = totalElementos > 0 ? parseFloat((notasProceso / totalElementos).toFixed(2)) : 0;
                procesoPorcentaje = parseFloat((proceso * 0.7).toFixed(2));

                tests
                    .filter((t) => t.id_subciclo === subciclo.id && t.id_materiacurso === materia.id)
                    .forEach((evaluacion) => {
                        const notatest = notatests.find((nt) => nt.id_elemento === evaluacion.id);
                        notaAporte = notatest ? parseFloat(notatest.calificacion) : 0;
                        testPorcentaje = parseFloat((notaAporte * 0.3).toFixed(2));
                    });

                const promFinal = parseFloat((procesoPorcentaje + testPorcentaje).toFixed(2));
                sumaGeneral += promFinal;
                promGeneralSubciclos[index] += promFinal;
            });

            const promParcialesMat = subciclos.length > 0 ? parseFloat((sumaGeneral / subciclos.length).toFixed(2)) : 0;
            const porcParcialesMat = parseFloat((promParcialesMat * 0.7).toFixed(2));

            let valExamen = 0;
            const examenMateria = examenes.filter((ex) => ex.id_materiacurso === materia.id);

            if (examenMateria.length > 0) {
                examenMateria.forEach((examen) => {
                    const notaexamen = notaexamenes.find((ne) => ne.id_examen === examen.id);
                    valExamen = notaexamen ? parseFloat(notaexamen.calificacion) : 0;
                });
            }

            let valProy = 0;
            const proyMateria = proyectos.filter((proy) => proy.id_materiacurso === materia.id);

            if (proyMateria.length > 0) {
                proyMateria.forEach((proyecto) => {
                    const notaproyecto = notaproyectos.find((np) => np.id_proyecto === proyecto.id);
                    valProy = notaproyecto ? parseFloat(notaproyecto.calificacion) : 0;
                });
            }

            const porcExamen = parseFloat((valExamen * 0.2).toFixed(2));
            const porcProy = parseFloat((valProy * 0.1).toFixed(2));
            //const totalMateria = parseFloat((porcParcialesMat + porcExamen + porcProy).toFixed(2));
            sumaCiclo += promParcialesMat;
            sumaExam += valExamen;
            sumaProy += valProy;
        });

        const promedioGeneral = sumaCiclo / materias.length;
        const promExamenes = sumaExam / materias.length;
        const promProy = sumaProy / materias.length;
        return { promedioGeneral, promGeneralSubciclos, promExamenes, promProy };
    };

    const { promedioGeneral, promGeneralSubciclos, promExamenes, promProy } = calculatePromedioGeneral();
    
    const diasLaborados = bitacoras.length;
    let injustificadas = 0;
    let justificadas = 0;
    let atrasos = 0;
    asistencias.forEach(asistencia => {
        switch (asistencia.asistencia) {
            case 3:
                injustificadas++;
            break;
            case 2:
                atrasos++;
            break;
            case 4:
                justificadas++;
            break;
            default:
            break;
        }
    });

    // Función para validar y obtener un número
    const toNumber = (value) => {
        return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    };

    // Función para calcular la escala cuantitativa
    const getCuantitativa = (promFinal) => {
        if(curso.inicial === 1){
            if (promFinal < 4) return "N.E.";
            if (promFinal >= 4.01 && promFinal <= 6.99) return "I";
            if (promFinal >= 7 && promFinal < 8.99) return "EP";
            if (promFinal >= 9 && promFinal <= 10) return "A";
            return "N.E.";
        }else{
            if (promFinal >= 1 && promFinal < 2) return "E-";
            if (promFinal >= 2 && promFinal < 3) return "E+";
            if (promFinal >= 3 && promFinal < 4) return "D-";
            if (promFinal >= 4 && promFinal < 5) return "D+";
            if (promFinal >= 5 && promFinal < 6) return "C-";
            if (promFinal >= 6 && promFinal < 7) return "C+";
            if (promFinal >= 7 && promFinal < 8) return "B-";
            if (promFinal >= 8 && promFinal < 9) return "B+";
            if (promFinal >= 9 && promFinal < 10) return "A-";
            if (promFinal === 10) return "A+";
            return "E-";
        }
    };

    const generarPDF = async () => {
        console.log("procesando");
        try {
            const response = await axios.get(`${apiUrl}/boletin/ciclo/pdf/${matriculaId}/${cicloId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/pdf', // Asegúrate de que la respuesta sea un PDF
                },
                responseType: 'blob' // Importante para manejar la respuesta como archivo binario
            });
    
            // Crear un enlace de descarga
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
    
            // Forma el nombre del archivo con un formato seguro para nombres de archivo
            const nombreArchivo = `Trimestral ${estudiante.estudiante.nombres}.pdf`;
            link.setAttribute('download', nombreArchivo);
            document.body.appendChild(link);
            link.click();
    
            // Liberar el objeto URL y eliminar el enlace del DOM
            window.URL.revokeObjectURL(url);
            link.remove();
        } catch (err) {
            console.error('Error al descargar el PDF:', err);
            window.showErrorPopup('Error al descargar el archivo PDF.');
        }
        console.log("fin");
    };

    if (loading) {
        return <Spinner />;
    }
    
    return (
        <div className="py-3 px-3 content-wrapper">
            <style>
                {`
                    .boletin {
                        padding: 6px;
                        border: 1px solid black;
                        font-size: 13px;
                    }
                    
                    .bold {
                        font-weight: bold;
                    }
                    .mayusculas{
                        text-transform: uppercase;
                    }
                    .firma-container {
                        display: flex;
                        justify-content: space-between;
                        margin: 20px;
                    }
            
                    .firma {
                        width: 200px;
                        border-top: 1px solid #000;
                        margin-top: 10px;
                        padding-top: 10px;
                        text-align: center;
                    }
            
                    .firma-name {
                        font-weight: bold;
                    }
            
                    .firma-title {
                        margin-top: 5px;
                    }
                `}
            </style>
            <button className="btn btn-primary" onClick={generarPDF}>
                <i className="fas fa-file-pdf"></i> <span className="d-none d-lg-inline"> Generar PDF</span>
            </button>
            <div className='m-3' style={{ padding: "16px 16px 2px 16px", border: "2px solid", display: "flow-root", backgroundColor: "white" }}>
                <div>
                    <table cellPadding="0" cellSpacing="0" width="100%">
                        <tbody>
                            <tr>
                                <td style={{ width: '25%', textAlign: 'center' }}>
                                    <img src={logoUrl} alt="Logo" width="100" />
                                </td>
                                <td style={{ textAlign: 'center' }}> {/* Cambiado a un objeto */}
                                    <div style={{ fontSize: '17px' }}><strong>{escuela.nombre}</strong></div>
                                    <div style={{ fontSize: '19px' }} className="mayusculas">
                                        <strong> INFORME DE RENDIMIENTO ACADÉMICO</strong>
                                    </div>
                                    <div style={{ fontSize: '14px' }}>
                                        Correo: {escuela.correo}
                                    </div>
                                    <div className="d-sm-flex justify-content-between align-items-start px-5" style={{ fontSize: '14px' }}>
                                        <div>Cantón: {escuela.canton.nombre}</div>
                                        <div>Parroquia: {escuela.parroquia}</div>
                                    </div>
                                </td>
                                <td style={{ width: '25%', textAlign: 'center' }}>
                                    <img src={logo_mineud} alt="" width="120"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <table
                        style={{
                            fontSize: '14px',
                            textAlign: 'center',
                            borderCollapse: 'collapse',
                            width: '100%',
                        }}
                        border="1"
                    >
                        <tbody className="mayusculas">
                            <tr>
                                <th className="boletin">NOMBRES:</th>
                                <td className="boletin">{estudiante.estudiante.nombres}</td>
                                <th className="boletin">AÑO LECTIVO:</th>
                                <td className="boletin">{curso.periodo.descripcion}</td>
                            </tr>
                            <tr>
                                <th className="boletin">CURSO:</th>
                                <td className="boletin" style={{ fontSize: '13px', fontWeight: 'bold' }}>
                                    {curso.nivel.nombre} {curso.tipo.alias} {curso.seccion.descripcion}
                                </td>
                                <th className="boletin" colSpan="2">
                                    {cicloActual.descripcion}
                                </th>
                            </tr>
                            <tr>
                                <th className="boletin" colSpan="2">Docente:</th>
                                <td className="boletin" colSpan="2">
                                    {curso.tutor.nombres}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <div
                        style={{
                            overflowX: 'scroll',
                            overflowX: 'visible',
                            paddingBottom: '5px',
                            width: 'auto'
                        }}
                    >
                        <table cellpadding="0" cellspacing="0" width="100%" border="1">
                            <tbody>
                                <tr>
                                    <td colspan="1" rowspan="2" className="boletin text-center bold"> Materias/Asignaturas </td>
                                    <td colspan="10" className="boletin text-center bold"> {cicloActual.descripcion} </td>
                                </tr>
                                <tr className="mayusculas">
                                    <th className="boletin text-center">Insumos 70%</th>
                                    <th className="boletin text-center">Aporte 30%</th>
                                    <th className="boletin text-center">Proceso</th>
                                    <th className="boletin text-center">70%</th>
                                    <th className="boletin text-center">Examen</th>
                                    <th className="boletin text-center">20%</th>
                                    <th className="boletin text-center">{subdominio === 'hacialacumbre' ? 'Apt': 'P. Int.'}</th>
                                    <th className="boletin text-center">10%</th>
                                    <th className="boletin text-center">PROM. FINAL</th>
                                    <th className="boletin text-center">Cualitativa</th>
                                </tr>
                                
                                {renderTableRows()}
                                <tr>
                                    <td className="boletin contenidobody bold promedio">PROMEDIO GENERAL</td>
                                    <td colSpan={'2'} className="boletin contenidobody text-center bold promedio">
                                        -
                                    </td>
                                    <td className="boletin contenidobody text-center bold promedio">
                                        {parseFloat(promedioGeneral.toFixed(2))}
                                    </td>
                                    <td className="boletin contenidobody text-center bold promedio">
                                        {parseFloat((promedioGeneral * 0.7).toFixed(2))}
                                    </td>
                                    <td className="boletin contenidobody text-center bold promedio">
                                        {parseFloat(promExamenes.toFixed(2))}
                                    </td>
                                    <td className="boletin contenidobody text-center bold promedio">
                                        {parseFloat((promExamenes * 0.2).toFixed(2))}
                                    </td>
                                    <td className="boletin contenidobody text-center bold promedio">
                                        {parseFloat(promProy.toFixed(2))}
                                    </td>
                                    <td className="boletin contenidobody text-center bold promedio">
                                        {parseFloat((promProy * 0.1).toFixed(2))}
                                    </td>
                                    <td className="boletin contenidobody text-center bold promedio">
                                        {parseFloat(((promedioGeneral * 0.7) + (promExamenes * 0.2) + (promProy * 0.1)).toFixed(2))}
                                    </td>
                                    <td className="boletin contenidobody text-center bold promedio">
                                        {getCuantitativa(((promedioGeneral * 0.7) + (promExamenes * 0.2) + (promProy * 0.1)))}
                                    </td>
                                </tr>

                                <tr>
                                    <td className="boletin contenidobody bold">
                                            COMPORTAMIENTO
                                    </td>
                                    <td colspan="10" className="boletin contenidobody text-center bold">
                                        { conducta }
                                    </td>
                                </tr>
                                <tr>
                                    <td className="boletin contenidobody">
                                    Días Laborados
                                    </td>
                                    <td colspan="10" className="boletin contenidobody text-center">
                                        {diasLaborados}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="boletin contenidobody">
                                    Faltas Injustificadas
                                    </td>
                                    <td colspan="10" className="boletin contenidobody text-center">
                                        {injustificadas}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="boletin contenidobody">
                                        Atrasos
                                    </td>
                                    <td colspan="10" className="boletin contenidobody text-center">
                                        {atrasos}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="boletin contenidobody">
                                        Faltas Justificadas
                                    </td>
                                    <td colspan="10" className="boletin contenidobody text-center">
                                        {justificadas}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br/>
                    <table cellpadding="0" cellspacing="0" border="1" width="100%">       
                        <tbody>
                            <tr>
                                <td className="boletin" valign="top">
                                    <center><strong>ESCALA DE APROVECHAMIENTO</strong></center> 
                                    <div>
                                        <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                            <tbody>
                                                {curso.inicial === 1 ? (
                                                    <>
                                                    <tr>
                                                        <td style={{width: "80%;"}}><div className="escalas">A - Destrezas o Aprendizajes Alcanzados.</div></td>
                                                        <td><div className="escalas">(9.00 a 10.00)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}><div className="escalas">EP - Destrezas o aprendizajes en Proceso de Desarrollo.</div></td>
                                                        <td><div className="escalas">(7.00 a 8.99)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}> <div className="escalas"> I - Destrezas o Aprendizaje Iniciado. </div></td>
                                                        <td><div className="escalas">(4.01 a 6.99)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}> <div className="escalas"> N.E. - Destrezas o Aprendizaje no Alcanzados. </div></td>
                                                        <td><div className="escalas">(0.00 a 4.00)</div></td>
                                                    </tr>
                                                    </>
                                                ) : (
                                                    <>
                                                    <tr>
                                                        <td style={{width: "80%;"}}><div className="escalas">DAR - Domina los aprendizajes requeridos.</div></td>
                                                        <td><div className="escalas">(9.00 a 10.00)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}><div className="escalas">AAR - Alcanza los aprendizajes requeridos.</div></td>
                                                        <td><div className="escalas">(7.00 a 8.99)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}> <div className="escalas"> PAAR - Está próximo a alcanzar los aprendizajes requeridos. </div></td>
                                                        <td><div className="escalas">(4.01 a 6.99)</div></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "80%;"}}> <div className="escalas"> NAAR - No alcanza los aprendizajes requeridos. </div></td>
                                                        <td><div className="escalas">(0.00 a 4.00)</div></td>
                                                    </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="boletin">
                                    <center><strong>ESCALA COMPORTAMIENTO</strong></center>
                                    <div>
                                        S - Siempre idera el cumplimiento de los compromisos establecidos para la sana convivencia social. <br/>
                                        F - Frecuentemente cumple con los compromisos establecidos para la sana convivencia social. <br/>
                                        O - Ocasionalmente falla en el cumplimiento de los compromisos establecidos para la sana convivencia social. <br/>
                                        N - No cumple con los compromisos establecidos para la sana convivencia social. <br/>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <div className="firma-container">
                        <div className="firma">
                            <div className="firma-line"></div>
                            <div className="firma-name">{escuela.rector}</div>
                            <div className="firma-title">Diector/a</div>
                        </div>
                        <div className="firma">
                            <div className="firma-line"></div>
                            <div className="firma-name">{curso.tutor.nombres}</div>
                            <div className="firma-title">Tutor/a</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Ciclo;
