import React from 'react';
import TextInput from '../../form/InputText';
import SelectInput from '../../form/SelectInput';
import CheckboxInput from '../../form/CheckboxInput';

const FormContent = ({ formData, setFormData, niveles, secciones, tipos, modalidades, especialidades, jornadas, periodos, docentes, isEdit, errors }) => {

    // Definir handleInputChange dentro del componente
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCheckboxChange = (field, subField = null) => (e) => {
        const checked = e.target.checked;
        setFormData(prevFormData => {
            if (subField) {
                // Caso para subcampos
                return {
                    ...prevFormData,
                    [field]: {
                        ...prevFormData[field],
                        [subField]: checked
                    }
                };
            } else {
                // Caso para campos en el nivel superior
                return {
                    ...prevFormData,
                    [field]: checked
                };
            }
        });
    };

    return (
        <>
            <div className='row'>
                {/* Condicionar el campo "id_periodo" solo si no es edición */}
                {!isEdit && (
                    <div className='col-12'>
                        <SelectInput
                            id="id_periodo"
                            name="id_periodo"
                            value={formData.id_periodo}
                            onChange={handleInputChange}
                            placeholder="Periodo"
                            options={periodos.map(periodo => ({
                                value: periodo.id,
                                label: periodo.descripcion
                            }))}
                            error={errors['id_periodo'] && errors['id_periodo'][0]}
                        />
                    </div>
                )}
                <div className='col-6'>
                    <SelectInput
                        id="id_nivel"
                        name="id_nivel"
                        value={formData.id_nivel}
                        onChange={handleInputChange}
                        placeholder="Nivel"
                        options={niveles.map(nivel => ({
                            value: nivel.id,
                            label: nivel.nombre_corto + ' - ' + nivel.nombre
                        }))}
                        error={errors['id_nivel'] && errors['id_nivel'][0]}
                    />
                </div>
                <div className='col-6'>
                    <SelectInput
                        id="id_seccion"
                        name="id_seccion"
                        value={formData.id_seccion}
                        onChange={handleInputChange}
                        placeholder="Seccion"
                        options={secciones.map(seccion => ({
                            value: seccion.id,
                            label: seccion.descripcion
                        }))}
                        error={errors['id_seccion'] && errors['id_seccion'][0]}
                    />
                </div>
                <div className='col-6'>
                    <SelectInput
                        id="id_tipo"
                        name="id_tipo"
                        value={formData.id_tipo}
                        onChange={handleInputChange}
                        placeholder="Tipo"
                        options={tipos.map(tipo => ({
                            value: tipo.id,
                            label: tipo.descripcion
                        }))}
                        error={errors['id_tipo'] && errors['id_tipo'][0]}
                    />
                </div>
                <div className='col-6'>
                    <SelectInput
                        id="id_modalidad"
                        name="id_modalidad"
                        value={formData.id_modalidad}
                        onChange={handleInputChange}
                        placeholder="Modalidad"
                        options={modalidades.map(modalidad => ({
                            value: modalidad.id,
                            label: modalidad.descripcion
                        }))}
                        error={errors['id_modalidad'] && errors['id_modalidad'][0]}
                    />
                </div>
                <div className='col-6'>
                    <SelectInput
                        id="id_jornada"
                        name="id_jornada"
                        value={formData.id_jornada}
                        onChange={handleInputChange}
                        placeholder="Jornada"
                        options={jornadas.map(jornada => ({
                            value: jornada.id,
                            label: jornada.descripcion
                        }))}
                        error={errors['id_jornada'] && errors['id_jornada'][0]}
                    />
                </div>
                <div className='col-6'>
                    <SelectInput
                        id="id_especialidad"
                        name="id_especialidad"
                        value={formData.id_especialidad}
                        onChange={handleInputChange}
                        placeholder="Especialidad"
                        options={especialidades.map(especialidad => ({
                            value: especialidad.id,
                            label: especialidad.descripcion
                        }))}
                        error={errors['id_especialidad'] && errors['id_especialidad'][0]}
                    />
                </div>
                <div className='col-12'>
                    <SelectInput
                        id="id_tutor"
                        name="id_tutor"
                        value={formData.id_tutor}
                        onChange={handleInputChange}
                        placeholder="Tutor"
                        options={docentes.map(periodo => ({
                            value: periodo.id,
                            label: periodo.nombres
                        }))}
                        error={errors['id_tutor'] && errors['id_tutor'][0]}
                    />
                </div>
                <div className="col-6">
                    <TextInput
                        label="Valor Matricula"
                        name="matricula"
                        value={formData.matricula}
                        onChange={handleInputChange}
                        placeholder="Valor Matricula"
                        errors={errors}
                        validacion={'decimal'}
                    />
                </div>
                <div className="col-6">
                    <TextInput
                        label="Valor Cuotas"
                        name="cuota"
                        value={formData.cuota}
                        onChange={handleInputChange}
                        placeholder="Valor Cuotas"
                        errors={errors}
                        validacion={'decimal'}
                    />
                </div>
                {isEdit && (
                    <>
                        <div className=''>
                            <CheckboxInput 
                                id="estado"
                                name="estado"
                                checked={formData.estado}
                                onChange={handleCheckboxChange('estado')}
                                label="Estado"
                            />
                        </div>
                        <div className=''>
                            <CheckboxInput 
                                id="inicial"
                                name="inicial"
                                checked={formData.inicial}
                                onChange={handleCheckboxChange('inicial')}
                                label="Inicial"
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default FormContent;