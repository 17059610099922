import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchParametros = (apiUrl, accessToken) => {
    const [parametros, setParametros] = useState({
        periodos: [],
        niveles: [],
        secciones: [],
        tipos: [],
        modalidades: [],
        especialidades: [],
        jornadas: [],
        docentes: []
    });

    useEffect(() => {
        const fetchParametros = async () => {
            const cacheKey = 'parametrosCache';
            const cacheExpirationKey = 'parametrosCacheExpiration';
            const cachedData = localStorage.getItem(cacheKey);
            const cacheExpiration = localStorage.getItem(cacheExpirationKey);
            const now = new Date().getTime();

            // Verificar si hay datos en caché y si aún son válidos (1 hora = 3600000 ms)
            if (cachedData && cacheExpiration && now < cacheExpiration) {
                const parametrosCacheados = JSON.parse(cachedData);
                setParametros(parametrosCacheados);
                console.log('Datos cargados desde el caché');
            } else {
                try {
                    const response = await axios.get(`${apiUrl}/cursos/parametros`, {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Accept': 'application/json'
                        }
                    });

                    const parametrosAPI = response.data;

                    // Guardar los datos en caché y la expiración (1 hora)
                    localStorage.setItem(cacheKey, JSON.stringify(parametrosAPI));
                    localStorage.setItem(cacheExpirationKey, now + 3600000);

                    setParametros(parametrosAPI);
                    console.log('Datos obtenidos desde la API y guardados en el caché');
                } catch (error) {
                    console.error('Error al obtener los parámetros:', error.response ? error.response.data : error.message);
                }
            }
        };

        fetchParametros();
    }, [apiUrl, accessToken]);

    return parametros;
};

export default useFetchParametros;
