import axios from 'axios';
import React, { useEffect, useState, useRef  } from 'react';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import FileViewer from '../widget/FileViewer';
import { validateAndFormatValue } from '../widget/ValidateAndFormatValue';
import Entrega from './Entrega';
import { useApiContext } from '../layout/Template';
import { useNavigate } from 'react-router-dom';
import { format as formatDate } from 'date-fns'; // Renombrar la importación de date-fns
import { es } from 'date-fns/locale';
import { toZonedTime } from 'date-fns-tz';

const isWithinDateRange = (startDate, endDate) => {
    // Especifica la zona horaria de Guayaquil o Quito
    const timeZone = 'America/Guayaquil';
    
    // Obtén la fecha y hora actual en la zona horaria especificada
    const now = toZonedTime(new Date(), timeZone);
    console.log(now);
    
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    return now >= start && now <= end;
};

const Show = () => {
    const { actividadId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user } = useApiContext();
    const [actividad, setActividad] = useState(null);
    const [materia, setMateria] = useState(null);
    const [archivo_firmado, setaAchivo_firmado] = useState(null);
    const [estudiantes, setEstudiantes] = useState([]);
    const [filtroNombres, setFiltroNombres] = useState('');
    const [filtroEntrega, setFiltroEntrega] = useState('');
    const [filtroCalificacion, setFiltroCalificacion] = useState('');
    const [editableIndex, setEditableIndex] = useState(null);
    const [notas, setNotas] = useState(estudiantes.map(estudiante => estudiante.nota || ''));
    const [comentarios, setComentarios] = useState(estudiantes.map(estudiante => estudiante.comentario || ''));
    const [expandedRow, setExpandedRow] = useState(null);
    const [entregaRow, setEntregaRow] = useState(null);
    const [entrega, setEntrega] = useState({})
    const [entrega_firmada, setEntrega_firmada] = useState(null);
    

    const inputRefs = useRef([]);
    
    const formattedDate = (dateString, dateFormat = 'eeee dd MMMM yyyy HH:mm:ss') => {
        const date = new Date(dateString);
        return formatDate(date, dateFormat, { locale: es });
    };

    const handleToggleExpand = async (index, entregaId) => {
        setEntregaRow(null);
        if (expandedRow === index) {
            setExpandedRow(null); // colapsar si ya está expandido
            setEntrega({}); // resetear datos de entrega
        } else {
            try {
                const response = await axios.get(`${apiUrl}/entrega/${entregaId}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json'
                    }
                });
                const data = response.data; // Cambia esto para usar data
                setEntrega(data.entrega); // Guardar los datos de la entrega
                setEntrega_firmada(data.entrega_firmada);
                setExpandedRow(index); // expandir la fila
            } catch (error) {
                console.error("Error al obtener la entrega:", error);
            }
        }
    };

    const handdleEntregaToggle = async (index, entregaId) => {
        setExpandedRow(null); 
        if (entregaRow === index) {
            setEntregaRow(null); // colapsar si ya está expandido
        } else {
            setEntregaRow(index); 
        }
    };
    
    const handleFiltroNombresChange = (e) => {
        setFiltroNombres(e.target.value);
    };

    const handleFiltroEntregaChange = (e) => {
        setFiltroEntrega(e.target.value);
    };

    const handleFiltroCalificacionChange = (e) => {
        setFiltroCalificacion(e.target.value);
    };

    const handleNotaChange = (index, value) => {
        const newNotas = [...notas];
        newNotas[index] = value;
        setNotas(newNotas);
    };

    const handleComentarioChange = (e, index) => {
        const nuevosComentarios = [...comentarios];
        nuevosComentarios[index] = e.target.value;
        setComentarios(nuevosComentarios);
    };

    const estudiantesFiltrados = estudiantes.filter(estudiante => {
        const nombreMatch = estudiante.nombres.toLowerCase().includes(filtroNombres.toLowerCase());
        const entregaMatch = filtroEntrega === '' || (filtroEntrega === 'entregado' && estudiante.entrega) || (filtroEntrega === 'pendiente' && !estudiante.entrega);
        const calificacionMatch = filtroCalificacion === '' || (filtroCalificacion === 'conCalificacion' && estudiante.nota) || (filtroCalificacion === 'sinCalificacion' && !estudiante.nota);
        return nombreMatch && entregaMatch && calificacionMatch;
    });

    const handleEditClick = (index) => {
        setEditableIndex(index);
    };

    useEffect(() => {
        if (editableIndex !== null && inputRefs.current[editableIndex]) {
            inputRefs.current[editableIndex].focus();
        }
    }, [editableIndex]);

    const handleCancelEdit = () => {
        setEditableIndex(null);
    };

    const handleSave = async (index) => {
        const idEstudiante = estudiantesFiltrados[index].id;
        const idActividad = actividad.id; // Asegúrate de que `actividad` contenga el ID correcto.
    
        try {
            // Verifica que la nota actual esté establecida correctamente en el estado
            const currentNota = notas[index] !== undefined ? notas[index] : estudiantesFiltrados[index].nota;
            const currentComentario = comentarios[index] !== undefined ? comentarios[index] : estudiantesFiltrados[index].comentario;

            const response = await axios.post(`${apiUrl}/nota/actividad`, {
                id_estudiante: idEstudiante,
                id_actividad: idActividad,
                calificacion: currentNota,
                comentario: currentComentario
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
    
            // Muestra el mensaje de éxito
            window.showSuccessPopup(response.data.message);

            // Actualiza el array original de estudiantes
            const updatedEstudiantes = estudiantes.map(est => 
                est.id === idEstudiante ? { ...est, nota: parseFloat(currentNota).toFixed(2), comentario: currentComentario } : est
            );
            setEstudiantes(updatedEstudiantes);
    
            // Cancela la edición después de guardar
            handleCancelEdit();
        } catch (error) {
            console.error('Error al guardar la calificación:', error);
            // Maneja el error según sea necesario
        }
    };
    
    const fetchActividad = async () => {
        try {
            const response = await axios.get(`${apiUrl}/materia/actividad/${actividadId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            const data = response.data;
            setActividad(data.actividad);
            setaAchivo_firmado(data.archivo_firmado);
            setMateria(data.materia);
            setEstudiantes(data.estudiantes);
            console.log(data.estudiantes);
            //window.showSuccessPopup('Datos del curso cargados exitosamente.');
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchActividad();
    }, [actividadId, apiUrl, accessToken]);

    const handleEntregaSuccess = () => {
        setEntregaRow(null); // colapsar si ya está expandido
        fetchActividad();
    };

    if (loading) {
        return <Spinner />;
    }

    // Función para extraer el ID de YouTube desde la URL
    const getYouTubeVideoId = (url) => {
        const regex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regex);
        return (match && match[1]) ? match[1] : null;
    };

    const videoId = actividad.url ? getYouTubeVideoId(actividad.url) : null;

    return (
        <div className="py-3 px-3 content-wrapper">
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2>
                        {materia.materia.nombre} {materia.curso.nivel.nombre_corto}/{materia.curso.seccion.descripcion} 
                        {materia.curso.id_especialidad ? ` - ${materia.curso.especialidad.descripcion}` : ''} - 
                        {materia.curso.jornada.descripcion}
                    </h2>
                </div>
                <div>
                    <div className="btn-group mb-2" role="group">
                        <Link to={`/Materias/Contenido/${materia.id}/${actividad.id_subciclo}`} className="btn btn-warning btn-sm"><i className="fas fa-chevron-circle-left"></i> <span className="d-none d-lg-inline"> Volver</span></Link>
                    </div>
                </div>
            </div>

            <div className='offset-lg-1 col-lg-10 offset-md-0 col-md-12 mb-3'>
                <div className="card">
                    <div className="card-body">
                        <header id="page-header">
                            <div className="d-flex align-items-center">
                                <div className="mr-auto">
                                    <h2>
                                        Actividad: {`${actividad.resumen} - ${actividad.titulo} - ${actividad.fecha}`}
                                    </h2>
                                    <p dangerouslySetInnerHTML={{ __html: actividad.descripcion }}></p>
                                    {actividad.fecha_inicio && actividad.entregas !== 0 && (
                                        <p className="text-danger">
                                            Activo Desde: {actividad.fecha_inicio}, Hasta: {actividad.fecha_fin}
                                        </p>
                                    )}
                                    {videoId ? (
                                        <div className="video-container">
                                            <div class="ratio ratio-16x9">
                                                <iframe src={`https://www.youtube.com/embed/${videoId}`} title="YouTube video" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    ) : (
                                        actividad.url && (
                                            <p>
                                                <Link to={actividad.url} target="_blank">
                                                    Recurso
                                                </Link>
                                            </p>
                                        )
                                    )}
                                    {archivo_firmado && (
                                        <FileViewer
                                            fileName={actividad.archivo}
                                            filePath={archivo_firmado}
                                        />
                                    )}
                                </div>
                            </div>
                        </header>
                    </div>
                </div>
                <div className="card mt-4">
                    <div className="card-body">
                        {user.estudiante !== 1 && (
                            <div className='row'>
                                <div className="col-lg-4">
                                    <input
                                        type="text"
                                        placeholder="Buscar estudiante..."
                                        value={filtroNombres}
                                        onChange={handleFiltroNombresChange}
                                        className="form-control"
                                    />
                                </div>
                                
                                {actividad.entregas === 1 && (
                                    <div className="col-lg-4">
                                        <select
                                            value={filtroEntrega}
                                            onChange={handleFiltroEntregaChange}
                                            className="form-select"
                                        >
                                            <option value="">Todos</option>
                                            <option value="entregado">Entregados</option>
                                            <option value="pendiente">Pendientes</option>
                                        </select>
                                    </div>
                                )}
                                <div className="col-lg-4">
                                    <select
                                        value={filtroCalificacion}
                                        onChange={handleFiltroCalificacionChange}
                                        className="form-select"
                                    >
                                        <option value="">Todos</option>
                                        <option value="conCalificacion">Con Calificación</option>
                                        <option value="sinCalificacion">Sin Calificación</option>
                                    </select>
                                </div>
                            </div>
                        )}
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        {user.estudiante !== 1 && (
                                            <>
                                            <th scope="col" style={{ width: '1%' }}>#</th>
                                            <th scope="col" style={{ width: '30%' }}>Estudiante</th>
                                            </>
                                        )}
                                        <th scope="col-1" className="" style={{ width: '30%' }}>Calificacion</th>
                                        {actividad.entregas === 1 && (
                                            <th scope="col" className="text-left">Entrega</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* .sort((a, b) => a.nombres.toLowerCase().localeCompare(b.nombres.toLowerCase())) */}
                                    {estudiantesFiltrados
                                    .map((estudiante, index) => (
                                        <React.Fragment key={estudiante.id}>
                                        <tr>
                                            {user.estudiante !== 1 && (
                                                <>
                                                    <td>{index + 1}</td>
                                                    <td>{estudiante.nombres}</td>
                                                </>
                                            )}
                                            <td className="text-center">
                                                <form onSubmit={(e) => { e.preventDefault(); handleSave(index); }}>
                                                    <div className="input-group" style={{ width: '200px' }}>
                                                        <input 
                                                            type="text" 
                                                            value={editableIndex === index ? notas[index] : estudiante.nota || ''} 
                                                            onInput={(e) => validateAndFormatValue(e, handleNotaChange, index)}
                                                            disabled={editableIndex !== index} 
                                                            className="form-control"
                                                            ref={el => inputRefs.current[index] = el} // Asigna la referencia al input
                                                        />
                                                        {(editableIndex === index && (user.estudiante !== 1 && (user.id_docente === materia.id_docente || user.id_rol))) ? (
                                                            <>
                                                            <button 
                                                                type="submit" 
                                                                className="btn btn-sm btn-success input-group-prepend"
                                                            >
                                                                <i className="fa fa-save text-dark"></i>
                                                            </button>
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-sm btn-danger input-group-prepend"
                                                                onClick={handleCancelEdit}
                                                            >
                                                                <i className="fa fa-times"></i>
                                                            </button>
                                                            </>
                                                        ) : (
                                                            (user.estudiante !== 1 && user.id_docente === materia.id_docente || user.id_rol) && (
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-sm btn-primary input-group-prepend"
                                                                onClick={() => handleEditClick(index)}
                                                            >
                                                                <i className="fa fa-edit"></i>
                                                            </button>
                                                            )
                                                        )}
                                                    </div>
                                                    <div className="input-group" style={{ width: '200px' }}>
                                                        <textarea
                                                            value={editableIndex === index ? comentarios[index] : estudiante.comentario || ''}
                                                            disabled={editableIndex !== index} 
                                                            className="form-control"
                                                            rows="1" // Comienza con 1 fila
                                                            style={{ resize: 'none', overflow: 'hidden' }} // Evita el redimensionamiento manual
                                                            onInput={(e) => {
                                                                e.target.style.height = 'auto'; // Resetea la altura para recalcularla
                                                                e.target.style.height = `${e.target.scrollHeight}px`; // Ajusta la altura al contenido
                                                                handleComentarioChange(e, index); // Maneja el cambio en el comentario
                                                            }}
                                                            maxLength={250}
                                                        />
                                                    </div>
                                                </form>
                                            </td>
                                            {actividad.entregas === 1 && (
                                                <td className="text-left">
                                                    {estudiante.entrega ? (
                                                        <>
                                                            <button 
                                                                className="btn btn-primary"
                                                                onClick={() => handleToggleExpand(index, estudiante.id_entrega)} // Usar el ID de la entrega
                                                            >
                                                                Ver Entrega
                                                            </button>
                                                            {user.estudiante === 1 && ( // Verifica si el usuario es estudiante
                                                                <button 
                                                                    className="btn btn-success" 
                                                                    onClick={() => handdleEntregaToggle(index)}
                                                                >
                                                                    Editar
                                                                </button>
                                                            )}
                                                        </>
                                                    ) : (
                                                        user.estudiante === 1 && isWithinDateRange(actividad.fecha_inicio, actividad.fecha_fin) ? (
                                                            <button 
                                                                className="btn btn-success" 
                                                                onClick={() => handdleEntregaToggle(index)}
                                                            >
                                                                Añadir
                                                            </button>
                                                        ) : (
                                                            <span className="text-danger">Pendiente</span>
                                                        )
                                                    )}
                                                </td>
                                            )}
                                        </tr>
                                        {expandedRow === index && (
                                            <tr>
                                            <td colSpan={4}>
                                                <div className="card">
                                                    <div className="card-body container">
                                                        <h5 className="card-title">
                                                            Entrega Realizada: {formattedDate(entrega.created_at)}
                                                            {entrega.created_at !== entrega.updated_at && (
                                                                <span>
                                                                    {' (Actualizada: ' + formattedDate(entrega.updated_at) + ')'}
                                                                </span>
                                                            )}
                                                        </h5>

                                                        <div>
                                                            {entrega_firmada && (
                                                                <FileViewer
                                                                    fileName={entrega.archivo}
                                                                    filePath={entrega_firmada}
                                                                />
                                                            )}
                                                        </div>
                                                        <p dangerouslySetInnerHTML={{ __html: entrega.descripcion }}></p>
                                                        {/* 
                                                        <div class="mt-3">
                                                            <form>                
                                                                <div class="mb-3">
                                                                    <label for="calificacion">Calificación</label>
                                                                    <input 
                                                                        type="text" 
                                                                        className="form-control"
                                                                    />
                                                                </div>     
                                                                <div>
                                                                    <label for="descripcion form-label">Comentario</label>
                                                                    <div class="mb-3 form-floating">
                                                                        <textarea class="form-control" name="comentario" maxlength="250" disabled=""></textarea>
                                                                    </div>
                                                                </div>
                                                                <button 
                                                                    type="submit" 
                                                                    className="btn btn-sm btn-success input-group-prepend text-dark"
                                                                >
                                                                    <i className="fa fa-save"></i> Guardar
                                                                </button>
                                                            </form>
                                                        </div>
                                                        */}
                                                    </div>
                                                </div>
                                                <style>
                                                    {`
                                                        .entrega-card img {
                                                            border-radius: 0 !important;
                                                            width: 1000px;
                                                            height: 1000px;
                                                        }
                                                        .table td img {
                                                            border-radius: 0 !important;
                                                            width: 350px;
                                                            height: 350px;
                                                        }
                                                    `}
                                                </style>
                                            </td>
                                        </tr>
                                        
                                        )}
                                        {entregaRow === index && (
                                            <tr>
                                            <td colSpan={4}>
                                                <Entrega idActividad={actividad.id} foro={actividad.foro} onEntregaSuccess={handleEntregaSuccess} />
                                            </td>
                                        </tr>
                                        )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default Show;
