import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useApiContext } from '../layout/Template';
import { useNavigate } from 'react-router-dom';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import ParcialesCurso from './ParcialesCurso';

const ParcialCurso = () => {
    const { cursoId, subcicloId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user } = useApiContext();
    const [isLoading, setIsLoading] = useState(false);
    
    const [parcial, setParcial] = useState(null);
    const [curso, setCurso] = useState(null);
    const [titulo, setTitulo] = useState([]);
    const [estudiantes, setEstudiantes] = useState([]);
    const [conductas, setConductas] = useState([]);
    const [ciclos, setCiclos] = useState([]);
    const [subciclos, setSubciclos] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${apiUrl}/comportamiento/curso/${cursoId}/${subcicloId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            const data = response.data;
            setParcial(data.parcial);
            setCurso(data.curso);
            setTitulo(data.titulo);
            setEstudiantes(Array.isArray(data.estudiantes) ? data.estudiantes : Object.values(data.estudiantes));
            // Convertir `data.conductas` a un array si no es uno
            const conductasArray = Array.isArray(data.conductas) ? data.conductas : Object.values(data.conductas);
            setConductas(conductasArray.reduce((acc, conducta) => {
                acc[conducta.id_estudiante] = conducta;
                return acc;
            }, {}));
            setCiclos(data.ciclos);
            setSubciclos(data.subciclos);
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    //navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSelectChange = (e, estudianteId) => {
        const nuevaConducta = e.target.value;
    
        // Actualiza el estado de conductas
        setConductas(prevConductas => ({
            ...prevConductas,
            [estudianteId]: {
                ...prevConductas[estudianteId],
                conducta: nuevaConducta
            }
        }));
    };

    useEffect(() => {
        fetchData();
    }, [cursoId, subcicloId]);
    
    const guardarConducta = async () => {
        // Mostrar el popup de carga
        window.showLoaderPopup();
        // Crear un objeto para almacenar los datos
        const conductasData = [];
    
        // Recorre la tabla para recoger los datos
        document.querySelectorAll('tbody tr').forEach(row => {
            const idEstudiante = row.getAttribute('data-id'); // Obtén el id del estudiante
            const select = row.querySelector('select');
            const conducta = select ? select.value : 'S'; // Obtén el valor seleccionado o 'S' si no hay selección
            
            // Agrega los datos al array
            conductasData.push({ id_estudiante: idEstudiante, conducta });
        });

        try {
            // Enviar los datos a la API
            const response = await axios.post(`${apiUrl}/comportamiento/curso/store/${cursoId}/${subcicloId}`, 
                { conductas: conductasData },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                }
            );
            window.hideLoaderPopup();
            window.showSuccessPopup("Registros actualizados.");
        } catch (err) {
            // Manejo de errores
            console.error('Error al guardar las conductas:', err);
            alert('Error al guardar las conductas.');
        }
    
    };

    if (loading) {
        return <Spinner />;
    }
    
    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start mb-2">
                <div>
                    <h2><strong>{titulo.descripcion}</strong></h2>
                </div>
                <div>
                    <div className="btn-group mb-2" role="group" aria-label="Basic outlined example">
                        <Link to={`/MisCursos/Info/${cursoId}`} className="btn btn-warning btn-sm"><i className="fas fa-chevron-circle-left"></i> <span className="d-none d-lg-inline"> Volver</span></Link>
                    </div>
                </div>
            </div>
            <div className="card col-lg-6 offset-lg-3">
                <div className="card-body">
                    <div className="d-sm-flex justify-content-between align-items-start mb-2">
                        <div>
                            <h2><strong>{titulo.subtitulo}</strong></h2>
                            <ParcialesCurso ciclos={ciclos} subciclos={subciclos} cursoId={cursoId} />
                        </div>
                        <div>
                            <div className="btn-group" role="group">
                                <button type="button" className="btn btn-success" onClick={guardarConducta}>
                                    <i className="fas fa-save"></i> <span className="d-none d-lg-inline"> Guardar Conductas</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th className='fixed-column' style={{ backgroundColor: 'white' }}>
                                        Estudiantes
                                    </th>
                                    <th>
                                        Cumple con: Autoconocimiento, Pensamiento Crítico, <br/>
                                        Toma de Decisiones, Habilidad Social y Emocional
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {estudiantes.map((estudiante) => {
                                    const conducta = conductas[estudiante.id] || {};

                                    return (
                                        <tr key={estudiante.id} data-id={estudiante.id}>
                                            <td className='fixed-column' style={{ backgroundColor: 'white', width: '250px' }}>
                                                {estudiante.estudiante.nombres}
                                            </td>
                                            <td>
                                                <select
                                                    className="form-select"
                                                    value={conducta.conducta || 'S'}
                                                    onChange={(e) => handleSelectChange(e, estudiante.id)}
                                                >
                                                    <option value="S">S - Siempre</option>
                                                    <option value="F">F - Frecuentemente</option>
                                                    <option value="O">O - Ocasional</option>
                                                    <option value="N">N - Nunca</option>
                                                </select>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ParcialCurso;
