import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { useNavigate, useParams , Link } from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';
import moment from 'moment';
import 'moment/locale/es';
import Swal from 'sweetalert2';

const Cuotas = ({ apiUrl, accessToken, periodoId, meses, getPeriodo }) => {
    const [errors, setErrors] = useState({}); // Estado para errores de validación
    const formatDate = (date) => moment(date).format('DD MMM YYYY');

    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingMesId, setEditingMesId] = useState(null);
    const [newMes, setNewMes] = useState({
        id_mes: '',
        nombre: '',
        alias: '',
        valor: '',
        fecha: '',
        vencimiento: '',
        orden: '',
        personalizado: false
    });

    const toggleFormVisibility = () => {
        setNewMes({
            id_mes: '',
            nombre: '',
            alias: '',
            valor: '',
            fecha: '',
            vencimiento: '',
            orden: '',
            personalizado: false 
        });
        setIsFormVisible(!isFormVisible);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewMes({ ...newMes, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const method = isEditing ? 'put' : 'post';
        const url = isEditing ? `${apiUrl}/periodos/meses/${editingMesId}` : `${apiUrl}/periodos/meses/${periodoId}`;
    
        console.log(newMes.personalizad)
        try {
            const response = await axios[method](url, {
                id_periodo: periodoId,
                id_mes: newMes.id_mes,
                nombre: newMes.nombre,
                alias: newMes.alias,
                valor: newMes.valor,
                fecha: newMes.fecha,
                vencimiento: newMes.vencimiento,
                orden: newMes.orden,
                personalizado: newMes.personalizado ? 1 : 0, 
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                }
            });
            // Aquí es donde actualizarías la lista de meses, pero lo hemos eliminado
    
            // Limpiar el formulario y ocultarlo después de enviar
            setNewMes({
                id_mes: '',
                nombre: '',
                alias: '',
                valor: '',
                fecha: '',
                vencimiento: '',
                orden: '',
                personalizado: false 
            });
            setIsFormVisible(false);
            setIsEditing(false);
            getPeriodo();
            window.showSuccessPopup(response.data.message);
    
        } catch (error) {
            // Manejar errores de validación
            const errorResponse = error.response?.data || {};
            setErrors(errorResponse.errors || {});
            console.error('Error al guardar el mes:', errorResponse.message || error.message);
            
            // Mostrar el mensaje de error en el popup
            const errorMessage = errorResponse.message || 'Ocurrió un error al guardar el mes.';
            window.showErrorPopup(errorMessage);
    
            // Mantener el formulario visible si hay errores
            setIsFormVisible(true);
        }
    };

    const handleClose = () => {
        setNewMes({
            id_mes: '',
            nombre: '',
            alias: '',
            valor: '',
            fecha: '',
            vencimiento: '',
            orden: '',
            personalizado: false 
        });
        setIsFormVisible(false);
        setIsEditing(false);
    };

    const handleEditClick = (mes) => {
        // Convertir datetime a date
        const formattedFecha = mes.fecha.slice(0, 10);
        const formattedVencimiento = mes.vencimiento.slice(0, 10);
    
        setNewMes({
            nombre: mes.nombre,
            alias: mes.alias,
            valor: mes.valor,
            fecha: formattedFecha,
            vencimiento: formattedVencimiento,
            orden: mes.orden,
            personalizado: mes.personalizado === 1
        });
        setEditingMesId(mes.id);
        setIsFormVisible(true);
        setIsEditing(true);
    };

    // Array de meses con IDs
    const mesesConNombre = [
        { nombre: 'Enero' },
        { nombre: 'Febrero' },
        { nombre: 'Marzo' },
        { nombre: 'Abril' },
        { nombre: 'Mayo' },
        { nombre: 'Junio' },
        { nombre: 'Julio' },
        { nombre: 'Agosto' },
        { nombre: 'Septiembre' },
        { nombre: 'Octubre' },
        { nombre: 'Noviembre' },
        { nombre: 'Diciembre' }
    ];

    const handleMesChange = (e) => {
        setNewMes({ ...newMes, nombre: e.target.value });
    };

    // Manejar el cambio en el checkbox
    const handleCheckboxChange = (e) => {
        setNewMes({ ...newMes, personalizado: e.target.checked });
    };

    // Manejar el cambio en el campo alias
    const handleAliasChange = (e) => {
        setNewMes({ ...newMes, alias: e.target.value });
    };

    // Manejar el cambio en el campo alias
    const handleValorChange = (e) => {
        setNewMes({ ...newMes, valor: e.target.value });
    };
    
    const handleDelete = async (id) => {
        // Mostrar el cuadro de confirmación
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás recuperar esta cuota después de eliminarla!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        });
    
        // Si el usuario confirma la eliminación
        if (result.isConfirmed) {
            try {
                await axios.delete(`${apiUrl}/periodos/meses/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    },
                });
                getPeriodo();
                Swal.fire({
                    icon: 'success',
                    title: 'Eliminado',
                    text: 'La cuota se ha sido eliminado correctamente.',
                });
            } catch (error) {
                console.error('Error deleting cuota:', error.response ? error.response.data : error.message);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo eliminar la cuota. Por favor, inténtelo de nuevo.',
                });
            }
        }
    };

    return (
        <div>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h3 className="">Proyección Cobranzas</h3>
                </div>
                <div>
                    <button onClick={toggleFormVisibility} className="btn btn-primary">
                        Añadir Nuevo Mes
                    </button>
                </div>
            </div>

            {isFormVisible && (
                <div className="card mt-3">
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>

                            {!isEditing && (
                                <div className="mb-3">
                                    <input
                                        type="checkbox"
                                        id="personalizado"
                                        name="personalizado"
                                        checked={newMes.personalizado}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor="personalizado" className="ms-2">Personalizado</label>
                                </div>
                            )}

                            {!newMes.personalizado && (
                                <div className="mb-3">
                                    <label htmlFor="id_mes">Seleccionar Mes</label>
                                    <select
                                        id="nombre_mes"
                                        name="nombre_mes"
                                        value={newMes.nombre || ""} // Asegúrate de que haya un valor predeterminado si es undefined
                                        onChange={handleMesChange}
                                        disabled={isEditing}
                                        className={`form-control form-select ${errors.nombre ? 'is-invalid' : ''}`}
                                        style={{
                                            color: '#000',  // Color del texto
                                            backgroundColor: '#fff',  // Color de fondo
                                            borderColor: '#ced4da',  // Color del borde
                                        }}
                                    >
                                        <option value="" disabled>Seleccione un mes</option>
                                        {mesesConNombre.map((mes, index) => (
                                            <option key={index} value={mes.nombre}>
                                                {mes.nombre}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.nombre && <div className="invalid-feedback">{errors.nombre[0]}</div>}
                                </div>
                            )}

                            {(isEditing ? newMes.personalizado : newMes.personalizado) && (
                                <>
                                    <div className='row'>
                                        <div className="col-4 mb-3">
                                            <label htmlFor="nombre">Nombre</label>
                                            <input
                                                value={newMes.nombre}
                                                onChange={(e) => setNewMes({ ...newMes, nombre: e.target.value })}
                                                type="text"
                                                className={`form-control ${errors.nombre ? 'is-invalid' : ''}`}
                                                id="nombre"
                                                name="nombre"
                                                placeholder="Nombre"
                                                maxLength={10}
                                            />
                                            {errors.nombre && <div className="invalid-feedback">{errors.nombre[0]}</div>}
                                        </div>

                                        <div className="col-4 mb-3">
                                            <label htmlFor="alias">Alias</label>
                                            <input
                                                type="text"
                                                id="alias"
                                                name="alias"
                                                value={newMes.alias}
                                                onChange={handleAliasChange}
                                                className={`form-control ${errors.alias ? 'is-invalid' : ''}`}
                                                maxLength={4}
                                            />
                                            {errors.alias && <div className="invalid-feedback">{errors.alias[0]}</div>}
                                        </div>

                                        <div className="col-4 mb-3">
                                            <label htmlFor="valor">Valor</label>
                                            <input
                                                type="text"
                                                id="valor"
                                                name="valor"
                                                value={newMes.valor}
                                                onChange={handleValorChange}
                                                className={`form-control ${errors.valor ? 'is-invalid' : ''}`}
                                            />
                                            {errors.valor && <div className="invalid-feedback">{errors.valor[0]}</div>}
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className='row'>
                                <div className="col-6 mb-3">
                                    <label htmlFor="fecha">Fecha</label>
                                    <input
                                        value={newMes.fecha}
                                        onChange={(e) => setNewMes({ ...newMes, fecha: e.target.value })}
                                        type="date"
                                        className={`form-control ${errors.fecha ? 'is-invalid' : ''}`}
                                        id="fecha"
                                        name="fecha"
                                    />
                                    {errors.fecha && <div className="invalid-feedback">{errors.fecha[0]}</div>}
                                </div>

                                <div className="col-6 mb-3">
                                    <label htmlFor="vencimiento">Vencimiento</label>
                                    <input
                                        value={newMes.vencimiento}
                                        onChange={(e) => setNewMes({ ...newMes, vencimiento: e.target.value })}
                                        type="date"
                                        className={`form-control ${errors.vencimiento ? 'is-invalid' : ''}`}
                                        id="vencimiento"
                                        name="vencimiento"
                                    />
                                    {errors.vencimiento && <div className="invalid-feedback">{errors.vencimiento[0]}</div>}
                                </div>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="orden">Orden</label>
                                <input
                                    value={newMes.orden}
                                    onChange={(e) => setNewMes({ ...newMes, orden: e.target.value })}
                                    type="number"
                                    className={`form-control ${errors.orden ? 'is-invalid' : ''}`}
                                    id="orden"
                                    name="orden"
                                    placeholder="Orden"
                                />
                                {errors.orden && <div className="invalid-feedback">{errors.orden[0]}</div>}
                            </div>

                            <button type="submit" className="btn btn-primary mt-2">
                                {isEditing ? 'Actualizar' : 'Añadir'}
                            </button>
                            <button type="button" onClick={handleClose} className="btn btn-danger mt-2 ml-2">
                                Cancelar
                            </button>
                        </form>
                    </div>
                </div>
            )}

            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                        <tr>
                        <th>Mes</th>
                        <th>Corte</th>
                        <th>Vence</th>
                        <th># Pensión</th>
                        <th><i className='fas fa-cogs'></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        {meses.map((mes) => (
                            <tr key={mes.id}>
                                <td>{mes.nombre} - {mes.alias}</td>
                                <td>{formatDate(mes.fecha)}</td>
                                <td>{formatDate(mes.vencimiento)}</td>
                                <td>Pension #{mes.orden}</td>
                                <td>
                                    <div className="dropdown">
                                        <button
                                            className="btn btn-secondary"
                                            type="button"
                                            id={`dropdownMenuButton-${mes.id}`}
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <i className="fas fa-cogs"></i>
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${mes.id}`}>
                                            <li>
                                                <a 
                                                    className="dropdown-item"
                                                    href="#"
                                                    onClick={() => handleEditClick(mes)}
                                                >
                                                    Editar
                                                </a>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="#" onClick={()=>handleDelete(mes.id)}>Eliminar</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Cuotas;
