import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { useNavigate, useParams , Link } from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';
import FormContent from './FormContent';
import useFetchParametros from './useFetchParametros'

const Edit = () => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const parametros = useFetchParametros(apiUrl, accessToken);
    const [formData, setFormData] = useState({
        id_periodo: '',
        id_nivel: '',
        id_seccion: '',
        id_tipo: '',
        id_modalidad: '',
        id_jornada: '',
        id_especialidad: '',
        id_tutor: '',
        matricula: '',
        cuota: '',
        estado: '',
        inicial: '',
    });
    const [errors, setErrors] = useState({}); // Estado para errores de validación
    const navigate = useNavigate();
    const {id} = useParams();
    useEffect(() => {
        const getCurso = async () => {
            try {
                const response = await axios.get(`${apiUrl}/cursos/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    }
                });
                // Convertir los datos del backend al formato esperado
                const { curso, id_periodo, id_nivel, id_seccion, id_tipo, id_modalidad, id_jornada, id_especialidad, id_tutor, matricula, cuota, estado, inicial } = response.data;
                setFormData({
                    id_periodo: id_periodo,
                    id_nivel: id_nivel,
                    id_seccion: id_seccion,
                    id_tipo: id_tipo,
                    id_modalidad: id_modalidad,
                    id_jornada: id_jornada,
                    id_especialidad: id_especialidad,
                    id_tutor: id_tutor,
                    matricula: matricula,
                    cuota: cuota,
                    estado: estado,
                    inicial: inicial
                });
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener el curso:', error.response ? error.response.data : error.message);
                setLoading(false); // Desactivar el cargador en caso de error
            }
        };
        getCurso();
    }, [apiUrl, accessToken, id]);
    
    const update = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.put(`${apiUrl}/cursos/${id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            navigate('/cursos');  // Redirigir a la lista de cursos
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            if (error.response) {
                // Manejar errores de validación
                setErrors(error.response.data.errors);
            } else {
                console.error('Error al actualizar:', error.response ? error.response.data : error.message);
            }
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Actualizar Curso</h2>
                </div>
            </div>
            <div className="col-md-6 offset-md-3 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={update}>
                            <FormContent
                                formData={formData}
                                setFormData={setFormData}
                                periodos={parametros.periodos}
                                niveles={parametros.niveles}
                                secciones={parametros.secciones}
                                tipos={parametros.tipos}
                                modalidades={parametros.modalidades}
                                especialidades={parametros.especialidades}
                                jornadas={parametros.jornadas}
                                docentes={parametros.docentes}
                                isEdit={true}
                                errors={errors}
                            />
                            <button type="submit" className="btn btn-primary me-2"><i className="fas fa-save"></i> Actualizar Curso</button>
                            <Link className="btn btn-warning" to='/cursos'>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Edit;
