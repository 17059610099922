// Función de validación y formateo
export const validateAndFormatValue = (e, handleNotaChange, index) => {
    const value = e.target.value;

    // Expresión regular para permitir números con hasta dos decimales
    const regex = /^(\d{0,2})(\.\d{0,2})?$/;
    const match = value.match(regex);

    // Si no hay coincidencia, usar una cadena vacía
    const cleanValue = match ? match[0] : '';

    // Convertir el valor a número
    const numericValue = parseFloat(cleanValue);

    if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 10) {
        // Actualizar el valor del input si es válido
        e.target.value = cleanValue;
        // Llamar a handleNotaChange con el valor validado
        handleNotaChange(index, cleanValue);
    } else {
        // Mostrar mensaje de error
        //window.showErrorPopup('Calificación no válida. Debe ser un número entre 0 y 10 con hasta dos decimales.');
        // Restablecer el valor del input si no es válido
        e.target.value = '';
        // Llamar a handleNotaChange con un valor vacío si no es válido
        handleNotaChange(index, '');
    }
};
