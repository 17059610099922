import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useApiContext } from '../layout/Template';
import Spinner from '../widget/Spinner';
import DatosGeneralesContext from '../widget/DatosGeneralesContext';
import DatosEstudiante from './DatosEstudiante';
import DatosFicha from './DatosFicha';

const Edit = () => {
    const { apiUrl, accessToken } = useApiContext();
    const { id } = useParams(); // Obtén el ID del parámetro de la URL
    const [formData, setFormData] = useState({
        estudiante: {
            identificacion: '',
            extranjero: false,
            nombre: '',
            apellido: '',
            correo: '',
            id_representante: '',
            celular: '',
            telefono: '',
            direccion: '',
            sexo: '',
            id_canton: '',
            id_nacionalidad: '',
        },
        ficha: {
            parentezco1: '',
            nombre_contacto1: '',
            celular_contacto1: '',
            domicilio_contacto1: '',
            dir_trabajo_contacto1: '',
            
            parentezco2: '',
            nombre_contacto2: '',
            celular_contacto2: '',
            domicilio_contacto2: '',
            dir_trabajo_contacto2: '',
        }
    });
    const [representantes, setRepresentantes] = useState([]);
    const [cursos, setCursos] = useState([]);
    const [usuario, setUsuario] = useState(null);
    const [estudiante, setEstudiante] = useState(null);

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const { cantones, paises } = DatosGeneralesContext(apiUrl);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/estudiantes/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    }
                });

                const estudianteData = response.data.estudiante;

                setFormData({
                    estudiante: {
                        identificacion: estudianteData.identificacion || '',
                        extranjero: estudianteData.extranjero || false,
                        nombre: estudianteData.nombre || '',
                        apellido: estudianteData.apellido || '',
                        id_representante: estudianteData.id_representante || '',
                        correo: estudianteData.correo || '',
                        celular: estudianteData.celular || '',
                        telefono: estudianteData.telefono || '',
                        direccion: estudianteData.direccion || '',
                        sexo: estudianteData.sexo || '',
                        id_canton: estudianteData.id_canton || '',
                        id_nacionalidad: estudianteData.id_nacionalidad || '',
                    },
                    ficha: {
                        parentezco1: estudianteData.parentezco1 || '',
                        nombre_contacto1: estudianteData.nombre_contacto1 || '',
                        celular_contacto1: estudianteData.celular_contacto1 || '',
                        domicilio_contacto1: estudianteData.domicilio_contacto1 || '',
                        dir_trabajo_contacto1: estudianteData.dir_trabajo_contacto1 || '',
                        
                        parentezco2: estudianteData.parentezco2 || '',
                        nombre_contacto2: estudianteData.nombre_contacto2 || '',
                        celular_contacto2: estudianteData.celular_contacto2 || '',
                        domicilio_contacto2: estudianteData.domicilio_contacto2 || '',
                        dir_trabajo_contacto2: estudianteData.dir_trabajo_contacto2 || '',
                    }
                });
                setRepresentantes(response.data.representantes);
                setUsuario(response.data.usuario);
                setEstudiante(response.data.estudiante);
                setCursos(response.data.cursos);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener los datos para editar:', error.response ? error.response.data : error.message);
                setLoading(false);
            }
        };

        fetchData();
    }, [id, apiUrl, accessToken]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.put(`${apiUrl}/estudiantes/${id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            console.log('Estudiante actualizado exitosamente:', response.data);
            navigate('/estudiantes');
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            if (error.response) {
                setErrors(error.response.data.errors);
            } else {
                console.error('Error al actualizar el estudiante:', error.response ? error.response.data : error.message);
            }
        }
    };

    const reestablecerPwd = async (idUsuario) => {
        try {
            const response = await axios.post(`${apiUrl}/restorePwd/${idUsuario}`, {}, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
    
            console.log('Contraseña restablecida exitosamente:', response.data);
            // Si deseas redirigir o realizar alguna acción adicional
            // navigate('/algun/ruta');  // Redirecciona a una página específica
            window.showSuccessPopup(response.data.message); // Muestra un popup con éxito
        } catch (error) {
            window.showErrorPopup("Ocurrio un error"); // Muestra un popup con éxito
        }
    };
    

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Editar Estudiante</h2>
                </div>
            </div>

            <div className='row'>
                <div className="col-lg-8 col-md-6 col-sm-12 grid-margin stretch-card">
                    <div className='card'>
                        <div className="card-body py-3">
                            <h4 className="card-title">Persona Asociada</h4>
                            <div className='row'>
                                <div className='col-lg-4 col-md-6'>
                                    <label>Nombres</label>
                                    <input
                                        value={estudiante.representante.nombres}
                                        className={`form-control`}
                                        disabled
                                    />
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <label>Identificacion</label>
                                    <input
                                        value={estudiante.representante.identificacion}
                                        className={`form-control`}
                                        disabled
                                    />
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <label>Correo</label>
                                    <input
                                        value={estudiante.representante.correo}
                                        className={`form-control`}
                                        disabled
                                    />
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <label>Celular</label>
                                    <input
                                        value={estudiante.representante.celular}
                                        className={`form-control`}
                                        disabled
                                    />
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <label>Telefono</label>
                                    <input
                                        value={estudiante.representante.telefono}
                                        className={`form-control`}
                                        disabled
                                    />
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <label>Dirección</label>
                                    <input
                                        value={estudiante.representante.direccion}
                                        className={`form-control`}
                                        disabled
                                    />
                                </div>
                            </div>
                            <Link className='btn btn-primary mt-2' to={`/personas/${estudiante.representante.id}`}>Ver</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 grid-margin stretch-card">
                    <div className='card'>
                        <div className="card-body py-3">
                            <h4 className="card-title">Cursos Inscritos</h4>
                            <ul>
                                {cursos.length > 0 ? (
                                    cursos.map((curso, index) => (
                                        <li key={index}>
                                            <Link className='text-decoration-none fs-5' to={`/MisCursos/Info/${curso.id}`}>
                                                {curso.periodo.descripcion} - {curso.nivel.nombre_corto} / {curso.seccion.descripcion} {curso.id_especialidad ? curso.especialidad.descripcion : ''} {curso.jornada.descripcion} {curso.modalidad.descripcion}
                                            </Link>
                                        </li>
                                    ))
                                ) : (
                                    <li>No hay cursos inscritos</li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
            <form onSubmit={handleSubmit}>
                <div className='row'>
                    <DatosEstudiante
                        formData={formData}
                        setFormData={setFormData}
                        paises={paises}
                        cantones={cantones}
                        representantes={representantes}
                        errors={errors}
                    />
                    <DatosFicha
                        formData={formData}
                        setFormData={setFormData}
                        errors={errors}
                    />
                </div>
                <button type="submit" className="btn btn-primary me-2"><i className="fas fa-save"></i> Guardar Cambios</button>
                {usuario && (
                    <>
                        <Link to='#' className="btn btn-info me-2" onClick={() => reestablecerPwd(usuario.id)}>
                            <i className="fas fa-lock"></i> Reestablecer Contraseña
                        </Link>
                    </>
                )}
                <Link className="btn btn-warning" to='/estudiantes'>Volver</Link>
            </form>
        </div>
    );
};

export default Edit;
