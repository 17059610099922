// src/routes/ContenidoRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../componentes/auth/ProtectedRoute';
import Template from '../componentes/client/layout/Template';

// secciones
import Index from '../componentes/client/contenido/Contenido';

const ContenidoRoutes = () => {
    return (
        <Routes>
            <Route
                path=":materiaId/:parcialId"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Index />
                        </Template>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default ContenidoRoutes;
