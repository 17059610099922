// src/routes/AsistenciaMateriaRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../componentes/auth/ProtectedRoute';
import Template from '../componentes/client/layout/Template';

// secciones
import Index from '../componentes/client/asistencias/ParcialMateria';

const AsistenciaMateriaRoutes = () => {
    return (
        <Routes>
            <Route
                path=":materiaId/:subcicloId"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Index />
                        </Template>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default AsistenciaMateriaRoutes;
