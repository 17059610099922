// src/routes/PeriodosRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../componentes/auth/ProtectedRoute';
import Template from '../componentes/client/layout/Template';

// periodos
import Index from '../componentes/client/periodos/Index';
import Create from '../componentes/client/periodos/Create';
import Edit from '../componentes/client/periodos/Edit';
import Show from '../componentes/client/periodos/Show';

const PeriodosRoutes = () => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <ProtectedRoute>
                        <Template>
                            <Index />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="create"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Create />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path=":periodoId"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Show />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="edit/:periodoId"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Edit />
                        </Template>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default PeriodosRoutes;
