import React, { useState, useEffect, createContext, useContext } from 'react';
//import AppSidebar from './AppSidebar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import NotificationComponent from '../widget/NotificationComponent'; 
import NotificationDropdown from '../widget/NotificationDropdown';
import ChangePasswordModal from '../widget/ChangePasswordModal';
import useAxiosInterceptor  from '../widget/useAxiosInterceptor';
import { ToastContainer } from 'react-toastify';
import userImage from '../../../assets/img/user_profile.png'; 
import logoSqr from '../../../assets/img/edusnap/logo_sqr_comprimido.png'; 
import logoRect from '../../../assets/img/edusnap/logo_hotizontal.png'; 
//import '../../../assets/js/template.js';
import $ from 'jquery';
import Swal from 'sweetalert2';

// Crear el contexto con apiUrl y accessToken
const ApiContext = createContext();

export const useApiContext = () => {
    return useContext(ApiContext);
}

const openInNewTab = (url) => {
    var a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.rel = 'noopener noreferrer'; // Seguridad adicional
    a.style.display = 'none'; // Asegurarse de que el enlace no sea visible en el DOM
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
const handleLinkClick = () => {
    openInNewTab('https://drive.google.com/drive/folders/1XlJFPOpmkSZVjydRxZULEp7F8CHxLp7z?usp=drive_link');
};

const Template = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    
    const hostname = window.location.hostname;
    const subdominio = hostname.split('.')[0];
    const tenantUrl = process.env.REACT_APP_API_TENANT_BASE_URL.replace('tenant', subdominio);

    const apiUrl = tenantUrl;

    useAxiosInterceptor();
    
    const logoutEndpoint = `${apiUrl}/logout`;
    const accessToken = localStorage.getItem('accessToken');

    const verifyTokenOnMount = async () => {
        if (!accessToken) {
            navigate('/Login');
            return;
        }

        try {
            // Realizar una solicitud a la ruta de verificación del token
            const response = await axios.get(`${apiUrl}/check-token`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.data.valid) {
                throw new Error('Token no válido');
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Token inválido o sesión expirada
                console.error('El token es inválido o ha expirado.');
                localStorage.removeItem('accessToken');
                localStorage.removeItem('user');
                navigate('/Login');
            } else {
                console.error('Error al verificar el token:', error.response ? error.response.data : error.message);
            }
        }
    };

    useEffect(() => {
        verifyTokenOnMount();
    }, []);

    const handleSidebarToggle = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    // Función para mostrar alertas de error
    const showErrorPopup = (message) => {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            confirmButtonText: 'Aceptar',
            text: message,
            customClass: {
                popup: 'modal-content modal-sm', // Clase de Bootstrap para el contenido del modal
                title: 'modal-title',     // Clase de Bootstrap para el título del modal
                content: 'modal-body',    // Clase de Bootstrap para el cuerpo del modal
                confirmButton: 'btn btn-danger'  // Clase de Bootstrap para el botón de aceptar
            },
        });
    };

    // Función para mostrar alertas de éxito
    const showSuccessPopup = (message) => {
        Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: message,
            confirmButtonText: 'Aceptar',
            customClass: {
                popup: 'modal-content modal-sm', // Clase de Bootstrap para el contenido del modal
                title: 'modal-title',     // Clase de Bootstrap para el título del modal
                content: 'modal-body',    // Clase de Bootstrap para el cuerpo del modal
                confirmButton: 'btn btn-primary'  // Clase de Bootstrap para el botón de aceptar
            },
        });
    };
    
    const showLoaderPopup = () => {
        Swal.fire({
            icon: 'info',
            title: 'Cargando...',
            text: 'Por favor, espere.',
            allowOutsideClick: false,
            onOpen: () => {
                Swal.showLoading();
            },
            customClass: {
                popup: 'modal-content modal-sm', // Clase de Bootstrap para el contenido del modal
                title: 'modal-title',     // Clase de Bootstrap para el título del modal
                content: 'modal-body',    // Clase de Bootstrap para el cuerpo del modal
                confirmButton: 'btn btn-primary'  // Clase de Bootstrap para el botón de aceptar
            }
        });
    };

    const hideLoaderPopup = () => {
        Swal.close();
    };

    useEffect(() => {
        window.showErrorPopup = showErrorPopup;
        window.showSuccessPopup = showSuccessPopup;
        window.showLoaderPopup = showLoaderPopup;
        window.hideLoaderPopup = hideLoaderPopup;
        // Agregar la clase sidebar-icon-only por defecto
        $('body').addClass('sidebar-icon-only');

        // Manejar eventos para cambiar las clases en el body
        $('[data-bs-toggle="minimize"], #sidebar').on("click mouseenter mouseleave", function(event) {
            const body = $('body');
            const logoEsquina = $('#logo_esquina');

            if (event.type === 'mouseenter') {
                // Al hacer mouseenter, aplicar la clase sidebar-absolute y ocultar el logo
                body.removeClass('sidebar-icon-only').addClass('sidebar-absolute');
                logoEsquina.hide();
            } else if (event.type === 'mouseleave') {
                // Al salir, revertir las clases y mostrar el logo
                body.removeClass('sidebar-absolute').addClass('sidebar-icon-only');
                logoEsquina.show();
            }
        });

        // Limpiar los event listeners al desmontar el componente
        return () => {
            $('[data-bs-toggle="minimize"], #sidebar').off("click mouseenter mouseleave");
        };
    }, []);

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            setUser(JSON.parse(userData));
        }
    }, []);
    
    const [showPwdModal, setShowPwdModal] = useState(false);

    const handleOpenPwdModal = () => {
        setShowPwdModal(true);
    };

    const handleClosePwdModal = () => {
        setShowPwdModal(false);
    };

    const logout = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                throw new Error('No hay token de acceso almacenado');
            }
            const response = await axios.post(logoutEndpoint, {}, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            console.log(response.data); // Verificar la respuesta
            localStorage.removeItem('accessToken');
            localStorage.removeItem('user'); // Eliminar los datos del usuario del localStorage
            // Redirigir a la página de inicio de sesión u otra página relevante
            navigate('/Login');
        } catch (error) {
            console.error('Error al cerrar sesión:', error.response ? error.response.data : error.message);
        }
    };
    
    return (
        <div className="container-scroller">
        <NotificationComponent subdominio={subdominio} user={user} />
        <ToastContainer />
            <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row" style={{ zIndex: '1029 !important'  }}>
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
                    <div className="me-3">
                        <div className="logo_esquina" id="logo_esquina">
                            <Link to="/dashboard" className="navbar-brand brand-logo">
                                <img src={logoSqr} className="hidden-on-mobile" alt="logo_edusnap3" width="30"/>
                            </Link>
                        </div>
                    </div>
                    <div>
                        <Link to="/dashboard" className="navbar-brand brand-logo">
                            <img src={logoRect} alt="logo_edusnap1"/>
                        </Link>
                        <Link to="/dashboard" className="navbar-brand brand-logo-mini">
                            <img src={logoSqr} alt="logo_edusnap2"/>
                        </Link>
                    </div>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-top">
                    <ul className="navbar-nav">
                        <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
                        </li>
                    </ul>
                    <ul className="navbar-nav ms-auto">
                        <NotificationDropdown  apiUrl={apiUrl} accessToken={accessToken}/>
                        <li className="nav-item">
                            <Link to="#" className="nav-link">
                                <i className="fas fa-cog"></i>
                            </Link>
                        </li>
                        <li className="nav-item dropdown d-lg-block user-dropdown">
                            <Link to="#" className="nav-link" id="UserDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <img className="img-xs rounded-circle" src={userImage} alt="Profile image1" /> 
                            </Link>
                            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">
                                {user ? (
                                    <>
                                        <div className="dropdown-header text-center">
                                            <img className="img-xs rounded-circle" src={userImage} alt="Profile image2" />
                                            <p className="mb-1 mt-3 font-weight-semibold">{user.name}</p>
                                            <p className="fw-light text-muted mb-0">{user.email}</p>
                                        </div>
                                        <Link to="#" className="dropdown-item" onClick={handleOpenPwdModal}>
                                            <i className="dropdown-item-icon fas fa-key text-primary me-2"></i> Cambiar Contraseña
                                        </Link>
                                        <Link to="#" onClick={logout}className="dropdown-item">
                                            <i className="dropdown-item-icon fas fa-power-off text-primary me-2"></i> Cerrar Sesión
                                        </Link>
                                    </>
                                ) : (
                                    <p>Cargando datos del usuario...</p>
                                )}
                            </div>
                        </li>
                    </ul>
                    <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={handleSidebarToggle}>
                        <span><i className="fas fa-bars"></i></span>
                    </button>

                </div>
            </nav>

            <ChangePasswordModal show={showPwdModal} handleClose={handleClosePwdModal} apiUrl={apiUrl} accessToken={accessToken} />
            <div className="container-fluid page-body-wrapper">
                <div className="theme-setting-wrapper">
                    <div id="settings-trigger" onClick={handleLinkClick}>
                        <i className="fab fa-youtube"></i>
                    </div>
                </div>
                <nav className={`sidebar sidebar-offcanvas ${sidebarOpen ? 'active' : ''}`} id="sidebar" style={{ overflow: 'auto' }}>
                    <ul className="nav">
                        <li className="nav-item">
                            <Link to="/dashboard" className="nav-link" onClick={closeSidebar}>
                                <i className="fas fa-tachometer-alt menu-icon"></i>
                                <span className="menu-title">Inicio</span>
                            </Link>
                        </li>
                        <li className="nav-item nav-category">Modulos</li>
                        
                        {user ? (
                            <>
                                {/* Mostrar menú si user.docente es 1 */}
                                {user.docente === 1 && (
                                    <>
                                    <li className="nav-item">
                                        <Link to="/MisCursos" className="nav-link" onClick={closeSidebar}>
                                            <i className="menu-icon fas fa-chalkboard"></i>
                                            <span className="menu-title">Mis Cursos</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/Tutorias" className="nav-link" onClick={closeSidebar}>
                                        <i className="menu-icon fas fa-chalkboard-teacher"></i>
                                            <span className="menu-title">Tutorias</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/MisMaterias" className="nav-link" onClick={closeSidebar}>
                                            <i className="menu-icon fas fa-book-reader"></i>
                                            <span className="menu-title">Mis Materias</span>
                                        </Link>
                                    </li>
                                    </>
                                )}
                            </>
                        ) : (
                            <p>Cargando..</p>
                        )}

                        
                        {user ? (
                            <>
                                {/* Mostrar menú si user.estudiante es 1 */}
                                {user.estudiante === 1 && (
                                    <>
                                    <li className="nav-item">
                                        <Link to="/MisCursos" className="nav-link" onClick={closeSidebar}>
                                        <i className="menu-icon fas fa-chalkboard-teacher"></i>
                                            <span className="menu-title">Mis Cursos</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/MisMaterias" className="nav-link" onClick={closeSidebar}>
                                            <i className="menu-icon fas fa-book-reader"></i>
                                            <span className="menu-title">Mis Materias</span>
                                        </Link>
                                    </li>
                                    </>
                                )}
                            </>
                        ) : (
                            <p>Cargando..</p>
                        )}
                        
                        {user ? (
                            <>
                                {/* Mostrar menú si id_rol no es nulo */}
                                {user.id_rol && (
                                    <>
                                    <li className="nav-item">
                                        <Link to="/periodos" className="nav-link" onClick={closeSidebar}>
                                            <i className="fas fa-calendar-alt menu-icon"></i>
                                            <span className="menu-title">Periodos</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="collapse" href="#side-menu1" aria-expanded="false" aria-controls="side-menu1">
                                            <i className="menu-icon fas fa-users"></i>
                                            <span className="menu-title"> Personas </span>
                                        </a>
                                        <div className="collapse" id="side-menu1">
                                            <ul className="nav flex-column sub-menu">
                                                <li className="nav-item">
                                                    <Link to="/personas" className="nav-link">
                                                        <span className="menu-title">Personas</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/estudiantes" className="nav-link">
                                                        <span className="menu-title">Estudiantes</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/docentes" className="nav-link">
                                                        <span className="menu-title">Docentes</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-bs-toggle="collapse" href="#side-menu2" aria-expanded="false" aria-controls="side-menu2">
                                            <i className="menu-icon fas fa-chalkboard-teacher"></i>
                                            <span className="menu-title"> Cursos </span>
                                        </a>
                                        <div className="collapse" id="side-menu2">
                                            <ul className="nav flex-column sub-menu">
                                                <li className="nav-item">
                                                    <Link to="/Cursos" className="nav-link">
                                                        <span className="menu-title">Cursos</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/niveles" className="nav-link">
                                                        <span className="menu-title">Niveles</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/modalidades" className="nav-link">
                                                        <span className="menu-title">Modalidades</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/especialidades" className="nav-link">
                                                        <span className="menu-title">Especialidades</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/jornadas" className="nav-link">
                                                        <span className="menu-title">Jornadas</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/secciones" className="nav-link">
                                                        <span className="menu-title">Secciones</span>
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/tipocurso" className="nav-link">
                                                        <span className="menu-title">Tipos</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    {/* 
                                    <li className="nav-item active">
                                        <a className="nav-link" data-bs-toggle="collapse" href="#side-menu3" aria-expanded="false" aria-controls="side-menu3">
                                            <i className="menu-icon fas fa-users"></i>
                                            <span className="menu-title"> Roles </span>
                                            <i className="menu-arrow"></i>*
                                        </a>
                                        <div className="collapse" id="side-menu3">
                                            <ul className="nav flex-column sub-menu">
                                                <li className="nav-item">
                                                    <Link className="nav-link active" to="#"> Cursos</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    */}
                                    </>
                                )}
                            </>
                        ) : (
                            <p>Cargando..</p>
                        )}
                    </ul>
                </nav>
                <ApiContext.Provider value={{ apiUrl, accessToken, user, subdominio }}>
                    <div className="main-panel">
                        {children}
                    </div>
                </ApiContext.Provider>
            </div>
        </div>
    );
}

export default Template; // Asegúrate de tener esta línea al final del archivo
