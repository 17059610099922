import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { useNavigate, useParams , Link } from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';

const Create = () => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [descripcion, setDescripcion] = useState('');
    const [desde, setDesde] = useState('');
    const [hasta, setHasta] = useState('');
    const [errors, setErrors] = useState({}); // Estado para errores de validación
    const navigate = useNavigate();
    const {periodoId} = useParams()

    useEffect( () =>{
        const getPeriodo = async () => {
            try {
                const response = await axios.get(`${apiUrl}/periodos/get/${periodoId}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    }
                });
                setDescripcion(response.data.descripcion);
                setDesde(response.data.desde);
                setHasta(response.data.hasta);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener el periodo:', error.response ? error.response.data : error.message);
            }
        };
        getPeriodo();
    }, [] );

    const update = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.put(`${apiUrl}/periodos/${periodoId}`, {
                descripcion: descripcion,
                desde: desde,
                hasta: hasta
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });

            navigate('/periodos');  // Redirigir a la lista de periodos
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            if (error.response) {
                // Manejar errores de validación
                setErrors(error.response.data.errors);
            } else {
                console.error('Error al crear el periodo:', error.response ? error.response.data : error.message);
            }
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Actualizar Periodo</h2>
                </div>
            </div>
            
            <div className="col-md-6 offset-md-3 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                    <form className="forms-sample" onSubmit={update}>
                            <div className="mb-3">
                                <label htmlFor="descripcion">Descripción</label>
                                <input 
                                    value={descripcion}
                                    onChange={(e) => setDescripcion(e.target.value)}
                                    type="text" 
                                    className={`form-control ${errors.descripcion ? 'is-invalid' : ''}`} 
                                    id="descripcion" 
                                    name="descripcion" 
                                    placeholder="Descripción"
                                />
                                {errors.descripcion && <div className="invalid-feedback">{errors.descripcion[0]}</div>}
                            </div>
                            <div className='row'>
                                <div className="col-6 mb-3">
                                    <label htmlFor="desde">Desde</label>
                                    <input 
                                        value={desde}
                                        onChange={(e) => setDesde(e.target.value)}
                                        type="date" 
                                        className={`form-control ${errors.desde ? 'is-invalid' : ''}`} 
                                        id="desde" 
                                        name="desde" 
                                        placeholder="Desde" 
                                    />
                                    {errors.desde && <div className="invalid-feedback">{errors.desde[0]}</div>}
                                </div>
                                <div className="col-6 mb-3">
                                    <label htmlFor="hasta">Hasta</label>
                                    <input 
                                        value={hasta}
                                        onChange={(e) => setHasta(e.target.value)}
                                        type="date" 
                                        className={`form-control ${errors.hasta ? 'is-invalid' : ''}`} 
                                        id="hasta" 
                                        name="hasta" 
                                        placeholder="Hasta" 
                                    />
                                    {errors.hasta && <div className="invalid-feedback">{errors.hasta[0]}</div>}
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary me-2"><i className="fas fa-save"></i> Actualizar Periodo</button>
                            <Link className="btn btn-warning" to='/periodos'>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Create;
