import React from 'react';

const SelectInput = ({ id, name, value, onChange, placeholder, options, error, disabled = false }) => (
    <div className="form-group">
        <label htmlFor={id}>{placeholder}</label>
        <select 
            value={value}
            onChange={onChange}
            className={`form-select ${error ? 'is-invalid' : ''}`}
            id={id} 
            name={name}
            disabled={disabled}
        >
            <option value="" disabled>{placeholder}</option>
            {options.map(option => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
        {error && <div className="invalid-feedback">{error}</div>}
    </div>
);

export default SelectInput;
