import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';
import Swal from 'sweetalert2';

const Estudiantes = () => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [estudiantes, setEstudiantes] = useState([]);
    //filtros
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [extranjeroFilter, setExtranjeroFilter] = useState('');

    const [pagination, setPagination] = useState({
        current_page: 1,
        from: 1,
        to: 0,
        total: 0,
        links: [],
    });
    
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(searchTerm);
        }, 2000);
    
        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);
    
    useEffect(() => {
        if ((debouncedSearchTerm.length >= 3 || debouncedSearchTerm === '') || statusFilter !== '' || extranjeroFilter !== '') {
            getData(currentPage, debouncedSearchTerm, statusFilter, extranjeroFilter);
        }
    }, [debouncedSearchTerm, currentPage, statusFilter, extranjeroFilter]);
    
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };
    
    const handleStatusChange = (e) => {
        setStatusFilter(e.target.value);
    };
    
    const handleExtranjeroChange = (e) => {
        setExtranjeroFilter(e.target.value);
    };

    const getData = async (page, searchTerm = '', status = '', extranjeroFilter = '') => {
        try {
            console.log(`Fetching data with parameters: page=${page}, search=${searchTerm}, status=${status}`);
            const response = await axios.get(`${apiUrl}/estudiantes`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                },
                params: {
                    page: page,
                    search: searchTerm,
                    status: status,
                    extranjeroFilter: extranjeroFilter
                }
            });
    
            console.log('Datos recibidos:', response.data);
    
            const pageData = {
                data: response.data.data,
                pagination: {
                    current_page: response.data.current_page,
                    from: response.data.from,
                    to: response.data.to,
                    total: response.data.total,
                    links: response.data.links,
                },
                timestamp: Date.now(),
            };
    
            setEstudiantes(response.data.data);
            setPagination(pageData.pagination);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching dashboard data', error.response ? error.response.data : error.message);
            setLoading(false);
        }
    };
    
    const handleDelete = async (id) => {
        // Mostrar el cuadro de confirmación
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás recuperar este registro después de eliminarlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        });
        // Si el usuario confirma la eliminación
        if (result.isConfirmed) {
            try {
                await axios.delete(`${apiUrl}/estudiantes/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    },
                });
                getData(currentPage);
                Swal.fire({
                    icon: 'success',
                    title: 'Eliminado',
                    text: 'El registro ha sido eliminado correctamente.',
                });
            } catch (error) {
                console.error('Error deleting registro:', error.response ? error.response.data : error.message);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo eliminar el registro. Por favor, inténtelo de nuevo.',
                });
            }
        }
    };

    const clearFilters = () => {
        setSearchTerm('');
        setStatusFilter('');
        setExtranjeroFilter('');
        getData(currentPage, '', '');
    };

    const toggleStatus = async (id, currentStatus) => {
        try {
            const newStatus = currentStatus === 1 ? 0 : 1;
            await axios.patch(`${apiUrl}/estudiantes/${id}/status`, { status: newStatus }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                },
            });
            setLoading(true);
            getData(currentPage);
        } catch (error) {
            console.error('Error updating status', error.response ? error.response.data : error.message);
        }
    };

    const generarUsuariosEstudiantes = async () => {
        try {
            const response = await axios.post(`${apiUrl}/generarUsuariosEstudiantes`, {}, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                },
            });
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            console.error('Error updating status', error.response ? error.response.data : error.message);
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>

            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Consultar Estudiantes</h2>
                </div>
                <div>
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                        <Link to='create' className="btn btn-md btn-outline-primary"><i className="fas fa-plus-circle"></i></Link>
                        <Link 
                        to='#' 
                        className="btn btn-md btn-outline-primary"
                        id={`generarUsuarios`} 
                        data-bs-toggle="dropdown" 
                        aria-expanded="false"
                        >
                            <i className="fas fa-user"></i>
                        </Link>
                        

                        <ul className="dropdown-menu" aria-labelledby={`generarUsuarios`}>
                            <li>
                                <Link 
                                    className="dropdown-item" 
                                    to='#'
                                    onClick={() => generarUsuariosEstudiantes()}
                                >
                                    Generar Usuarios
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="accordion my-3" id="accordionFilters">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFilters">
                        <button 
                            className="accordion-button" 
                            type="button" 
                            data-bs-toggle="collapse" 
                            data-bs-target="#collapseFilters" 
                            aria-expanded="true" 
                            aria-controls="collapseFilters"
                            style={{ 
                                padding: '0.6rem 0.5rem',  /* Relleno reducido */
                                height: 'auto',  /* Ajuste automático de la altura */
                                lineHeight: '1.2',  /* Altura de línea más pequeña para reducir la altura del botón */
                            }}
                        >
                            <i className="fas fa-filter"></i>
                            Filtros
                        </button>
                    </h2>
                    <div 
                        id="collapseFilters" 
                        className="accordion-collapse collapse show" 
                        aria-labelledby="headingFilters" 
                        data-bs-parent="#accordionFilters"
                    >
                        <div className="accordion-body">
                            <div className='row'>
                                <div className="col-lg-6 col-md-12 mb-2">
                                    <label htmlFor="searchTerm" className="form-label">Buscar:</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Buscar... (Cedula, Nombres, Correos, Celular, etc)" 
                                        value={searchTerm} 
                                        onChange={handleSearch} 
                                    />
                                </div>
                                <div className="col-lg-3 col-md-3 col-6 mb-2">
                                    <label htmlFor="statusFilter" className="form-label">Estado:</label>
                                    <select 
                                        className="form-select" 
                                        value={statusFilter} 
                                        onChange={handleStatusChange}
                                    >
                                        <option value="">Todos</option>
                                        <option value="1">Activo</option>
                                        <option value="0">Inactivo</option>
                                    </select>
                                </div>
                                <div className="col-lg-3 col-md-3 col-6 mb-2">
                                    <label htmlFor="statusFilter" className="form-label">Extranjero:</label>
                                    <select 
                                        className="form-select" 
                                        value={extranjeroFilter} 
                                        onChange={handleExtranjeroChange}
                                    >
                                        <option value="">Todos</option>
                                        <option value="1">Si</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>
                            </div>
                            {/* Botón para eliminar filtros */}
                            {(searchTerm || statusFilter || extranjeroFilter) && (
                                <button 
                                    className="btn btn-outline-danger" 
                                    onClick={clearFilters}
                                >
                                    Eliminar Filtros
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="card card-rounded">
                <div className="card-body">
                    <div className="table-responsive  mt-1">
                        <table className="table select-table">
                        <thead>
                                <tr>
                                    <th width={"4%"}></th>
                                    <th width={"8%"}>Identificación</th>
                                    <th>Nombres</th>
                                    <th>Estado</th>
                                    <th>Representante</th>
                                </tr>
                            </thead>
                            <tbody>
                                {estudiantes.map(persona => (
                                    <tr style={{ fontSize: '20px' }} key={persona.id}>
                                        <td>
                                            <div className="dropdown">
                                                <button 
                                                    className="btn btn-sm btn-secondary" 
                                                    type="button" 
                                                    id={`dropdownMenuButton-${persona.id}`} 
                                                    data-bs-toggle="dropdown" 
                                                    aria-expanded="false"
                                                >
                                                    <i className="fas fa-cogs"></i>
                                                </button>

                                                <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${persona.id}`}>
                                                    <li>
                                                        <Link 
                                                            className="dropdown-item" 
                                                            to={`/estudiantes/${persona.id}`} 
                                                        >
                                                            <i className="fas fa-user-edit"></i> Editar
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link 
                                                            className="dropdown-item"
                                                            to="#"
                                                            onClick={()=>handleDelete(persona.id)}
                                                        >
                                                            <i className="fas fa-trash-alt"></i> Eliminar
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                        <td>
                                            <Link to={`/estudiantes/${persona.id}`} className="text-decoration-none">
                                                {persona.identificacion}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to={`/estudiantes/${persona.id}`} className="text-decoration-none">
                                                {persona.nombres}
                                            </Link>
                                        </td>
                                        <td>
                                            <button
                                                onClick={() => toggleStatus(persona.id, persona.estado)}
                                                className={`btn btn-sm ${persona.estado === 1 ? 'btn-success' : 'btn-danger'}`}
                                            >
                                                <i className={`fas fa-${persona.estado === 1 ? 'check' : 'times'}`}></i>
                                            </button>
                                        </td>
                                        <td>
                                            <Link to={`/estudiantes/${persona.id}`} className="text-decoration-none">
                                                {persona.representante.nombres}
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* Paginación */}
                    <div className="row mt-2 pb-0 mb-0">
                        <div className="col-sm-12 col-md-5">
                            <div>
                                Mostrando {pagination.from} a {pagination.to} de {pagination.total} resultados
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                            <ul className="pagination">
                                {pagination.links.map((link, index) => (
                                    <li key={index} className={`paginate_button page-item ${link.active ? 'active' : ''}`}>
                                        <button
                                            onClick={() => link.url && setCurrentPage(new URL(link.url).searchParams.get('page'))}
                                            className="page-link"
                                            disabled={!link.url}
                                            dangerouslySetInnerHTML={{ __html: link.label }}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Estudiantes;
