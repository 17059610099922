import React from 'react';
import TextInput from '../../form/InputText';

const FormContent = ({ formData, setFormData, errors }) => {
    // Definir handleInputChange dentro del componente
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <div className="mb-3">
                <TextInput
                    label="# Nivel"
                    name="nivelEscala"
                    value={formData.nivelEscala}
                    onChange={handleInputChange}
                    validacion="entero"
                    placeholder="# Nivel"
                    errors={errors}
                />
            </div>
            <div className="mb-3">
                <TextInput
                    label="Nombre"
                    name="nombre"
                    value={formData.nombre}
                    onChange={handleInputChange}
                    placeholder="Nombre"
                    errors={errors}
                />
            </div>
            <div className="mb-3">
                <TextInput
                    label="Nombre Corto"
                    name="nombreCorto"
                    value={formData.nombreCorto}
                    onChange={handleInputChange}
                    placeholder="Nombre Corto"
                    errors={errors}
                />
            </div>
        </>
    );
};

export default FormContent;