import axios from 'axios';
import React, { useEffect, useState, useRef  } from 'react';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import FileViewer from '../widget/FileViewer';
import { validateAndFormatValue } from '../widget/ValidateAndFormatValue';
import Entrega from './Entrega';
import { useApiContext } from '../layout/Template';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const isWithinDateRange = (startDate, endDate) => {
    const now = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    return now >= start && now <= end;
};

const Show = () => {
    const { examenId } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user } = useApiContext();
    const [examen, setExamen] = useState(null);
    const [materia, setMateria] = useState(null);
    const [archivo_firmado, setaAchivo_firmado] = useState(null);
    const [estudiantes, setEstudiantes] = useState([]);
    const [filtroNombres, setFiltroNombres] = useState('');
    const [filtroEntrega, setFiltroEntrega] = useState('');
    const [filtroCalificacion, setFiltroCalificacion] = useState('');
    const [editableIndex, setEditableIndex] = useState(null);
    const [notas, setNotas] = useState(estudiantes.map(estudiante => estudiante.nota || ''));
    const [expandedRow, setExpandedRow] = useState(null);
    const [entregaRow, setEntregaRow] = useState(null);
    const [entrega, setEntrega] = useState({})
    const [entrega_firmada, setEntrega_firmada] = useState(null);

    const inputRefs = useRef([]);
    
    const formattedDate = (dateString, dateFormat = 'eeee dd MMMM yyyy HH:mm:ss') => {
        const date = new Date(dateString);
        return format(date, dateFormat, { locale: es });
    };

    const handleToggleExpand = async (index, entregaId) => {
        setEntregaRow(null);
        if (expandedRow === index) {
            setExpandedRow(null); // colapsar si ya está expandido
            setEntrega({}); // resetear datos de entrega
        } else {
            try {
                const response = await axios.get(`${apiUrl}/examen/entrega/${entregaId}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json'
                    }
                });
                const data = response.data; // Cambia esto para usar data
                setEntrega(data.entrega); // Guardar los datos de la entrega
                setEntrega_firmada(data.entrega_firmada);
                setExpandedRow(index); // expandir la fila
            } catch (error) {
                console.error("Error al obtener la entrega:", error);
            }
        }
    };

    const handdleEntregaToggle = async (index, entregaId) => {
        setExpandedRow(null); 
        if (entregaRow === index) {
            setEntregaRow(null); // colapsar si ya está expandido
        } else {
            setEntregaRow(index); 
        }
    };
    
    const handleFiltroNombresChange = (e) => {
        setFiltroNombres(e.target.value);
    };

    const handleFiltroEntregaChange = (e) => {
        setFiltroEntrega(e.target.value);
    };

    const handleFiltroCalificacionChange = (e) => {
        setFiltroCalificacion(e.target.value);
    };

    const handleNotaChange = (index, value) => {
        const newNotas = [...notas];
        newNotas[index] = value;
        setNotas(newNotas);
    };

    const estudiantesFiltrados = estudiantes.filter(estudiante => {
        const nombreMatch = estudiante.nombres.toLowerCase().includes(filtroNombres.toLowerCase());
        const entregaMatch = filtroEntrega === '' || (filtroEntrega === 'entregado' && estudiante.entrega) || (filtroEntrega === 'pendiente' && !estudiante.entrega);
        const calificacionMatch = filtroCalificacion === '' || (filtroCalificacion === 'conCalificacion' && estudiante.nota) || (filtroCalificacion === 'sinCalificacion' && !estudiante.nota);
        return nombreMatch && entregaMatch && calificacionMatch;
    });

    const handleEditClick = (index) => {
        setEditableIndex(index);
    };

    useEffect(() => {
        if (editableIndex !== null && inputRefs.current[editableIndex]) {
            inputRefs.current[editableIndex].focus();
        }
    }, [editableIndex]);

    const handleCancelEdit = () => {
        setEditableIndex(null);
    };

    const handleSave = async (index) => {
        const idEstudiante = estudiantesFiltrados[index].id;
        const examenId = examen.id; // Asegúrate de que `examen` contenga el ID correcto.
    
        try {
            const response = await axios.post(`${apiUrl}/nota/examen`, {
                id_estudiante: idEstudiante,
                id_examen: examenId,
                calificacion: notas[index] // Asegúrate de que `notas` tenga el valor actualizado.
            }, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
    
            // Muestra el mensaje de éxito
            window.showSuccessPopup(response.data.message);
            
            // Actualiza el array original de estudiantes
            const updatedEstudiantes = estudiantes.map(est => 
                est.id === idEstudiante ? { ...est, nota: parseFloat(notas[index]).toFixed(2) } : est
            );
            setEstudiantes(updatedEstudiantes);
    
            // Cancela la edición después de guardar
            handleCancelEdit();
        } catch (error) {
            console.error('Error al guardar la calificación:', error);
            // Maneja el error según sea necesario
        }
    };
    
    
    const fetchExamen = async () => {
        try {
            const response = await axios.get(`${apiUrl}/examen/notas/${examenId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            const data = response.data;
            setExamen(data.examen);
            setaAchivo_firmado(data.archivo_firmado);
            setMateria(data.materia);
            setEstudiantes(data.estudiantes);
            console.log(data.estudiantes);
            //window.showSuccessPopup('Datos del curso cargados exitosamente.');
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchExamen();
    }, [examenId, apiUrl, accessToken]);

    const handleEntregaSuccess = () => {
        setEntregaRow(null); // colapsar si ya está expandido
        fetchExamen();
    };

    const obtenerSubdominio = () => window.location.hostname.split('.')[0];
    const subdominio = obtenerSubdominio();
    console.log(subdominio); // "escuela1"
    
    const generarProyecto = async () => {
        try {
            const response = await axios.get(`${apiUrl}/examen/genProy/${examenId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            window.showSuccessPopup(response.data.message);
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className="py-3 px-3 content-wrapper">
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2>
                        {materia.materia.nombre} {materia.curso.nivel.nombre_corto}/{materia.curso.seccion.descripcion} 
                        {materia.curso.id_especialidad ? ` - ${materia.curso.especialidad.descripcion}` : ''} - 
                        {materia.curso.jornada.descripcion}
                    </h2>
                </div>
                <div>
                    <div className="btn-group mb-2" role="group">
                        <Link to={`/Materias/Contenido/${materia.id}/${examen.id_ciclo}`} className="btn btn-warning btn-sm"><i className="fas fa-chevron-circle-left"></i> Volver</Link>
                    </div>
                </div>
            </div>

            <div className='offset-lg-2 col-lg-8 mb-3'>
                <div className="card">
                    <div className="card-body">
                        <header id="page-header">
                            <div className="d-flex align-items-center">
                                <div className="mr-auto">
                                    <h2>
                                        Examen: {`${examen.fecha}`}
                                    </h2>
                                    <p dangerouslySetInnerHTML={{ __html: examen.descripcion }}></p>
                                    {examen.fecha_inicio && examen.entregas !== 0 && (
                                        <p className="text-danger">
                                            Activo Desde: {examen.fecha_inicio}, Hasta: {examen.fecha_fin}
                                        </p>
                                    )}
                                    {archivo_firmado && (
                                        <FileViewer
                                            fileName={examen.archivo}
                                            filePath={archivo_firmado}
                                        />
                                    )}
                                    {(subdominio === "monteeverest" || subdominio === "escuela1" || subdominio === "capacitaciones") && user.estudiante === 0 && (
                                        <a href="#" className="btn btn-info" onClick={(e) => {
                                            e.preventDefault(); // Prevenir el comportamiento por defecto del enlace
                                            generarProyecto();
                                        }}>
                                            Generar proyecto
                                        </a>
                                    )}
                                </div>
                            </div>
                        </header>
                    </div>
                </div>
                <div className="card mt-4">
                    <div className="card-body">
                        {user.estudiante !== 1 && (
                            <div className='row'>
                                <div className="col-lg-4">
                                    <input
                                        type="text"
                                        placeholder="Buscar estudiante..."
                                        value={filtroNombres}
                                        onChange={handleFiltroNombresChange}
                                        className="form-control"
                                    />
                                </div>
                                
                                {examen.entregas === 1 && (
                                    <div className="col-lg-4">
                                        <select
                                            value={filtroEntrega}
                                            onChange={handleFiltroEntregaChange}
                                            className="form-select"
                                        >
                                            <option value="">Todos</option>
                                            <option value="entregado">Entregados</option>
                                            <option value="pendiente">Pendientes</option>
                                        </select>
                                    </div>
                                )}
                                <div className="col-lg-4">
                                    <select
                                        value={filtroCalificacion}
                                        onChange={handleFiltroCalificacionChange}
                                        className="form-select"
                                    >
                                        <option value="">Todos</option>
                                        <option value="conCalificacion">Con Calificación</option>
                                        <option value="sinCalificacion">Sin Calificación</option>
                                    </select>
                                </div>
                            </div>
                        )}
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        {user.estudiante !== 1 && (
                                            <>
                                                <th scope="col" style={{ width: '1%' }}>#</th>
                                                <th scope="col" style={{ width: '40%' }}>Estudiante</th>
                                            </>
                                        )}
                                        <th scope="col-1" className="" style={{ width: '200px' }}>Calificacion</th>
                                        {examen.entregas === 1 && (
                                            <th scope="col" className="text-center">Entrega</th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* .sort((a, b) => a.nombres.toLowerCase().localeCompare(b.nombres.toLowerCase())) */}
                                    {estudiantesFiltrados
                                    .map((estudiante, index) => (
                                        <React.Fragment key={estudiante.id}>
                                        <tr>
                                            {user.estudiante !== 1 && (
                                                <>
                                                    <td>{index + 1}</td>
                                                    <td>{estudiante.nombres}</td>
                                                </>
                                            )}
                                            <td className="text-center">
                                                <form onSubmit={(e) => { e.preventDefault(); handleSave(index); }}>
                                                    <div className="input-group" style={{ width: '200px' }}>
                                                        <input 
                                                            type="text" 
                                                            value={editableIndex === index ? notas[index] : estudiante.nota || ''} 
                                                            onInput={(e) => validateAndFormatValue(e, handleNotaChange, index)}
                                                            disabled={editableIndex !== index} 
                                                            className="form-control"
                                                            ref={el => inputRefs.current[index] = el} // Asigna la referencia al input
                                                        />
                                                        {(editableIndex === index && (user.estudiante !== 1 && (user.id_docente === materia.id_docente || user.id_rol))) ? (
                                                            <>
                                                            <button 
                                                                type="submit" 
                                                                className="btn btn-sm btn-success input-group-prepend"
                                                            >
                                                                <i className="fa fa-save text-dark"></i>
                                                            </button>
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-sm btn-danger input-group-prepend"
                                                                onClick={handleCancelEdit}
                                                            >
                                                                <i className="fa fa-times"></i>
                                                            </button>
                                                            </>
                                                        ) : (
                                                            (user.estudiante !== 1 && user.id_docente === materia.id_docente || user.id_rol) && (
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-sm btn-primary input-group-prepend"
                                                                onClick={() => handleEditClick(index)}
                                                            >
                                                                <i className="fa fa-edit"></i>
                                                            </button>
                                                            )
                                                        )}
                                                    </div>
                                                </form>
                                            </td>
                                            {examen.entregas === 1 && (
                                                <td className="text-center">
                                                    {estudiante.entrega ? (
                                                        <>
                                                            <button 
                                                                className="btn btn-primary"
                                                                onClick={() => handleToggleExpand(index, estudiante.id_entrega)} // Usar el ID de la entrega
                                                            >
                                                                Ver Entrega
                                                            </button>
                                                            {user.estudiante === 1 && ( // Verifica si el usuario es estudiante
                                                                <button 
                                                                    className="btn btn-success" 
                                                                    onClick={() => handdleEntregaToggle(index)}
                                                                >
                                                                    Editar
                                                                </button>
                                                            )}
                                                        </>
                                                    ) : (
                                                        user.estudiante === 1 && isWithinDateRange(examen.fecha_inicio, examen.fecha_fin) ? (
                                                            <button 
                                                                className="btn btn-success" 
                                                                onClick={() => handdleEntregaToggle(index)}
                                                            >
                                                                Añadir
                                                            </button>
                                                        ) : (
                                                            <span className="text-danger">Pendiente</span>
                                                        )
                                                    )}
                                                </td>
                                            )}
                                        </tr>
                                        {expandedRow === index && (
                                            <tr>
                                            <td colSpan={4}>
                                                <div className="card">
                                                    <div className="card-body container">
                                                        <h5 className="card-title">
                                                            Entrega Realizada: {formattedDate(entrega.created_at)}
                                                            {entrega.created_at !== entrega.updated_at && (
                                                                <span>
                                                                    {' (Actualizada: ' + formattedDate(entrega.updated_at) + ')'}
                                                                </span>
                                                            )}
                                                        </h5>

                                                        <div>
                                                            {entrega_firmada && (
                                                                <FileViewer
                                                                    fileName={entrega.archivo}
                                                                    filePath={entrega_firmada}
                                                                />
                                                            )}
                                                        </div>
                                                        <p dangerouslySetInnerHTML={{ __html: entrega.descripcion }}></p>
                                                        {/* 
                                                        <div class="mt-3">
                                                            <form>                
                                                                <div class="mb-3">
                                                                    <label for="calificacion">Calificación</label>
                                                                    <input 
                                                                        type="text" 
                                                                        className="form-control"
                                                                    />
                                                                </div>     
                                                                <div>
                                                                    <label for="descripcion form-label">Comentario</label>
                                                                    <div class="mb-3 form-floating">
                                                                        <textarea class="form-control" name="comentario" maxlength="250" disabled=""></textarea>
                                                                    </div>
                                                                </div>
                                                                <button 
                                                                    type="submit" 
                                                                    className="btn btn-sm btn-success input-group-prepend text-dark"
                                                                >
                                                                    <i className="fa fa-save"></i> Guardar
                                                                </button>
                                                            </form>
                                                        </div>
                                                        */}
                                                    </div>
                                                </div>
                                                <style>
                                                    {`
                                                        .entrega-card img {
                                                            border-radius: 0 !important;
                                                            width: 1000px;
                                                            height: 1000px;
                                                        }
                                                        .table td img {
                                                            border-radius: 0 !important;
                                                            width: 350px;
                                                            height: 350px;
                                                        }
                                                    `}
                                                </style>
                                            </td>
                                        </tr>
                                        
                                        )}
                                        {entregaRow === index && (
                                            <tr>
                                            <td colSpan={4}>
                                            <Entrega examenId={examen.id} onEntregaSuccess={handleEntregaSuccess} />
                                            </td>
                                        </tr>
                                        )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default Show;
