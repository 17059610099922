import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Spinner from '../widget/Spinner';
import { useApiContext  } from '../layout/Template';
import courseImg from '../../../assets/img/course.png'; 

const cache = {};
const CACHE_DURATION = 5 * 60 * 60 * 1000; // 5 horas en milisegundos

const Tutorias = () => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user } = useApiContext();
    const [cursos, setCursos] = useState([]);
    
    const getCursos = async () => {

        const now = Date.now();

        // Verifica si el caché existe y es válido
        if (cache['tutorias'] && (now - cache['tutorias'].timestamp < CACHE_DURATION)) {
            setCursos(cache['tutorias'].data);
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${apiUrl}/tutorias`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            console.log('Datos recibidos:', response.data.cursos); // Imprime los datos recibidos en la consola

            // Actualiza el caché
            cache['tutorias'] = {
                data: response.data.cursos,
                timestamp: now // Marca de tiempo para la expiración
            };
            
            setCursos(response.data.cursos);
            setLoading(false); 
        } catch (error) {
            console.error('Error fetching dashboard data', error.response ? error.response.data : error.message);
        }
    };

    useEffect( ()=> {
        getCursos()
    }, [])

    // Si aún se están cargando los datos, muestra el spinner
    if (loading) {
        return <Spinner />;
    }

    return (
        <div className="py-3 px-3 content-wrapper">
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Tutorias</h2>
                </div>
            </div>
            <div className="container py-5">
                <div className="row justify-content-center">
                    {cursos.map((curso) => (
                        <div key={curso.id} className="col-md-6 col-lg-6 col-xl-3 my-3">
                            <div className="card text-black">
                                <div className="text-center mt-2">
                                    <img
                                        src={courseImg}
                                        className="bd-placeholder-img rounded-circle text-center" width="140" height="140"
                                        alt="Course IMG"
                                    />
                                </div>
                                <div className="card-body">
                                    <div className="text-center">
                                        <h5 className="card-title">Curso: {curso.nivel.nombre_corto}/{curso.seccion.descripcion}</h5>
                                        <p className="text-muted mb-1">Tutor: <strong>{curso.tutor.nombres}</strong></p>
                                        <p className="text-muted mb-1">Tipo: {curso.tipo.descripcion}</p>
                                        <p className="text-muted mb-1">Modalidad: {curso.modalidad.descripcion}</p>
                                        <p className="text-muted mb-1">Especialidad: {curso.id_especialidad ? curso.especialidad.descripcion : 'N/A'}</p>
                                        <p className="text-muted mb-1">Jornada: {curso.jornada.descripcion}</p>
                                        <p className="text-muted mb-1">Periodo: {curso.periodo.descripcion}</p>
                                    </div>
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <span>Estudiantes:</span><span>{curso.totalEstudiantes}</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span>Materias:</span><span>{curso.totalMaterias}</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span className={curso.estado === 1 ? 'text-success' : 'text-danger'}>
                                                {curso.estado === 1 ? 'Activo' : 'Inactivo'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="text-center d-grid gap-2">
                                        <Link to={`/MisCursos/Info/${curso.id}`} className="btn btn-primary mt-3">Ingresar</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Tutorias;