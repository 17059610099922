import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Spinner from '../widget/Spinner';
import { useApiContext  } from '../layout/Template';
import courseImg from '../../../assets/img/course.png'; 

const cache = {};
const CACHE_DURATION = 5 * 60 * 60 * 1000; // 5 horas en milisegundos

const Mismaterias = () => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext(); // Ajusta según tu contexto API
    const [periodo, setPeriodo] = useState('');
    const [periodos, setPeriodos] = useState([]);
    const [materias, setMaterias] = useState([]);

    useEffect(() => {
        getInfo(); // Llama a la función para obtener los datos iniciales
    }, []);

    const getInfo = async () => {

        const now = Date.now();

        // Verifica si el caché existe y es válido para la consulta inicial
        if (cache['mismaterias'] && (now - cache['mismaterias'].timestamp < CACHE_DURATION)) {
            const { periodos, materias } = cache['mismaterias'].data;
            setPeriodos(periodos);
            setMaterias(materias);
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${apiUrl}/mismaterias`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            console.log('Datos recibidos:', response.data); // Verifica los datos recibidos en la consola

            // Actualiza el caché
            cache['mismaterias'] = {
                data: response.data,
                timestamp: now // Marca de tiempo para la expiración
            };

            const { periodos, materias } = response.data;
    
            // Actualiza el estado con los datos recibidos
            setPeriodos(periodos);
            setMaterias(materias);
            setLoading(false); 
        } catch (error) {
            console.error('Error fetching data:', error.response ? error.response.data : error.message);
        }
    };

    const handlePeriodoChange = async (e) => {
        const selectedPeriodo = e.target.value;
        setPeriodo(selectedPeriodo);
        setLoading(true); 

        const now = Date.now();
        const cacheKey = `mismaterias-${selectedPeriodo}`;

        // Verifica si el caché existe y es válido para la consulta con periodo seleccionado
        if (cache[cacheKey] && (now - cache[cacheKey].timestamp < CACHE_DURATION)) {
            setMaterias(cache[cacheKey].data.materias);
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${apiUrl}/mismaterias?periodo=${selectedPeriodo}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            console.log('Datos actualizados:', response.data); // Verifica los datos recibidos en la consola

            // Actualiza el caché para el periodo seleccionado
            cache[cacheKey] = {
                data: response.data,
                timestamp: now // Marca de tiempo para la expiración
            };

            const { materias } = response.data;

            setMaterias(materias);
            setLoading(false); 
        } catch (error) {
            console.error('Error fetching updated data:', error.response ? error.response.data : error.message);
        }
    };

    // Si aún se están cargando los datos, muestra el spinner
    if (loading) {
        return <Spinner />;
    }

    return (
        <div className="py-3 px-3 content-wrapper">
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Consultar Materias</h2>
                </div>
                <div>
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                        <div className="mb-3">
                            <label htmlFor="periodoSelect" className="form-label">Periodo</label>
                            <select
                                className="form-select form-select-lg"
                                name="periodo"
                                id="periodoSelect"
                                value={periodo}
                                onChange={handlePeriodoChange}
                            >
                                <option value="">Todos los periodos</option>
                                {periodos.map(per => (
                                    <option key={per.id} value={per.id}>
                                        {per.descripcion}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className=" py-5">
                    <div className="row justify-content-center">
                        {materias.map(materia => (
                            <div key={materia.id} className="card card-link mb-3 mx-3 py-1" style={{ maxWidth: '400px' }}>
                                <div className="row g-0">
                                    <div className="col-md-4">
                                        <img
                                            src={courseImg}
                                            className="bd-placeholder-img rounded-circle text-center"
                                            width="140"
                                            height="140"
                                            alt="Curso"
                                        />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body">
                                            <h4 className="card-title mb-1">{materia.materiaNombre}</h4>
                                            <h6 className="card-text mb-1"><small className="text-muted">{materia.nivel_nombrecorto}/{materia.seccion} ({materia.jornada})</small></h6>
                                            <h6 className="card-text mb-1"><small className="text-muted">Periodo: {materia.periodo}</small></h6>
                                            <p className="text-muted mb-0">Especialidad: {materia.especialidad}</p>
                                            <p className="text-muted"><small className="text-muted">Modalidad: {materia.modalidad}</small></p>
                                        </div>
                                    </div>
                                </div>
                                <h4 className="text-center mb-1">Titular: <strong>{materia.docente}</strong></h4>
                                <Link to={`/Materias/Contenido/${materia.id}/${materia.curso.periodo.parcial_actual}`} className="btn btn-primary mb-1">Ver Materia</Link>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <style>
                {`
                    .card-link {
                        max-width: 400px;
                        border: gray solid 1px;
                        transition: background-color 0.3s, transform 0.3s;
                    }

                    .card-link:hover {
                        transform: scale(1.1);
                    }
                `}
            </style>
        </div>
    );
};

export default Mismaterias;