import axios from 'axios';
import React, { useState } from 'react';
import imgbanner from '../../assets/img/edusnap/logo_hotizontal.png';
import {Link} from 'react-router-dom'

const BASE_URL = process.env.REACT_APP_BASE_URL;
const CngPwd = ({ subdominio }) => { /* exportar variable de subdominio del appjs */
    const [password, setPassword] = useState('');
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [error, setError] = useState('');
    const [successTenants, setSuccessTenants] = useState([]);

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${apiUrl}/UpdPwd`;

    const store = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(endpoint, { password, password_confirmation });
            console.log('Respuesta del servidor:', response.data); // Mostrar respuesta en consola
            if (response.data.status === 'success') {
                if (response.data.tenants && response.data.tenants.length > 0) {
                    setSuccessTenants(response.data.tenants);
                }
            } else {
                setError(response.data.message);
            }
        } catch (error) {
            console.error('Error al iniciar sesión:', error);
            setError('El usuario no pertenece a una institución.');
        }
    };

    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-center auth px-0">
                    <div className="row w-100 mx-0">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                <div className="brand-logo">
                                    <img src={imgbanner} alt="edusnap" style={{ width: '15rem' }} />
                                </div>
                                <h4>Actualizar Contraseña</h4>
                                <h6 className="fw-light">Introduce tu nueva contraseña y confirma la contraseña para actualizar tus credenciales de acceso.</h6>

                                <form className="pt-3" onSubmit={store} method="POST">
                                    <div className="form-group">
                                        <input
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="form-control form-control-lg"
                                            id="exampleInputEmail1"
                                            placeholder="Contraseña"
                                            autoFocus
                                        />
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="password_confirmation"
                                            value={password_confirmation}
                                            onChange={(e) => setPassword_confirmation(e.target.value)}
                                            className="form-control form-control-lg"
                                            id="exampleInputEmail1"
                                            placeholder="Contraseña"
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <input
                                            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                            type="submit"
                                            value="Reestablecer Contraseña"
                                        />
                                    </div>
                                </form>
                                <div className="my-2 d-flex justify-content-between align-items-center">
                                <Link to={`/${subdominio}/Login`} className="auth-link text-black">Iniciar Sesion</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CngPwd;
