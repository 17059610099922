import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import Spinner from '../widget/Spinner';
import { useApiContext  } from '../layout/Template';
import courseImg from '../../../assets/img/course.png'; 
import Swal from 'sweetalert2';

const cache = {};
const CACHE_DURATION = 1 * 60 * 1000; // 1 min en milisegundos

const Cursos = () => {
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken } = useApiContext();
    const [cursos, setCursos] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [periodo, setPeriodo] = useState('');
    const [bitacoraUnificada, setBitacoraUnificada] = useState(null);
    const [actividadesUnificadas, setActividadesUnificadas] = useState(null);
    const [pagination, setPagination] = useState({
        current_page: 1,
        from: 1,
        to: 0,
        total: 0,
        links: [],
    });
    
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        getCursos(currentPage);
    }, [currentPage]);

    const getCursos = async (page) => {

        const now = Date.now();
        
        // Verifica si el caché existe y es válido
        if (cache[page] && (now - cache[page].timestamp < CACHE_DURATION)) {
            setCursos(cache[page].data);
            setPagination(cache[page].pagination);
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${apiUrl}/cursos?page=${page}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                },
            });
            console.log('Datos recibidos:', response.data); // Imprime los datos recibidos en la consola
        
            // Actualiza el estado con los datos recibidos
            const receivedData = {
                data: response.data.cursos.data,
                pagination: {
                    current_page: response.data.cursos.current_page,
                    from: response.data.cursos.from,
                    to: response.data.cursos.to,
                    total: response.data.cursos.total,
                    links: response.data.cursos.links,
                },
                periodos: periodos,
                periodo: periodo,
                bitacoraUnificada: bitacoraUnificada,
                actividadesUnificadas: actividadesUnificadas,
                timestamp: now, // Marca de tiempo para la expiración
            };

            // Actualiza el caché
            cache[page] = receivedData;

            // Actualiza el estado del componente
            setCursos(receivedData.data);
            setPagination(receivedData.pagination);
            setPeriodos(receivedData.periodos);
            setPeriodo(receivedData.periodo);
            setBitacoraUnificada(receivedData.bitacoraUnificada);
            setActividadesUnificadas(receivedData.actividadesUnificadas);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching dashboard data', error.response ? error.response.data : error.message);
        }
    };
    
    const handleDelete = async (id) => {
        // Mostrar el cuadro de confirmación
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás recuperar este curso después de eliminarlo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        });
    
        // Si el usuario confirma la eliminación
        if (result.isConfirmed) {
            try {
                await axios.delete(`${apiUrl}/cursos/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    },
                });
                getCursos(currentPage);
                Swal.fire({
                    icon: 'success',
                    title: 'Eliminado',
                    text: 'El curso ha sido eliminado correctamente.',
                });
            } catch (error) {
                console.error('Error deleting curso:', error.response ? error.response.data : error.message);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo eliminar el curso. Por favor, inténtelo de nuevo.',
                });
            }
        }
    };

    // Si aún se están cargando los datos, muestra el spinner
    if (loading) {
        return <Spinner />;
    }

    return (
        <div className="py-3 px-3 content-wrapper">
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Mis Cursos</h2>
                </div>
                <div>
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                        <Link to='create' className="btn btn-md btn-outline-primary"><i className="fas fa-plus-circle"></i></Link>
                    </div>
                </div>
            </div>
            <div className="pt-5">
                <div className="row justify-content-center">
                    {cursos.map((curso) => (
                        <div key={curso.id} className="col-md-6 col-lg-6 col-xl-2 my-3">
                            <div className="card text-black">
                                <div className="dropdown">
                                    <Link to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-ellipsis-v fa-lg pt-3 pb-1 px-3"></i></Link>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link className="dropdown-item" to={`/Cursos/${curso.id}`}><i className="fas fa-edit"></i> Editar</Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to="#" onClick={()=>handleDelete(curso.id)}><i className="fas fa-trash"></i> Eliminar</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="text-center">
                                    <img
                                        src={courseImg}
                                        className="bd-placeholder-img rounded-circle text-center"
                                        width="140"
                                        height="140"
                                        alt="Course IMG"
                                    />
                                </div>
                                <div className="card-body">
                                    <div className="text-center">
                                        <h5 className="card-title">
                                            Curso: {curso.nivel.nombre_corto}/{curso.seccion.descripcion}
                                        </h5>
                                        <p className="text-muted mb-1">Tutor: <strong>{curso.tutor.nombres}</strong></p>
                                        <p className="text-muted mb-1">Tipo: {curso.tipo.descripcion}</p>
                                        <p className="text-muted mb-1">Modalidad: {curso.modalidad.descripcion}</p>
                                        <p className="text-muted mb-1">
                                            Especialidad: {curso.id_especialidad ? curso.especialidad.descripcion : 'N/A'}
                                        </p>
                                        <p className="text-muted mb-1">Jornada: {curso.jornada.descripcion}</p>
                                        <p className="text-muted mb-1">Periodo: {curso.periodo.descripcion}</p>
                                    </div>
                                    <div>
                                        <div className="d-flex justify-content-between">
                                            <span>Estudiantes:</span><span>{curso.totalEstudiantes}</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span>Materias:</span><span>{curso.totalMaterias}</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span className={curso.estado === 1 ? 'text-success' : 'text-danger'}>
                                                {curso.estado === 1 ? 'Activo' : 'Inactivo'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="text-center d-grid gap-2">
                                        <Link to={`/MisCursos/Info/${curso.id}`} className="btn btn-primary mt-3">
                                            Ingresar
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Paginación */}
                <div className="row mt-2 pb-0 mb-0">
                    <div className="col-sm-12 col-md-5">
                        <div>
                            Mostrando {pagination.from} a {pagination.to} de {pagination.total} resultados
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-7">
                        <ul className="pagination">
                            {pagination.links.map((link, index) => (
                                <li key={index} className={`paginate_button page-item ${link.active ? 'active' : ''}`}>
                                    <button
                                        onClick={() => link.url && setCurrentPage(new URL(link.url).searchParams.get('page'))}
                                        className="page-link"
                                        disabled={!link.url}
                                        dangerouslySetInnerHTML={{ __html: link.label }}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cursos;