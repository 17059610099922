import React from 'react';
import TextInput from '../../form/InputText';

const FormContent = ({ formData, setFormData, errors }) => {
    // Definir handleInputChange dentro del componente
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <div className="mb-3">
                <TextInput
                    label="Descripcion"
                    name="descripcion"
                    value={formData.descripcion}
                    onChange={handleInputChange}
                    placeholder="Descripcion"
                    errors={errors}
                />
            </div>
            <div className="mb-3">
                <TextInput
                    label="Alias"
                    name="alias"
                    value={formData.alias}
                    onChange={handleInputChange}
                    placeholder="Alias"
                    errors={errors}
                />
            </div>
        </>
    );
};

export default FormContent;