import React from 'react';
import TextInput from '../../form/InputText';

const FormContent = ({ formData, setFormData, errors }) => {
    // Definir handleInputChange dentro del componente
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    return (
        <>
            <div className="">
                <TextInput
                    label="Descripcion"
                    name="descripcion"
                    value={formData.descripcion}
                    onChange={handleInputChange}
                    placeholder="Descripcion"
                    errors={errors}
                />
            </div>
            <div className='row'>
                <div className="col-6">
                    <TextInput
                        label="Desde"
                        name="hora_inicio"
                        type={"time"}
                        value={formData.hora_inicio}
                        onChange={handleInputChange}
                        placeholder="Desde"
                        errors={errors}
                    />
                </div>
                <div className="col-6">
                    <TextInput
                        label="Hasta"
                        name="hora_fin"
                        type={"time"}
                        value={formData.hora_fin}
                        onChange={handleInputChange}
                        placeholder="Hasta"
                        errors={errors}
                    />
                </div>
            </div>
        </>
    );
};

export default FormContent;