import React, { useState } from 'react';
import CheckboxInput from '../../form/CheckboxInput';

const DatosTipo = ({ formData, setFormData }) => {

    const handleCheckboxChange = (field, subField = null) => (e) => {
        const checked = e.target.checked;
        setFormData(prevFormData => {
            if (subField) {
                // Caso para subcampos
                return {
                    ...prevFormData,
                    [field]: {
                        ...prevFormData[field],
                        [subField]: checked
                    }
                };
            } else {
                // Caso para campos en el nivel superior
                return {
                    ...prevFormData,
                    [field]: checked
                };
            }
        });
    };

    return (
        <div className="col-lg-2 col-md-12 col-sm-12 mb-3">
            <div className='card'>
                <div className="card-body py-1">
                    <h4 className="card-title">Tipo</h4>
                    <div className="form-group pb-0">
                        <CheckboxInput 
                            id="isCliente"
                            name="isCliente"
                            checked={formData.isCliente}
                            onChange={handleCheckboxChange('isCliente')}
                            label="Cliente"
                        />
                        <CheckboxInput 
                            id="proveedor"
                            name="proveedor"
                            checked={formData.isProveedor}
                            onChange={handleCheckboxChange('isProveedor')}
                            label="Proveedor"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DatosTipo;