import React from 'react';

const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase();
    return /safari/.test(ua) && !/chrome/.test(ua);
};

const PdfModal = ({ pdfUrl, id }) => {
    const googleViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`;
    const isSafariBrowser = isSafari();

    return (
        <>
        {isSafariBrowser ? (
            <a
            href={pdfUrl}
            className="btn btn-primary"
            target="_blank"
            rel="noopener noreferrer"
            >
            Abrir PDF
            </a>
        ) : (
            <>
                <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target={`#pdfModal${id}`}
                >
                Abrir PDF
                </button>

                <div
                    className="modal fade"
                    id={`pdfModal${id}`}
                    tabIndex="-1"
                    aria-labelledby={`pdfModalLabel${id}`}
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                            <h5 className="modal-title" id={`pdfModalLabel${id}`}>Visualizar Archivo</h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <iframe
                                    src={googleViewerUrl}
                                    width="100%"
                                    height="600px"
                                    style={{ border: 'none' }}
                                    title="PDF Viewer"
                                />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Cerrar
                                </button>
                                <a
                                    href={pdfUrl}
                                    download
                                    className="btn btn-primary"
                                >
                                    Descargar PDF
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )}
        </>
    );
};

export default PdfModal;
