// Ejemplo de ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const isAuthenticated = !!localStorage.getItem('accessToken'); // Verificar si hay un token de acceso

    return isAuthenticated ? children : <Navigate to="/Login" />;
};

export default ProtectedRoute;
