import React, { useEffect, useState } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

const ImageGallery = ({ archivos }) => {
    const [dimensions, setDimensions] = useState([]);

    useEffect(() => {
        const lightbox = new PhotoSwipeLightbox({
            gallery: '#my-gallery',
            children: 'a',
            pswpModule: () => import('photoswipe')
        });
        lightbox.init();

        const loadImageDimensions = async () => {
            const dims = await Promise.all(
                archivos.map((archivo) =>
                    new Promise((resolve) => {
                        const img = new Image();
                        img.src = archivo;
                        img.onload = () => resolve({ width: img.width, height: img.height });
                    })
                )
            );
            setDimensions(dims);
        };

        loadImageDimensions();
    }, [archivos]);

    return (
        <div id="my-gallery">
            {archivos.map((archivo, index) => (
                <a
                    key={index}
                    href={archivo}
                    data-pswp-width={dimensions[index]?.width}
                    data-pswp-height={dimensions[index]?.height}
                >
                    <img 
                        src={archivo} 
                        alt={`Imagen ${index}`} 
                        style={{ maxWidth: '100px', maxHeight: '100px' }} 
                    />
                </a>
            ))}
        </div>
    );
};

export default ImageGallery;
