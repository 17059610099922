import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

// Configurar el interceptor de respuesta de Axios
const useAxiosInterceptor = () => {
    const navigate = useNavigate();

    // Interceptor de respuestas
    axios.interceptors.response.use(
        response => response, // Si la respuesta es exitosa, simplemente retorna la respuesta
        error => {
            if (error.response) {
                if (error.response.status === 401) {
                    // Muestra una alerta utilizando SweetAlert
                    Swal.fire({
                        title: 'Sesión expirada',
                        text: 'Tu sesión ha expirado. Por favor, vuelve a iniciar sesión.',
                        icon: 'warning',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        // Remover el token del localStorage
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('user');
                        
                        // Redireccionar al usuario a la página de login
                        navigate('/Login');
                    });
                } else if (error.response.status === 403) {
                    // Si la respuesta es un 403, redirigir al dashboard
                    navigate('/dashboard');
                }
            }
            return Promise.reject(error); // Rechaza la promesa para manejar el error donde se hizo la solicitud
        }
    );
};

export default useAxiosInterceptor;
