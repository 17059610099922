import React from 'react';
import { Navigate } from 'react-router-dom';

const SessionExist = ({ element: Element, ...rest }) => {
    const isAuthenticated = !!localStorage.getItem('accessToken'); // Verificar si hay un token de acceso

    return isAuthenticated ? <Navigate to="/Dashboard" /> : <Element {...rest} />;
};

export default SessionExist;
