import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import { useApiContext } from '../layout/Template';
import QuillEditor  from '../widget/QuillEditor';
import $ from 'jquery';
import 'bootstrap-fileinput/css/fileinput.min.css';
import 'bootstrap-fileinput/js/fileinput.min.js';
import 'bootstrap-fileinput/themes/fa5/theme.js';
import 'bootstrap-fileinput/js/locales/es';

const Test = () => {
    const { claseId } = useParams();
    const { apiUrl, accessToken } = useApiContext();
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [clase, setClase] = useState(null);
    const editorRef = useRef(null);
    const inputFileRef = useRef(null);
    const [imagenError, setImagenError] = useState(false); 
    //variables
    const [titulo, setTitulo] = useState('');
    const [url, setUrl] = useState('');
    const [resumen, setResumen] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [fecha, setFecha] = useState('');
    const [archivo, setArchivo] = useState(null);
    const [materia, setMateria] = useState(0);
    const [parcial, setParcial] = useState(0);
    const endpoint = `${apiUrl}/clase/${claseId}`;

    $(document).ready(function () {
        if (inputFileRef.current) {
            $(inputFileRef.current).fileinput({
                maxFileSize: 10240,
                maxFilesNum: 1,
                language: 'es',
                previewFileType: 'any',
                theme: 'fa5',
                showUpload: false,
                dropZoneEnabled: true,
                inputGroupClass: 'input-group-sm',
                allowedFileExtensions: ['jpg', 'jpeg', 'png', 'pdf'],
                showPreview: true,
                fileActionSettings: {
                    showUpload: false,
                    showZoom: false  // Aquí se ocultan los botones de zoom
                }
            });

            // Manejar el evento de modal oculto después de la vista de zoom
            $(inputFileRef.current).on('filezoomhidden', function(event, params) {
                console.log('File zoom hidden', params.sourceEvent, params.previewId, params.modal);
                // Aquí puedes realizar cualquier acción adicional después de que se oculte el modal de zoom
            });

            $(inputFileRef.current).on('fileerror', function(event, file, message) {
                console.log('Error:', message);
                setImagenError(true);  // Cambiar a true si hay un error
            });

            $(inputFileRef.current).on('fileclear', function(event) {
                setImagenError(false);  // Reset error state when file is cleared
            });
        }
    });

    const fetchClase = async () => {
        try {
            const response = await axios.get(`${apiUrl}/clase/${claseId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}` // Agrega el token de acceso si es necesario
                }
            });
            const clase = response.data.clase;
            setMateria(clase.id_materiacurso || '');
            setParcial(clase.id_subciclo || '');

            // Establecer los valores obtenidos en los estados correspondientes
            setTitulo(clase.titulo || '');
            setUrl(clase.url || '');
            setResumen(clase.resumen || '');

            // Establecer el valor de la descripción
            setDescripcion(clase.descripcion || '');
            if (editorRef.current) {
                editorRef.current.root.innerHTML = clase.descripcion || '';
            }
            console.log(clase.descripcion)
            setFecha(clase.fecha || '');
        } catch (err) {
            if (err.response && err.response.status === 422) {
                setErrors(err.response.data.errors);
            } else if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    navigate('/dashboard');
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false); // Finaliza el estado de carga
        }
    };

    useEffect(() => {
        fetchClase();
    }, [claseId, apiUrl, accessToken]);

    const update = async (e) => {
        e.preventDefault();
        
        // Obtén el contenido del editor desde el ref
        const editorContent = editorRef.current?.root.innerHTML || '';
        const file = inputFileRef.current?.files[0];
        const newErrors = {};

        // Validaciones de texto
        if (!titulo.trim() || titulo.length < 3 || titulo.length > 250)
            newErrors.titulo = 'El título es obligatorio y debe tener entre 3 y 250 caracteres.';
        
        if (url.length > 250)
            newErrors.url = 'El título es obligatorio y debe tener entre 3 y 250 caracteres.';

        if (!resumen.trim() || resumen.length > 100)
            newErrors.resumen = 'El resumen debe tener maximo 100 caracteres.';

        // Validación de descripción
        const textLength = editorContent.replace(/<[^>]*>/g, '').length; // Elimina etiquetas HTML y mide longitud
        if (textLength > 10000) {
            newErrors.descripcion = 'La descripción es obligatoria y debe tener entre 3 y 10000 caracteres.';
        }
    
        // Validación de fechas
        if (!fecha)
            newErrors.fecha = 'La fecha es obligatoria.';

        // Validación de archivo
        if (file && file.size > 10 * 1024 * 1024) {
            newErrors.archivo = 'El archivo no puede ser mayor a 10MB.';
        }
        console.log(imagenError)

        // Verificar imagenError antes de enviar el formulario
        if (imagenError) {
            console.log('Archivo Invalido');
            newErrors.archivo = 'Hay un error con el archivo seleccionado.';
        }

        // Actualiza el estado con los errores encontrados
        setErrors(newErrors);
    
        // Verifica si hay errores antes de enviar el formulario
        if (Object.keys(newErrors).length > 0) {
            // No continúes si hay errores
            return;
        }
    
        const formData = new FormData();
        formData.append('titulo', titulo);
        formData.append('url', url);
        formData.append('resumen', resumen);
        formData.append('descripcion', editorContent);
        formData.append('fecha', fecha);

        // Solo agrega el archivo si no hay una ruta de archivo
        if (file) {
            formData.append('archivo', file);
        }
    
        try {
            const response = await axios.post(endpoint, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            });
            navigate(`/Materias/Contenido/Clase/${response.data.id}`);
            window.showSuccessPopup(response.data.message);
            // Maneja la respuesta de éxito aquí
        } catch (err) {
            if (err.response && err.response.status === 422) {
                setErrors(err.response.data.errors);
            } else if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    navigate('/dashboard');
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        }
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className="container-scroller">
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Actualizar Clase</h2>
                </div>
            </div>

            <div className="col-md-6 offset-md-3 grid-margin stretch-card mt-3">
                <div className="card">
                    <div className="card-body">
                        <form className="forms-sample" onSubmit={update} encType="multipart/form-data">
                            <div className="mb-3">
                                <label htmlFor="titulo">Título</label>
                                <input 
                                    type="text" 
                                    className={`form-control ${errors.titulo ? 'is-invalid' : ''}`} 
                                    id="titulo" 
                                    name="titulo" 
                                    placeholder="Título" 
                                    maxLength="250"
                                    value={titulo}
                                    onChange={(e) => setTitulo(e.target.value)}
                                />
                                {errors.titulo && <div className="invalid-feedback">{errors.titulo}</div>}
                            </div>
                            <div className="mb-3">
                                <label for="resumen">Resumen</label>
                                <input 
                                    type="text" 
                                    className={`form-control ${errors.resumen ? 'is-invalid' : ''}`} 
                                    id="resumen" 
                                    name="resumen" 
                                    placeholder="Resumen" 
                                    maxLength="100"
                                    value={resumen}
                                    onChange={(e) => setResumen(e.target.value)}
                                />
                                {errors.resumen && <div className="invalid-feedback">{errors.resumen}</div>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="descripcion" className={`form-control ${errors.descripcion ? 'is-invalid' : ''}`}>Descripción</label>
                                <QuillEditor ref={editorRef} />
                                {errors.descripcion && <div className="invalid-feedback">{errors.descripcion}</div>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="titulo">Video Youtube</label>
                                <input 
                                    type="text" 
                                    className={`form-control ${errors.url ? 'is-invalid' : ''}`} 
                                    id="url" 
                                    name="url" 
                                    placeholder="URL VIDEO" 
                                    maxLength="250"
                                    value={url}
                                    onChange={(e) => setUrl(e.target.value)}
                                />
                                {errors.url && <div className="invalid-feedback">{errors.url}</div>}
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-12 mb-3">
                                    <label for="fecha">Fecha</label>
                                    <input 
                                        type="date" 
                                        className={`form-control ${errors.fecha ? 'is-invalid' : ''}`} 
                                        id="fecha" 
                                        name="fecha" 
                                        placeholder="fecha"
                                        value={fecha}
                                        onChange={(e) => setFecha(e.target.value)}
                                    />
                                    {errors.fecha && <div className="invalid-feedback">{errors.fecha}</div>}
                                </div>
                            </div>
                            <input 
                                ref={inputFileRef}
                                type="file"
                                name="file"
                                accept="image/*, application/pdf"
                                className="mt-2 imagen"
                            />
                            <button type="submit" className="btn btn-primary me-2 mt-2 boton-procesar">
                                <i className="fas fa-save"></i> Actualizar Clase
                            </button>
                            <Link className="btn btn-warning" to={`/Materias/Contenido/${materia}/${parcial}`}>Volver</Link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Test;
