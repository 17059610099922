import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

//const data = [
//    { name: 'Jan', uv: 4000, pv: 2400, amt: 2400 },
//    { name: 'Feb', uv: 3000, pv: 1398, amt: 2210 },
//    { name: 'Mar', uv: 2000, pv: 9800, amt: 2290 },
//    { name: 'Apr', uv: 2780, pv: 3908, amt: 2000 },
//    { name: 'May', uv: 1890, pv: 4800, amt: 2181 },
//    { name: 'Jun', uv: 2390, pv: 3800, amt: 2500 },
//    { name: 'Jul', uv: 3490, pv: 4300, amt: 2100 },
//];

const MyBarChart = ({ data, dataKeyX }) => (
    <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={dataKeyX} /> {/* Escala cualitativa en el eje X */}
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="valor" fill="#8884d8" /> {/* Muestra los valores en las barras */}
        </BarChart>
    </ResponsiveContainer>
);

export default MyBarChart;
