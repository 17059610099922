import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Spinner from '../widget/Spinner';
import { useApiContext } from '../layout/Template';
import ListaEstudiantes from './ListaEstudiantes ';
import { useNavigate } from 'react-router-dom';

// Objeto de caché
const cache = {};
const CACHE_DURATION = 48 * 60 * 60 * 1000; // 48 horas en milisegundos

const InfoCurso = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { apiUrl, accessToken, user } = useApiContext();
    const [curso, setCurso] = useState(null);
    const [materias, setMaterias] = useState([]);
    const [ciclos, setCiclos] = useState([]);
    const [subciclos, setSubciclos] = useState([]);
    const [comunicados, setComunicados] = useState([]);
    const [estudiante, setEstudiante] = useState(null);
    const [ocultarBoletin, setOcultarBoletin] = useState(null);
    const [ocultarBoletinDocente, setOcultarBoletinDocente] = useState(null);
    const [rubros, setRubros] = useState([]);
    const [error, setError] = useState(null);
    
    const fetchCursoInfo = async () => {

        const now = Date.now();
        const cacheKey = `cursoInfo-${id}`;

        // Verifica si el caché existe y es válido
        if (cache[cacheKey] && (now - cache[cacheKey].timestamp < CACHE_DURATION)) {
            const data = cache[cacheKey].data;
            setCurso(data.curso);
            setMaterias(data.materias);
            setCiclos(data.ciclos);
            setSubciclos(data.subciclos);
            setComunicados(data.comunicados);
            setRubros(data.rubros);
            setEstudiante(data.estudiante);
            setOcultarBoletin(data.ocultarBoletin);
            setOcultarBoletinDocente(data.ocultarBoletinDocente);
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${apiUrl}/miscursos/info/${id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            const data = response.data;

            // Actualiza el caché
            cache[cacheKey] = {
                data: data,
                timestamp: now // Marca de tiempo para la expiración
            };

            setCurso(data.curso);
            setMaterias(data.materias);
            setCiclos(data.ciclos);
            setSubciclos(data.subciclos);
            setComunicados(data.comunicados);
            setRubros(data.rubros);
            setEstudiante(data.estudiante);
            setOcultarBoletin(data.ocultarBoletin);
            setOcultarBoletinDocente(data.ocultarBoletinDocente);
            //window.showSuccessPopup('Datos del curso cargados exitosamente.');
        } catch (err) {
            if (err.response) {
                const errorMessage = err.response.data.error;
                const errorCode = err.response.data.code;
                if (errorCode === 403) {
                    // Mostrar el popup de error y redirigir al Dashboard
                    navigate('/dashboard'); // Cambia '/dashboard' por la ruta real de tu componente Dashboard
                    window.showErrorPopup(errorMessage);
                    return;
                }
                window.showErrorPopup(errorMessage);
            } else if (err.request) {
                window.showErrorPopup('No se recibió respuesta del servidor.');
            } else {
                window.showErrorPopup('Error al configurar la solicitud: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCursoInfo();
    }, [id, apiUrl, accessToken]);

    const notasParcial = async (subciclo) => {
        window.showLoaderPopup();
        console.log("procesando");
        try {
            const response = await axios.get(`${apiUrl}/sabana/parcial/${curso.id}/${subciclo.id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/pdf', // Asegúrate de que la respuesta sea un PDF
                },
                responseType: 'blob' // Importante para manejar la respuesta como archivo binario
            });
    
            // Crear un enlace de descarga
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
    
            // Forma el nombre del archivo con un formato seguro para nombres de archivo
            const nombreArchivo = `${subciclo.descripcion}-${curso.nivel.nombre.replace(/[\/\\?%*:|"<>]/g, '-')}-${curso.nivel.nombre_corto.replace(/[\/\\?%*:|"<>]/g, '-')}-${curso.seccion.descripcion.replace(/[\/\\?%*:|"<>]/g, '-')}.pdf`;
    
            link.setAttribute('download', nombreArchivo);
            document.body.appendChild(link);
            link.click();
    
            // Liberar el objeto URL y eliminar el enlace del DOM
            window.URL.revokeObjectURL(url);
            link.remove();
            window.hideLoaderPopup();
        } catch (err) {
            window.hideLoaderPopup();
            console.error('Error al descargar el PDF:', err);
            window.showErrorPopup('Error al descargar el archivo PDF.');
        }
        console.log("fin");
    };

    const notasCiclo = async (ciclo) => {
        window.showLoaderPopup();
        console.log("procesando");
        try {
            const response = await axios.get(`${apiUrl}/sabana/ciclo/${curso.id}/${ciclo.id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/pdf', // Asegúrate de que la respuesta sea un PDF
                },
                responseType: 'blob' // Importante para manejar la respuesta como archivo binario
            });
    
            // Crear un enlace de descarga
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
    
            // Forma el nombre del archivo con un formato seguro para nombres de archivo
            const nombreArchivo = `${ciclo.descripcion}-${curso.nivel.nombre.replace(/[\/\\?%*:|"<>]/g, '-')}-${curso.nivel.nombre_corto.replace(/[\/\\?%*:|"<>]/g, '-')}-${curso.seccion.descripcion.replace(/[\/\\?%*:|"<>]/g, '-')}.pdf`;
    
            link.setAttribute('download', nombreArchivo);
            document.body.appendChild(link);
            link.click();
    
            // Liberar el objeto URL y eliminar el enlace del DOM
            window.URL.revokeObjectURL(url);
            link.remove();
            window.hideLoaderPopup();
        } catch (err) {
            window.hideLoaderPopup();
            console.error('Error al descargar el PDF:', err);
            window.showErrorPopup('Error al descargar el archivo PDF.');
        }
        console.log("fin");
    };

    const reportePendientes = async (ciclo) => {
        window.showLoaderPopup();
        console.log("procesando");
        try {
            const response = await axios.get(`${apiUrl}/pendientes/pdf/${curso.id}/${ciclo.id}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/pdf', // Asegúrate de que la respuesta sea un PDF
                },
                responseType: 'blob' // Importante para manejar la respuesta como archivo binario
            });
    
            // Crear un enlace de descarga
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
    
            // Forma el nombre del archivo con un formato seguro para nombres de archivo
            const nombreArchivo = `Reporte ${ciclo.descripcion}-${curso.nivel.nombre.replace(/[\/\\?%*:|"<>]/g, '-')}-${curso.nivel.nombre_corto.replace(/[\/\\?%*:|"<>]/g, '-')}-${curso.seccion.descripcion.replace(/[\/\\?%*:|"<>]/g, '-')}.pdf`;
    
            link.setAttribute('download', nombreArchivo);
            document.body.appendChild(link);
            link.click();
    
            // Liberar el objeto URL y eliminar el enlace del DOM
            window.URL.revokeObjectURL(url);
            link.remove();
            window.hideLoaderPopup();
        } catch (err) {
            window.hideLoaderPopup();
            console.error('Error al descargar el PDF:', err);
            window.showErrorPopup('Error al descargar el archivo PDF.');
        }
        console.log("fin");
    };
    
    const deleteMateria = async (materiaId) => {
        try {
            await axios.delete(`${apiUrl}/materiacurso/${materiaId}`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json'
                }
            });
            fetchCursoInfo(); // Actualiza la lista de productos después de eliminar
        } catch (error) {
            console.error('Error al eliminar el examenn:', error);
            // Manejo de errores según sea necesario
        }
    }

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className="py-3 px-3 content-wrapper">
            <div className="card">
                <div className="card-body">
                    <div className="d-sm-flex justify-content-between align-items-start">
                        <div>
                            <h2 className="">
                                Información del Curso <strong>{curso.nivel.nombre} ({curso.nivel.nombre_corto}) - {curso.seccion.descripcion}</strong>
                            </h2>
                            {user.estudiante === 1 && ocultarBoletin === 0 && (
                                <>  
                                    {/* 
                                    <Link to={`/Boletin/${estudiante.id}`} className="links" style={{ fontSize: '20px' }}>
                                        <i className="menu-icon "></i> V
                                    </Link>
                                    */}
                                    <div className="dropdown">
                                        <button className="btn btn-success" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="menu-icon fas fa-clipboard-list"></i> <span className="d-none d-lg-inline"> Ver Boletín</span>
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            {ciclos.map((ciclo) => (
                                                <React.Fragment key={ciclo.id}>
                                                    <li>
                                                        <Link
                                                            className="dropdown-item"
                                                            to={`/Boletin/Ciclo/${estudiante.id}/${ciclo.id}`}
                                                        >
                                                            {ciclo.descripcion}
                                                        </Link>
                                                    </li>
                                                    {subciclos.filter(subciclo => subciclo.id_ciclo === ciclo.id).map((subciclo) => (
                                                        <li key={subciclo.id}>
                                                            <Link
                                                                className="dropdown-item"
                                                                to={`/Boletin/Parcial/${estudiante.id}/${subciclo.id}`}
                                                            >
                                                                {subciclo.descripcion} - {subciclo.ciclo.descripcion}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </React.Fragment>
                                            ))}
                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                        <div>
                            {(user.estudiante !== 1 && (curso.id_tutor === user.id_docente || user.id_rol)) && (
                                <>
                                <div>
                                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                                        
                                        <Link to={`/MisCursos/Asistencia/${curso.id}/${curso.periodo.parcial_actual}`} className="btn btn-outline-primary">
                                            <i className="far fa-calendar-alt"></i>
                                            <span className="d-none d-lg-inline"> Asistencias</span>
                                        </Link>
                                        <Link to={`/MisCursos/Comportamiento/${curso.id}/${curso.periodo.parcial_actual}`} className="btn btn-outline-primary">
                                            <i className="fas fa-spell-check"></i>
                                            <span className="d-none d-lg-inline"> Comportamiento</span>
                                        </Link>
                                        <button className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fas fa-database"></i>
                                            <span className="d-none d-lg-inline"> Calificaciones</span>
                                        </button>
                                        <ul className="dropdown-menu">
                                            {ciclos.map((ciclo) => (
                                                <React.Fragment key={ciclo.id}>
                                                    <li>
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#"
                                                            onClick={() => notasCiclo(ciclo)}
                                                        >
                                                            {ciclo.descripcion}
                                                        </Link>
                                                    </li>
                                                    {subciclos.filter(subciclo => subciclo.id_ciclo === ciclo.id).map((subciclo) => (
                                                        <li key={subciclo.id}>
                                                            <Link
                                                                className="dropdown-item"
                                                                to="#"
                                                                onClick={() => notasParcial(subciclo)}
                                                            >
                                                                {subciclo.descripcion} - {subciclo.ciclo.descripcion}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </React.Fragment>
                                            ))}
                                        </ul>
                                        <button className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fas fa-flag"></i>
                                            <span className="d-none d-lg-inline"> Pendientes</span>
                                        </button>
                                        <ul className="dropdown-menu">
                                            {ciclos.map((ciclo) => (
                                                <React.Fragment key={ciclo.id}>
                                                    <li>
                                                        <Link
                                                            className="dropdown-item"
                                                            to="#"
                                                            onClick={() => reportePendientes(ciclo)}
                                                        >
                                                            Reporte {ciclo.descripcion}
                                                        </Link>
                                                    </li>
                                                </React.Fragment>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mt-3">
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Materia</th>
                                    <th scope="col" className="d-none d-sm-table-cell">Docente</th>
                                    {user.id_rol && (
                                        <th scope="col"><i className="fas fa-tools"></i></th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {materias.map((materia) => (
                                    <>
                                    <tr key={materia.id}>
                                        <th>
                                            <Link
                                                to={`/Materias/Contenido/${materia.id}/${curso.periodo.parcial_actual}`}
                                                style={{ textDecoration: 'none' }}
                                            >
                                                {materia.materia.nombre} - {materia.materia.codigo} <i className="fas fa-eye"></i>
                                            </Link>
                                        </th>
                                        <td className="d-none d-sm-table-cell">
                                            {materia.docente.nombres}
                                        </td>
                                        {user.id_rol && (
                                        <td>
                                            <div className="dropdown">
                                                <button
                                                    className="btn btn-link"
                                                    id={`dropdownMenuButton-${materia.id}`}
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <i className="fas fa-cog"></i>
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${materia.id}`}>
                                                    {/* 
                                                    <li>
                                                        <Link className="dropdown-item" to={`/Materias/Editar/${materia.id}`}>
                                                            Editar
                                                        </Link>
                                                    </li>
                                                    */}
                                                    <li>
                                                        <button className="dropdown-item" data-bs-toggle="modal" data-bs-target={`#exampleModal${materia.id}`}>
                                                            Eliminar
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                        )}
                                    </tr>
                                    <div class="modal fade" id={`exampleModal${materia.id}`} tabindex="-1" aria-labelledby="exampleModa1lLabel" aria-hidden="true">
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">Eliminar Materia {materia.materia.nombre}</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    ¿Esta seguro que desea <strong>eliminar</strong>: {materia.materia.nombre} ?
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                                    <button type="button" class="btn btn-danger" onClick={()=>deleteMateria(materia.id)} data-bs-dismiss="modal">Eliminar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {(user.docente === 1 || user.id_rol) && (
                <ListaEstudiantes
                    key={id} // Asegúrate de que `id` cambie para forzar una actualización
                    apiUrl={apiUrl}
                    accessToken={accessToken}
                    id={id}
                    curso={curso}
                    ciclos={ciclos}
                    subciclos={subciclos}
                    user={user}
                    materias={materias}
                    ocultarBoletinDocente={ocultarBoletinDocente}
                />
            )}
            {user.estudiante === 1 && (
                <div className="card mt-3">
                    <div className="card-body">
                        <h4 className="card-title">Historial de pagos</h4>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Tipo</th>
                                        <th>Mes</th>
                                        <th>Vencimiento</th>
                                        <th>Monto</th>
                                        <th>Pagado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rubros.map((rubro) => (
                                        <tr key={rubro.id}>
                                            <td>
                                                {rubro.tipo === 1 && <span>Matricula</span>}
                                                {rubro.tipo === 2 && <span>Pension</span>}
                                                {rubro.tipo === 3 && <span>{rubro.concepto}</span>}
                                            </td>
                                            <td>{rubro.mes ?? 'N/A'}</td>
                                            <td>{rubro.vencimiento}</td>
                                            <td>${rubro.monto_total}</td>
                                            <td>
                                                <label className={`badge ${
                                                    (() => {
                                                        switch (true) {
                                                            case rubro.monto_pagado === 0:
                                                                return 'badge-danger';
                                                            case rubro.monto_pagado > 0 && rubro.monto_pagado < rubro.monto_total:
                                                                return 'badge-warning';
                                                            case rubro.monto_pagado === rubro.monto_total:
                                                                return 'badge-success';
                                                            default:
                                                                return 'badge-danger';
                                                        }
                                                    })()
                                                }`}>
                                                    ${rubro.monto_pagado}
                                                </label>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InfoCurso;
