import React from 'react';
import TextInput from '../../form/InputText';
import SelectInput from '../../form//SelectInput';
import CheckboxInput from '../../form//CheckboxInput';

const DatosEstudiante = ({ formData, paises, cantones, representantes, errors, setFormData }) => {

    const handleCheckboxChange = (field, subField = null) => (e) => {
        const checked = e.target.checked;
        setFormData(prevFormData => {
            if (subField) {
                // Caso para subcampos
                return {
                    ...prevFormData,
                    [field]: {
                        ...prevFormData[field],
                        [subField]: checked
                    }
                };
            } else {
                // Caso para campos en el nivel superior
                return {
                    ...prevFormData,
                    [field]: checked
                };
            }
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const [section, field] = name.split('.');

        setFormData(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [field]: value
            }
        }));
    };
    return (
        <div className="col-lg-6 col-md-6 col-sm-12 grid-margin stretch-card">
            <div className='card'>
                <div className="card-body">
                    <h4 className="card-title">Datos Estudiante</h4>
                    <div className="form-group">
                        <div className='row'>
                            <div className="col-8">
                                <TextInput
                                    label="Identificación"
                                    name="estudiante.identificacion"
                                    value={formData.estudiante.identificacion}
                                    onChange={handleInputChange}
                                    validacion={formData.estudiante.extranjero ? 'alfanumerico' : 'entero'}
                                    placeholder="Identificación"
                                    errors={errors}
                                />
                                {errors['estudiante.identificacion'] && <div className="invalid-feedback">{errors['estudiante.identificacion'][0]}</div>}
                            </div>
                            <div className="form-check col-4">
                                <CheckboxInput 
                                    id="extranjero_estudiante"
                                    name="extranjero_estudiante"
                                    checked={formData.estudiante.extranjero}
                                    onChange={handleCheckboxChange('estudiante', 'extranjero')}
                                    label="Extranjero"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <TextInput
                                    id="estudiante.nombre"
                                    name="estudiante.nombre"
                                    value={formData.estudiante.nombre}
                                    onChange={handleInputChange}
                                    placeholder="Nombres"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <TextInput
                                    id="estudiante.apellido"
                                    name="estudiante.apellido"
                                    value={formData.estudiante.apellido}
                                    onChange={handleInputChange}
                                    placeholder="Apellidos"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-6">
                                <SelectInput
                                    id="estudiante.id_representante"
                                    name="estudiante.id_representante"
                                    value={formData.estudiante.id_representante}
                                    onChange={handleInputChange}
                                    placeholder="Representante"
                                    options={representantes.map(representante => ({
                                        value: representante.id,
                                        label: representante.identificacion + ' - ' + representante.nombres
                                    }))}
                                    error={errors['estudiante.id_representante'] && errors['estudiante.id_representante'][0]}
                                />
                            </div>
                            <div className="col-6">
                                <TextInput
                                    id="estudiante.correo"
                                    name="estudiante.correo"
                                    value={formData.estudiante.correo}
                                    onChange={handleInputChange}
                                    placeholder="Correo"
                                    type="email"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-6">
                                <TextInput
                                    id="estudiante.direccion"
                                    name="estudiante.direccion"
                                    value={formData.estudiante.direccion}
                                    onChange={handleInputChange}
                                    placeholder="Dirección"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-4">
                                <SelectInput
                                    id="estudiante.sexo"
                                    name="estudiante.sexo"
                                    value={formData.estudiante.sexo}
                                    onChange={handleInputChange}
                                    placeholder="Sexo"
                                    options={[
                                        { value: 'M', label: 'Masculino' },
                                        { value: 'F', label: 'Femenino' }
                                    ]}
                                    error={errors['estudiante.sexo'] && errors['estudiante.sexo'][0]}
                                />
                            </div>
                            <div className="col-4">
                                <TextInput
                                    id="estudiante.celular"
                                    name="estudiante.celular"
                                    value={formData.estudiante.celular}
                                    onChange={handleInputChange}
                                    placeholder="Celular"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-4">
                                <TextInput
                                    id="estudiante.telefono"
                                    name="estudiante.telefono"
                                    value={formData.estudiante.telefono}
                                    onChange={handleInputChange}
                                    placeholder="Telefono"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-6">
                                <SelectInput
                                    id="estudiante.id_canton"
                                    name="estudiante.id_canton"
                                    value={formData.estudiante.id_canton}
                                    onChange={handleInputChange}
                                    placeholder="Cantón"
                                    options={cantones.map(canton => ({
                                        value: canton.id,
                                        label: canton.nombre
                                    }))}
                                    error={errors['estudiante.id_canton'] && errors['estudiante.id_canton'][0]}
                                />
                            </div>
                            <div className="col-6">
                                <SelectInput
                                    id="estudiante.id_nacionalidad"
                                    name="estudiante.id_nacionalidad"
                                    value={formData.estudiante.id_nacionalidad}
                                    onChange={handleInputChange}
                                    placeholder="Nacionalidad"
                                    options={paises.map(pais => ({
                                        value: pais.id,
                                        label: pais.nacionalidad
                                    }))}
                                    error={errors['estudiante.id_nacionalidad'] && errors['estudiante.id_nacionalidad'][0]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DatosEstudiante;
