import React, { useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const QuillEditor = React.forwardRef((props, ref) => {
  const editorRef = useRef(null);

  useEffect(() => {
    if (!editorRef.current) {
      const quill = new Quill('#editor', {
        modules: {
          toolbar: [
            ['bold', 'italic'],
            ['link', 'blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
          ],
        },
        theme: 'snow',
      });
      editorRef.current = quill;
      if (ref) {
        ref.current = quill;
      }
    }
  }, [ref]);

  return (
    <div>
      <div id="editor" style={{ height: '200px' }}></div>
    </div>
  );
});

export default QuillEditor;
