import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Mensaje = ({ apiUrl, materiaId, accessToken, parcialId }) => {
    const navigate = useNavigate(); // Hook para navegar
    const [mensaje, setMensaje] = useState('');
    const [url, setUrl] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (event) => {
        window.showLoaderPopup();
        event.preventDefault();
        try {
            const response = await axios.post(
                `${apiUrl}/mensaje/store/${materiaId}`, 
                {
                    mensaje,
                    url
                },
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json'
                    }
                }
            );
            // Resetear el formulario
            setMensaje('');
            setUrl('');
            //setSuccess('Mensaje guardado exitosamente.');
            navigate(`/Materias/Contenido/${materiaId}/${parcialId}`);
            window.hideLoaderPopup();
            window.showSuccessPopup("Enlace Actualizado.");
        } catch (error) {
            window.hideLoaderPopup();
            setError('Error al enviar el mensaje.');
            console.error('Error al enviar el mensaje:', error);
        }
    };

    return (
        <div className="modal fade" id="mensajeModal" tabIndex="-1" aria-labelledby="mensajeModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="mensajeModalLabel">Enviar Mensaje</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="mensaje" className="form-label">Mensaje</label>
                                <textarea
                                className="form-control"
                                id="mensaje"
                                rows="3"
                                maxLength="250"
                                style={{ height: '10rem' }}
                                value={mensaje}
                                onChange={(e) => setMensaje(e.target.value)}
                                ></textarea>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="url" className="form-label">URL</label>
                                <input
                                type="text"
                                className="form-control"
                                id="url"
                                maxLength="250"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">Añadir URL</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Mensaje;
