import axios from 'axios'
import React, {useState, useEffect} from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useApiContext  } from '../layout/Template';
import Spinner from '../widget/Spinner';
import DatosGeneralesContext from '../widget/DatosGeneralesContext';
import DatosEstudiante from './DatosEstudiante';
import DatosFicha from './DatosFicha';

const Create = () => {
    const { apiUrl, accessToken } = useApiContext();
    const [loader, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        estudiante: {
            identificacion: '',
            extranjero: false,
            nombre: '',
            apellido: '',
            id_representante: '',
            correo: '',
            celular: '',
            telefono: '',
            direccion: '',
            sexo: '',
            id_canton: '',
            id_nacionalidad: '',
        },
        ficha: {
            parentezco1: '',
            nombre_contacto1: '',
            celular_contacto1: '',
            domicilio_contacto1: '',
            dir_trabajo_contacto1: '',
            
            parentezco2: '',
            nombre_contacto2: '',
            celular_contacto2: '',
            domicilio_contacto2: '',
            dir_trabajo_contacto2: '',
        }
    });

    const { cantones, paises, loading } = DatosGeneralesContext(apiUrl);
    
    const [representantes, setRepresentantes] = useState([]);
    useEffect(() => {
        const getRepresentantes = async () => {
            try {
                const response = await axios.get(`${apiUrl}/getRepresentantes`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Accept': 'application/json',
                    }
                });
                console.log('Respuesta completa:', response.data);

                // Asigna los datos a los estados
                setRepresentantes(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error al obtener los datos:', error.response ? error.response.data : error.message);
            }
        };

        getRepresentantes();
    }, [apiUrl]);

    const [errors, setErrors] = useState({}); // Estado para errores de validación
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log('Datos enviados:', formData); // Agrega esta línea para depurar
        try {
            const response = await axios.post(`${apiUrl}/estudiantes`, formData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            console.log('Estudiante creado exitosamente:', response.data);
            navigate('/estudiantes');  // Redirigir a la lista de estudiantes
            window.showSuccessPopup(response.data.message);
        } catch (error) {
            if (error.response) {
                // Manejar errores de validación
                setErrors(error.response.data.errors);
            } else {
                console.error('Error al crear el periodo:', error.response ? error.response.data : error.message);
            }
        }
    };

    if (loading || loader) {
        return <Spinner />;
    }

    return (
        <div className='py-3 px-3 content-wrapper'>
            <div className="d-sm-flex justify-content-between align-items-start">
                <div>
                    <h2 className="">Crear Estudiante</h2>
                </div>
            </div>
            
            <form onSubmit={handleSubmit}>
                <div className='row'>
                    <DatosEstudiante
                        formData={formData}
                        setFormData={setFormData}
                        paises={paises}
                        cantones={cantones}
                        representantes={representantes}
                        errors={errors}
                    />
                    <DatosFicha
                        formData={formData}
                        setFormData={setFormData}
                        errors={errors}
                    />
                </div>
                <button type="submit" className="btn btn-primary me-2"><i className="fas fa-save"></i> Crear Estudiante</button>
                <Link className="btn btn-warning" to='/estudiantes'>Volver</Link>
            </form>
        </div>
    );
};

export default Create;
