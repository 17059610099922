// src/routes/CursosRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../componentes/auth/ProtectedRoute';
import Template from '../componentes/client/layout/Template';

// cursos
import Cursos from '../componentes/client/cursos/Cursos';
import Create from '../componentes/client/cursos/Create';
import Edit from '../componentes/client/cursos/Edit';
import Miscursos from '../componentes/client/cursos/Miscursos';
import Tutorias from '../componentes/client/cursos/Tutorias';
import InfoCurso from '../componentes/client/cursos/InfoCurso';

const CursosRoutes = () => {
    return (
        <Routes>
            <Route
                path="Cursos"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Cursos />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="Cursos/create"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Create />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="cursos/:id"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Edit />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="MisCursos"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Miscursos />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="Tutorias"
                element={
                    <ProtectedRoute>
                        <Template>
                            <Tutorias />
                        </Template>
                    </ProtectedRoute>
                }
            />
            <Route
                path="MisCursos/Info/:id"
                element={
                    <ProtectedRoute>
                        <Template>
                            <InfoCurso />
                        </Template>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default CursosRoutes;
