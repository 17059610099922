// src/routes/ClasesRoutes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../componentes/auth/ProtectedRoute';
import Template from '../componentes/client/layout/Template';

// secciones
import Create from '../componentes/client/clases/Create';
import Edit from '../componentes/client/clases/Edit';
import Show from '../componentes/client/clases/Show';

const ClasesRoutes = () => {
    return (
        <Routes>
            <Route
                    path="create/:materiaId/:parcialId"
                    element={
                        <ProtectedRoute>
                            <Template>
                                <Create />
                            </Template>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="edit/:claseId"
                    element={
                        <ProtectedRoute>
                            <Template>
                                <Edit />
                            </Template>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path=":claseId"
                    element={
                        <ProtectedRoute>
                            <Template>
                                <Show />
                            </Template>
                        </ProtectedRoute>
                    }
                />
        </Routes>
    );
};

export default ClasesRoutes;
