import React from 'react';
import TextInput from '../../form/InputText';
import SelectInput from '../../form//SelectInput';
import CheckboxInput from '../../form//CheckboxInput';

const DatosRepresentante = ({ formData, errors, setFormData }) => {

    const handleCheckboxChange = (field, subField = null) => (e) => {
        const checked = e.target.checked;
        setFormData(prevFormData => {
            if (subField) {
                // Caso para subcampos
                return {
                    ...prevFormData,
                    [field]: {
                        ...prevFormData[field],
                        [subField]: checked
                    }
                };
            } else {
                // Caso para campos en el nivel superior
                return {
                    ...prevFormData,
                    [field]: checked
                };
            }
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const [section, field] = name.split('.');

        setFormData(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                [field]: value
            }
        }));
    };
    return (
        <div className="col-lg-5 col-md-6 col-sm-12 grid-margin stretch-card">
            <div className='card'>
                <div className="card-body">
                    <h4 className="card-title">Datos Representante</h4>
                    <div className="form-group">
                        <div className='row'>
                            <div className="col-8">
                                <TextInput
                                    label="Identificación"
                                    name="representante.identificacion"
                                    value={formData.representante.identificacion}
                                    onChange={handleInputChange}
                                    validacion={formData.representante.extranjero ? 'alfanumerico' : 'entero'}
                                    placeholder="Identificación"
                                    errors={errors}
                                />
                                {errors['representante.identificacion'] && <div className="invalid-feedback">{errors['representante.identificacion'][0]}</div>}
                            </div>
                            <div className="form-check col-4">
                                <CheckboxInput 
                                    id="extranjero_representante"
                                    name="extranjero_representante"
                                    checked={formData.representante.extranjero}
                                    onChange={handleCheckboxChange('representante', 'extranjero')}
                                    label="Extranjero"
                                />
                            </div>
                            <div className="col-lg-12">
                                <TextInput
                                    id="representante.nombre"
                                    name="representante.nombre"
                                    value={formData.representante.nombre}
                                    onChange={handleInputChange}
                                    placeholder="Nombres"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-6">
                                <TextInput
                                    id="representante.correo"
                                    name="representante.correo"
                                    value={formData.representante.correo}
                                    onChange={handleInputChange}
                                    placeholder="Correo"
                                    type="email"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-6">
                                <TextInput
                                    id="representante.direccion"
                                    name="representante.direccion"
                                    value={formData.representante.direccion}
                                    onChange={handleInputChange}
                                    placeholder="Dirección"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-4">
                                <SelectInput
                                    id="representante.sexo"
                                    name="representante.sexo"
                                    value={formData.representante.sexo}
                                    onChange={handleInputChange}
                                    placeholder="Sexo"
                                    options={[
                                        { value: 'M', label: 'Masculino' },
                                        { value: 'F', label: 'Femenino' }
                                    ]}
                                    error={errors['representante.sexo'] && errors['representante.sexo'][0]}
                                />
                            </div>
                            <div className="col-4">
                                <TextInput
                                    id="representante.celular"
                                    name="representante.celular"
                                    value={formData.representante.celular}
                                    onChange={handleInputChange}
                                    placeholder="Celular"
                                    errors={errors}
                                />
                            </div>
                            <div className="col-4">
                                <TextInput
                                    id="representante.telefono"
                                    name="representante.telefono"
                                    value={formData.representante.telefono}
                                    onChange={handleInputChange}
                                    placeholder="Telefono"
                                    errors={errors}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DatosRepresentante;
